const formsStyles = {
  "input[type='number'], input[type='tel'], textarea": {
    display: 'block',
    width: '100%',
    height: '32px',
    margin: '12px 0',
    padding: '3px 8px 2px',
    appearance: 'none',
    background: 'var(--c-island-bg)',
    borderRadius: '3px',
    border: '1px solid var(--c-input-border)',
    color: 'var(--hb-off-black)',
  },
  textarea: { resize: 'none' },
  "input[type='number']": { paddingRight: '1px' },
  'input:-webkit-autofill': { WebkitBoxShadow: '0 0 0 1000px white inset' },
  "input[type='text'], input[type='email'], input[type='password'], input[type='number'], input[type='tel'], textarea": {
    width: '100%',
  },
  '.form--wide label': { width: '200px !important' },
  ".input-label > input[type='radio'] + span::before, label > input[type='radio'] + span::before": {
    borderRadius: '100%',
  },
  ".input-label > input[type='checkbox'], label > input[type='checkbox']": {
    height: '20px',
    width: '20px',
  },
  ".input-label > input[type='checkbox'] + span::before, label > input[type='checkbox'] + span::before": {
    borderRadius: '3px',
  },
  '@apply --visuallyhidden': true,
  margin: '5px',
  '& + span': {
    display: 'inline-block',
    cursor: 'pointer',
    ".input-label > input[type='checkbox']::before, label > input[type='checkbox']::before, .input-label > input[type='radio']::before, label > input[type='radio']::before": {
      display: 'inline-block',
      verticalAlign: 'top',
      content: "''",
      backgroundColor: 'var(--c-island-bg)',
      border: '1px solid var(--c-info)',
      height: '20px',
      width: '20px',
      marginTop: '1px',
      marginRight: '5px',
    },
  },
  ".input-label > input[type='checkbox']:checked + span::before, label > input[type='checkbox']:checked + span::before, .input-label > input[type='radio']:checked + span::before, label > input[type='radio']:checked + span::before": {
    backgroundImage: "url('../images/icons/tick.svg')",
    backgroundColor: 'var(--c-info)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  ".input-label > input[type='checkbox'][disabled] + span, label > input[type='checkbox'][disabled] + span, .input-label > input[type='radio'][disabled] + span, label > input[type='radio'][disabled] + span": {
    color: 'var(--c-tint)',
    cursor: 'not-allowed',
  },
  ".input-label > input[type='checkbox'][disabled] + span::before, label > input[type='checkbox'][disabled] + span::before, .input-label > input[type='radio'][disabled] + span::before, label > input[type='radio'][disabled] + span::before": {
    borderColor: 'var(--c-tint)',
    backgroundColor: 'transparent',
  },
  ".input-label-reversed > input[type='radio'] + span::after": {
    borderRadius: '100%',
  },
  ".input-label-reversed > input[type='checkbox'] + span::after": {
    borderRadius: '3px',
  },
  ".input-label-reversed > input[type='checkbox'] + span::before, .input-label-reversed > input[type='radio'] + span::before": {
    display: 'none',
  },
  ".input-label-reversed > input[type='checkbox'] + span::after, .input-label-reversed > input[type='radio'] + span::after": {
    display: 'inline-block',
    verticalAlign: 'top',
    content: "''",
    backgroundColor: 'var(--c-island-bg)',
    border: '1px solid var(--c-info)',
    height: '20px',
    width: '20px',
    marginTop: '1px',
    marginLeft: '6px',
    marginRight: '-26px',
  },
  ".input-label-reversed > input[type='checkbox']:checked + span::before, .input-label-reversed > input[type='radio']:checked + span::before": {
    display: 'none',
  },
  ".input-label-reversed > input[type='checkbox']:checked + span::after, .input-label-reversed > input[type='radio']:checked + span::after": {
    backgroundImage: "url('../images/icons/tick.svg')",
    backgroundColor: 'var(--c-info)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  ".input-label-reversed > input[type='checkbox'][disabled] + span::before, .input-label-reversed > input[type='radio'][disabled] + span::before": {
    display: 'none',
  },
  ".input-label-reversed > input[type='checkbox'][disabled] + span::after, .input-label-reversed > input[type='radio'][disabled] + span::after": {
    borderColor: 'var(--c-tint)',
    backgroundColor: 'transparent',
  },
  '.input-output': { position: 'relative', width: '100%', display: 'block' },
  '.input-output span': {
    position: 'absolute',
    right: '11px',
    top: '-37px',
    color: 'var(--c-text-secondary)',
  },
};

export default formsStyles;
