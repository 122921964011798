import React from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function ChevronDoubleLeftIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon viewBox="0 0 20.6 24" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        d="M13.8.54,2.3,12l11.5,11.5"
        transform="translate(-1.59 -0.04)"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        d="M21.7.54,10.2,12l11.5,11.5"
        transform="translate(-1.59 -0.04)"
      />
    </SvgIcon>
  );
}

export default ChevronDoubleLeftIcon;
