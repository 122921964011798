import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function TractorSprayerIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path
          d="M292.6,434.9a1,1,0,0,1-1-1v-3.5h2v3.5A1,1,0,0,1,292.6,434.9Z"
          transform="translate(-278.1 -419.4)"
          fill="none"
        />
        <path
          d="M292.1,430.9v3a.5.5,0,0,0,1,0v-3h-1m-1-1h3v4a1.5,1.5,0,0,1-3,0Z"
          transform="translate(-278.1 -419.4)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M299.6,434.9a1,1,0,0,1-1-1v-3.5h2v3.5A1,1,0,0,1,299.6,434.9Z"
          transform="translate(-278.1 -419.4)"
          fill="none"
        />
        <path
          d="M299.1,430.9v3a.5.5,0,0,0,1,0v-3h-1m-1-1h3v4a1.5,1.5,0,0,1-3,0Z"
          transform="translate(-278.1 -419.4)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M297.3,431.8h-2.4v-2.4h-3.5v-1.5h1.1v-.5l.7-5.3h5.6c.2.2.7,5.7.8,5.9h1v1.6h-3.4Z"
          transform="translate(-278.1 -419.4)"
          fill="none"
        />
        <path
          d="M296.8,431.3v-2.4h3.4v-.6h-1c0-.5-.6-5.3-.7-5.9h-4.7c-.1.6-.8,5.4-.8,5.9h-1.1v.6h3.5v2.4h1.4m1,1h-3.4v-2.4h-3.5v-2.2l.4-.4h.8l.7-5.5.4-.4h5.9c.5-.1.9,5.6,1,5.9h.9l.2.4v2.2h-3.4Z"
          transform="translate(-278.1 -419.4)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M300.8,441.2H279.6v-4.3c3.2-1.8,18.7-1.8,21.2-1.3Z"
          transform="translate(-278.1 -419.4)"
          fill="none"
        />
        <path
          d="M291.6,435.8a43.3,43.3,0,0,0-11.5,1.4v3.5h20.2V436c-1-.1-3.8-.2-8.7-.2m0-1c7.9,0,9.9.3,9.7.3v6.6H279.1v-5.2S283.5,434.8,291.6,434.8Z"
          transform="translate(-278.1 -419.4)"
          fill="currentColor"
        />
      </g>
      <path
        d="M300.1,435.7a8.3,8.3,0,0,0-5.3,5.5"
        transform="translate(-278.1 -419.4)"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M285.2,435.7c-.8,0-4.6,4.1-5.2,5.6"
        transform="translate(-278.1 -419.4)"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M293.2,435.4a9.6,9.6,0,0,0-6,5.8"
        transform="translate(-278.1 -419.4)"
        fill="none"
        stroke="currentColor"
      />
      <g>
        <path
          d="M298,426.4h-3.8l.3-3h3.1Z"
          transform="translate(-278.1 -419.4)"
          fill="none"
        />
        <path
          d="M295,423.9l-.2,2h2.6l-.2-2H295m-.9-1h4l.4,4h-4.8Z"
          transform="translate(-278.1 -419.4)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M290.6,430.9h-11V430l1.6-1.1h9.4Z"
          transform="translate(-278.1 -419.4)"
          fill="none"
        />
        <path
          d="M281.4,429.4l-1.3.9h10v-1h-8.7m-.4-1h10.1v3h-12v-1.6Z"
          transform="translate(-278.1 -419.4)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M279.7,433.3a.5.5,0,0,1,0-.5A.5.5,0,0,1,279.7,433.3Z"
          transform="translate(-278.1 -419.4)"
          fill="none"
        />
        <path
          d="M279.7,431.9c.3.2,1,1.7,0,1.9S279.3,432.1,279.7,431.9Z"
          transform="translate(-278.1 -419.4)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M285.5,433.3a.5.5,0,0,1,0-.5A.5.5,0,0,1,285.5,433.3Z"
          transform="translate(-278.1 -419.4)"
          fill="none"
        />
        <path
          d="M285.5,431.9c.4.2,1.1,1.7,0,1.9S285.2,432.1,285.5,431.9Z"
          transform="translate(-278.1 -419.4)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M288.7,433.3a.5.5,0,0,1,0-.5A.5.5,0,0,1,288.7,433.3Z"
          transform="translate(-278.1 -419.4)"
          fill="none"
        />
        <path
          d="M288.7,431.9c.3.2,1,1.7,0,1.9S288.3,432.1,288.7,431.9Z"
          transform="translate(-278.1 -419.4)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M282.5,433.3a.5.5,0,0,1,0-.5A.5.5,0,0,1,282.5,433.3Z"
          transform="translate(-278.1 -419.4)"
          fill="none"
        />
        <path
          d="M282.5,431.9c.4.2,1.1,1.7,0,1.9S282.2,432.1,282.5,431.9Z"
          transform="translate(-278.1 -419.4)"
          fill="currentColor"
        />
      </g>
      <path
        d="M282.7,430.6l-1.4-1.3"
        transform="translate(-278.1 -419.4)"
        fill="none"
        stroke="currentColor"
        strokeWidth="0.5px"
      />
      <path
        d="M283.3,430.6l1.5-1.4"
        transform="translate(-278.1 -419.4)"
        fill="none"
        stroke="currentColor"
        strokeWidth="0.5px"
      />
      <path
        d="M286.7,430.6l-1.5-1.4"
        transform="translate(-278.1 -419.4)"
        fill="none"
        stroke="currentColor"
        strokeWidth="0.5px"
      />
      <path
        d="M287.3,430.6l1.5-1.5"
        transform="translate(-278.1 -419.4)"
        fill="none"
        stroke="currentColor"
        strokeWidth="0.5px"
      />
    </SvgIcon>
  );
}

export default TractorSprayerIcon;
