import React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Button } from '@hummingbirdtechgroup/wings-ui';
import { useModalStyles } from './modal.styles';
import Typography from '../Typography';

interface ModalProps {
  onClose: (args?: any) => any;
  show: boolean;
  /** className added to the modal container */
  className?: string;
  /** Shows a partially transparent backdrop to give the modal a popped effect */
  backdrop?: boolean;
  children: React.ReactNode;
  size?: 'large' | 'small';
  /** Removes the top right close button from the modal when true */
  withOutClose?: boolean;
}

function Modal({
  show,
  className,
  children,
  backdrop,
  onClose,
  size = 'small',
  withOutClose = false,
}: ModalProps): React.ReactPortal | null {
  const classes = useModalStyles();

  const closeHandler = (onCloseFn: any) => (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onCloseFn();
    }
  };

  return show
    ? createPortal(
        <div
          className={backdrop ? classes.backdrop : undefined}
          onClick={closeHandler(onClose)}
        >
          <div className={classNames(className, classes.modal, classes[size])}>
            {!withOutClose && (
              <Button className={classes.close} onClick={onClose} theme="link">
                <Typography variant="caption">
                  <FormattedMessage
                    id="farmSettings.exit"
                    defaultMessage="Close"
                  />
                </Typography>
              </Button>
            )}
            <section className={classes.content}>{children}</section>
          </div>
        </div>,
        document.body,
      )
    : null;
}

export default Modal;
