import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '../../../styles';
import Element, { ContentTag } from './Element';

const useStyles = makeStyles(({ palette, typography }: any) => ({
  // variants
  root: {
    fontFamily: typography.fontFamily,
    color: ({ color }: any) => color || 'inherit',
  },
  body1: {
    fontSize: 16,
  },
  body2: {
    fontSize: 14,
  },
  title1: {
    fontSize: 26,
  },
  title2: {
    fontSize: 22,
    fontWeight: typography.fontWeightBold,
  },
  title3: {
    fontSize: 20,
  },
  title4: {
    fontSize: 18,
    fontWeight: typography.fontWeightMedium,
  },
  caption: {
    fontSize: 12,
    color: `${palette.text.minor}!important`,
  },
  // themes
  text: {},
  info: {
    color: `${palette.primary.main}!important`,
  },
  bold: {
    fontWeight: typography.fontWeightMedium,
  },
  success: {
    color: `${palette.success.main}!important`,
  },
  error: {
    color: `${palette.error.main}!important`,
  },
  truncate: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export type Variant =
  | 'body1'
  | 'body2'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'title4'
  | 'caption';

export type Theme = 'text' | 'info' | 'bold' | 'success' | 'error';

type AbstractTextProps = {
  children: React.ReactNode;
  type?: ContentTag;
  variant?: Variant;
  theme?: Theme;
  className?: string;
  htmlFor?: string;
  truncate?: boolean;
};

const Typography = (props: AbstractTextProps) => {
  const classes = useStyles({});
  const {
    type = ContentTag.P,
    variant = 'body1',
    theme = 'text',
    className = '',
    children,
    htmlFor = null,
    truncate = false,
  } = props;
  return (
    <Element
      className={classNames(
        classes.root,
        { [classes[variant]]: type !== ContentTag.SPAN },
        classes[theme],
        { [classes.truncate]: truncate },
        className,
      )}
      type={type}
      htmlFor={htmlFor}
    >
      {children}
    </Element>
  );
};

export default Typography;
