export default function applicationReducerFactory(applicationKey, transformer) {
  return function applicationReducer(state = {}, action) {
    if (action.applicationKey === applicationKey) {
      switch (action.type) {
        case 'REQUEST_APPLICATION':
          return {
            ...state,
            [action.fieldSurveyId]: {
              ...state[action.fieldSurveyId],
              fetching: true,
            },
          };
        case 'SAVE_APPLICATION':
          return {
            ...state,
            [action.fieldSurveyId]: {
              ...state[action.fieldSurveyId],
              fetching: true,
            },
          };
        case 'UPDATE_APPLICATION':
          return {
            ...state,
            [action.fieldSurveyId]: {
              ...state[action.fieldSurveyId],
              fetching: true,
            },
          };
        case 'RECEIVE_APPLICATION':
          return {
            ...state,
            [action.fieldSurveyId]: {
              ...(transformer
                ? transformer(action.application)
                : action.application),
              fetching: false,
              errorCode: null,
            },
          };
        case 'REQUEST_APPLICATION_ERROR':
          return {
            ...state,
            [action.fieldSurveyId]: {
              ...state[action.fieldSurveyId],
              fetching: false,
              errorCode: action.errorCode,
            },
          };
        case 'SELECT_APPLICATION_LEVEL':
          return action.id;
        default:
          return state;
      }
    }
    return state;
  };
}
