const normalize = {
  html: {
    fontFamily: 'sans-serif',
    lineHeight: 1.15,
    msTextSizeAdjust: '100%',
    WebkitTextSizeAdjust: '100%',
  },
  body: { margin: '0' },
  'article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary': {
    display: 'block',
  },
  'audio, canvas, progress, video': { display: 'inline-block' },
  'audio:not([controls])': { display: 'none', height: '0' },
  progress: { verticalAlign: 'baseline' },
  'template, [hidden]': { display: 'none' },
  a: { backgroundColor: 'transparent', WebkitTextDecorationSkip: 'objects' },
  'a:active, a:hover': { outlineWidth: '0' },
  'abbr[title]': {
    borderBottom: 'none',
    textDecoration: ['underline', 'underline dotted'],
  },
  'b, strong': { fontWeight: 'bolder' },
  dfn: { fontStyle: 'italic' },
  h1: { fontSize: '2em', margin: '0.67em 0' },
  mark: { backgroundColor: '#ff0', color: '#000' },
  small: { fontSize: '80%' },
  'sub, sup': {
    fontSize: '75%',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline',
  },
  sub: { bottom: '-0.25em' },
  sup: { top: '-0.5em' },
  img: { borderStyle: 'none' },
  'code, kbd, pre, samp': {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  figure: { margin: '1em 40px' },
  hr: { boxSizing: 'content-box', height: '0', overflow: 'visible' },
  'button, input, optgroup, select, textarea': { font: 'inherit', margin: '0' },
  optgroup: { fontWeight: 'bold' },
  'button, input': { overflow: 'visible' },
  'button, select': { textTransform: 'none' },
  'button, html [type="button"], [type="reset"], [type="submit"]': {
    WebkitAppearance: 'button',
  },
  'button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner': {
    borderStyle: 'none',
    padding: '0',
  },
  'button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring': {
    outline: '1px dotted ButtonText',
  },
  fieldset: {
    border: '1px solid #c0c0c0',
    margin: '0 2px',
    padding: '0.35em 0.625em 0.75em',
  },
  legend: {
    boxSizing: 'border-box',
    color: 'inherit',
    display: 'table',
    maxWidth: '100%',
    padding: '0',
    whiteSpace: 'normal',
  },
  textarea: { overflow: 'auto' },
  '[type="checkbox"], [type="radio"]': {
    boxSizing: 'border-box',
    padding: '0',
  },
  '[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button': {
    height: 'auto',
  },
  '[type="search"]': { WebkitAppearance: 'textfield', outlineOffset: '-2px' },
  '[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration': {
    WebkitAppearance: 'none',
  },
  '::-webkit-input-placeholder': { color: 'inherit', opacity: 0.54 },
  '::-webkit-file-upload-button': {
    WebkitAppearance: 'button',
    font: 'inherit',
  },
  'html, body, div, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td': {
    margin: 0,
    padding: 0,
    border: 0,
    outline: 0,
    fontWeight: 'normal',
    fontStyle: 'normal',
    verticalAlign: 'baseline',
  },
};
export default normalize;
