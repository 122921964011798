import capitalize from 'libraries/wings-ui/utilities/captialize';

/**
 * Transform snake_case to camelCase
 */
function snakeToCamel(value: string): string {
  const splitWords = value.split('_');

  const camelCase = splitWords
    .map((word, i) => {
      if (i === 0) return word;
      return capitalize(word);
    })
    .join('');

  return camelCase;
}

export default snakeToCamel;
