function selectedApplicationLevelReducer(state = null, action = {}) {
  switch (action.type) {
    case 'SELECT_APPLICATION_LEVEL':
      return action.id;
    default:
      return state;
  }
}

export default selectedApplicationLevelReducer;
