import config from 'config';
import request from 'services/api/ApiClient';

export function authenticate() {
  const requestData = {
    baseURL: config.apiUrl,
    method: 'GET',
    requestName: 'ONSITE_JD_AUTH_REQUEST',
    url: '/v1.0/user/integrations/onsite/john-deere/authenticate',
  };
  return request(requestData);
}

export function pollNodes() {
  const requestData = {
    baseURL: config.apiUrl,
    method: 'GET',
    requestName: 'ONSITE_JD_NODES_REQUEST',
    url: '/v1.0/user/integrations/onsite/john-deere/nodes',
  };
  return request(requestData);
}

export function selectNode(fieldSurveyId, applicationId, data, order) {
  const requestData = {
    baseURL: config.apiUrl,
    method: 'POST',
    data,
    requestName: 'ONSITE_JD_SELECT_NODE',
    url: `v1.0/field-survey/${fieldSurveyId}/${applicationId}${
      order ? `/${order}` : ''
    }/integrations/onsite/john-deere`,
  };
  return request(requestData);
}

export function requestConversion(fieldSurveyId, applicationId, format, order) {
  const requestData = {
    baseURL: config.apiUrl,
    method: 'GET',
    requestName: 'ONSITE_CONVERSION_REQUEST',
    url: `/v1.0/field-survey/${fieldSurveyId}/${applicationId}${
      order ? `/${order}` : ''
    }/integrations/onsite/converter/${format}`,
  };
  return request(requestData);
}

export function downloadFileURL(trackingCode) {
  const requestData = {
    baseURL: config.apiUrl,
    method: 'GET',
    requestName: 'ONSITE_FILE_REQUEST',
    url: `/v1.0/integrations/onsite/converter/${trackingCode}/status`,
  };
  return request(requestData);
}

/**
 * Requests a list of equipment for converting shapefiles to xml from Onsite
 */
export function getOnsiteEquipmentList() {
  const requestData = {
    baseURL: config.apiUrl,
    method: 'GET',
    // TODO: set the correct request name.
    // Figure out how this plugs into the redux-saga flow
    requestName: 'ONSITE_EQUIPMENT_LIST_REQUEST',
    url: `/v1.0/integrations/onsite/converter/equipment`,
  };

  return request(requestData);
}
