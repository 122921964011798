import contourConfig from 'constants/contourConfig';

import transformer from './nitrogenVRAResponseToStateTransformer';
import applicationReducerFactory from '../applicationStateManagement/applicationReducerFactory';

const nitrogenApplicationReducer = applicationReducerFactory(
  contourConfig.nitrogenApplication.name,
  transformer,
);

export default nitrogenApplicationReducer;
