import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function SearchIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path
          d="M292,414.7c5.3-5,12.7,2.5,7.7,7.8S287,420,292,414.7Z"
          transform="translate(-286 -409)"
          fill="none"
        />
        <path
          d="M292.3,415.1c4.8-4.6,11.6,2.3,7.1,7S287.8,419.8,292.3,415.1Z"
          transform="translate(-286 -409)"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <line
        x1="14.1"
        y1="13.8"
        x2="19"
        y2="18.8"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
    </SvgIcon>
  );
}

export default SearchIcon;
