import { useRef, useLayoutEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash-es';

type Dimensions = {
  boundingClientRect?: any;
  heightFromScreenBottom?: number;
  height?: number;
  width?: number;
};

/**
 * Easy approach to getting element dimension references.
 * To extend add an additional property to setDimension in the layoutEffect.
 */
function useDimensions(): readonly [(node: any) => void, Dimensions] {
  const ref = useRef<HTMLElement | null>(null);
  const [dimensions, setDimensions] = useState<Dimensions>({});

  const setRef = useCallback((node: any) => {
    if (node !== null) {
      const boundingClientRect = node.getBoundingClientRect();
      const heightFromScreenBottom = window.innerHeight - node.offsetTop;
      setDimensions({
        boundingClientRect,
        heightFromScreenBottom,
        height: boundingClientRect?.height,
        width: boundingClientRect?.width,
      });
    }

    ref.current = node;
  }, []);

  const debouncedHandleResize = useCallback(
    () =>
      debounce(() => {
        const boundingClientRect = ref.current?.getBoundingClientRect();
        let heightFromScreenBottom: number;
        if (ref.current) {
          heightFromScreenBottom = window.innerHeight - ref.current?.offsetTop;
        }

        setDimensions(prevDimensions => ({
          ...prevDimensions,
          boundingClientRect,
          heightFromScreenBottom,
          width: boundingClientRect?.width,
        }));
      }, 150),
    [],
  );

  useLayoutEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);

    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, [debouncedHandleResize]);

  // const ensures typescript does not union the array through type inferance.
  return [setRef, dimensions] as const;
}

export default useDimensions;
