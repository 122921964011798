import { AnalysisConfig } from '../types/Analyses';

const rgbMap: AnalysisConfig = {
  id: 11,
  name: 'rgbMap',
  displayName: 'Photographic Imagery',
  inAPI: 'Photographic Imagery',
  type: 'analysis',
  url: 'analysis-photographic_imagery',
};

export default rgbMap;
