const typographyStyles = {
  "@import url('https://fonts.googleapis.com/css?family=Lato:700|Open+Sans:400,600&display=swap')": true,
  body: {
    fontSize: 'var(--base-font-size)',
    fontFamily: 'Roboto, Helvetica, sans-serif',
    color: 'var(--c-text-main)',
    lineHeight: 1.5,
  },
  ':h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6': {
    fontFamily: 'Lato',
    fontWeight: 'bold',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  h1: { fontSize: 'var(--large-font-size)' },
  a: { color: 'var(--hb-blue)', textDecoration: 'none' },
  'a:hover': { textDecoration: 'underline' },
};

export default typographyStyles;
