const histogramStyles = {
  '.histogram': {
    paddingTop: '15px',
    paddingBottom: '20px',
    minHeight: '170px',
  },
  '.histogramStatus': {
    fontWeight: 'bold',
    position: 'absolute',
    top: '180px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.histogramStatus.no-data': { color: 'var(--c-negative)' },
  '.histogramStatus.loading': { color: 'var(--c-text-main)' },
};

export default histogramStyles;
