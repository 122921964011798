import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { AnalysisConfig } from '../types/Analyses';

const plantMeanSizing: AnalysisConfig = {
  id: 1, // id should be same as ndvi
  name: 'ndviMax',
  displayName: 'Gridded NDVI Max',
  // note: Using Crop Health as surveys don't have Gridded NDVI Max - SFR 2021-03-08
  inAPI: 'Crop Health',
  type: 'analysis',
  url: 'analysis-gridded_ndvi_max',
  status: 'disabled',
  config: analysisBuilder().addColourList(['#B31C08', '#E6E60B']).build(),
};

export default plantMeanSizing;
