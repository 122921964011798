import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { SurveySourceType } from 'types/surveySourceType';
import { ApplicationConfig } from './ApplicationConfig';

const postEmHerbicideApplication: ApplicationConfig = {
  id: 29,
  name: 'postEmHerbicideApplication',
  displayName: 'Post-emergence Herbicide (Contoured)',
  inAPI: 'Post-emergence Herbicide application / Weed Detection',
  type: 'application',
  apiEndPoint: fieldSurveyId =>
    `/v1.0/hb-application/field-survey/${fieldSurveyId}/application-post_emergence_herbicide/analysis-weed_detection`,
  url: 'application-post_emergence_herbicide/analysis-weed_detection',
  base: 'weedDetection',
  surveySources: { [SurveySourceType.UAV]: true },
  config: analysisBuilder()
    .addUnits('unit.kilogramsPerHectare')
    .addBarStep(1)
    .addDefaultRange({
      lower: 0,
      upper: 120,
    })
    .addColourList(['#FFF987', '#FBAD5E', '#DD635A', '#A12660', '#4A0B5E'])
    .build(),
  status: 'deprecated',
};

export default postEmHerbicideApplication;
