import { getToken } from '@hummingbirdtechgroup/wings-auth';
import config from 'config';

export default function (channel: string, message: any): Promise<any> {
  return fetch(`${config.adminSlackBotUrl}/post-message`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      channel,
      ...message,
    }),
  }).then(res => res.json());
}
