export const ONSITE_JD_AUTH = 'ONSITE_JD_AUTH';
export const ONSITE_JD_RECEIVE_URL = 'ONSITE_JD_RECEIVE_URL';
export const ONSITE_JD_REQUEST_NODES = 'ONSITE_JD_REQUEST_NODES';
export const ONSITE_JD_RECEIVE_NODES = 'ONSITE_JD_RECEIVE_NODES';
export const ONSITE_JD_SELECT_NODE = 'ONSITE_JD_SELECT_NODE';
export const ONSITE_JD_TRANSFER_SUCCESS = 'ONSITE_JD_TRANSFER_SUCCESS';
export const ONSITE_FINISH_PROCESS = 'ONSITE_FINISH_PROCESS';
export const ONSITE_REQUEST_CONVERSION = 'ONSITE_REQUEST_CONVERSION';
export const ONSITE_CONVERSION_ERROR = 'ONSITE_CONVERSION_ERROR';
export const ONSITE_EQUIPMENT_LIST_REQUEST = 'ONSITE_EQUIPMENT_LIST_REQUEST';
export const ONSITE_EQUIPMENT_LIST_RECIEVED = 'ONSITE_EQUIPMENT_LIST_RECIEVED';
