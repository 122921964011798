/* eslint-disable camelcase */
import en_metric from './metric/en-UK.json';
import pt_metric from './metric/pt-BR.json';
import ru_metric from './metric/ru-RU.json';
import uk_metric from './metric/uk-UA.json';
import en_imperial_ca from './imperial_ca/en-UK.json';
import pt_imperial_ca from './imperial_ca/pt-BR.json';
import ru_imperial_ca from './imperial_ca/ru-RU.json';
import uk_imperial_ca from './imperial_ca/uk-UA.json';
import conversionFactors_imperial_ca from './imperial_ca/conversion-factors.json';
import en_imperial_us from './imperial_us/en-UK.json';
import pt_imperial_us from './imperial_us/pt-BR.json';
import ru_imperial_us from './imperial_us/ru-RU.json';
import uk_imperial_us from './imperial_us/uk-UA.json';
import conversionFactors_imperial_us from './imperial_us/conversion-factors.json';
import type { ConversionTables } from '../unit-constants';

const conversionTables: ConversionTables = {
  metric: {
    'en-UK': en_metric,
    'pt-BR': pt_metric,
    'ru-RU': ru_metric,
    'uk-UA': uk_metric,
  },
  imperial_ca: {
    'en-UK': en_imperial_ca,
    'pt-BR': pt_imperial_ca,
    'ru-RU': ru_imperial_ca,
    'uk-UA': uk_imperial_ca,
    conversionFactors: conversionFactors_imperial_ca,
  },
  imperial_us: {
    'en-UK': en_imperial_us,
    'pt-BR': pt_imperial_us,
    'ru-RU': ru_imperial_us,
    'uk-UA': uk_imperial_us,
    conversionFactors: conversionFactors_imperial_us,
  },
};
export default conversionTables;
