import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function AddGrowingSeasonIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <line
        x1="17.1"
        y1="21.9"
        x2="17.1"
        y2="11.6"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <line
        x1="22.2"
        y1="16.7"
        x2="12"
        y2="16.7"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <g>
        <g>
          <path
            d="M294,416.1a1.9,1.9,0,1,1,0,3.7Z"
            transform="translate(-285.4 -409.3)"
            fill="none"
          />
          <path
            d="M294.3,416.4v3.1a1.6,1.6,0,0,0,0-3.1m-.5-.6a2.2,2.2,0,1,1,0,4.3Z"
            transform="translate(-285.4 -409.3)"
            fill="currentColor"
          />
        </g>
        <line
          x1="3.8"
          y1="8.6"
          x2="1"
          y2="8.6"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <line
          x1="5.2"
          y1="12.9"
          x2="3.8"
          y2="14.6"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <line
          x1="3.1"
          y1="2.6"
          x2="5.2"
          y2="4.4"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <line
          x1="8.8"
          y1="0.5"
          x2="8.8"
          y2="2.6"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <line
          x1="8.8"
          y1="16.7"
          x2="8.8"
          y2="13.9"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          d="M294.4,418h6.1"
          transform="translate(-285.4 -409.3)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <path
          d="M294.3,417.5l3.9-3.8"
          transform="translate(-285.4 -409.3)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="11.9"
          y1="8.6"
          x2="13.4"
          y2="7.2"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="11"
          y1="6.1"
          x2="11"
          y2="4.5"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="14.1"
          y1="8.6"
          x2="15.5"
          y2="7.2"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="12.2"
          y1="4.9"
          x2="12.2"
          y2="3.4"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="11.9"
          y1="8.6"
          x2="13.4"
          y2="10"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="11"
          y1="6.1"
          x2="12.6"
          y2="6.1"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="14.1"
          y1="8.6"
          x2="15.5"
          y2="10"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="12.2"
          y1="4.9"
          x2="13.8"
          y2="5"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <path
          d="M294.3,418.3l4.1,4"
          transform="translate(-285.4 -409.3)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="11.1"
          y1="11.1"
          x2="11.1"
          y2="12.7"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="12.4"
          y1="12.3"
          x2="12.4"
          y2="14"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="11.1"
          y1="11.1"
          x2="12.8"
          y2="11"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="12.4"
          y1="12.3"
          x2="14.1"
          y2="12.3"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <g>
          <path
            d="M294,414.3v7.4a3.9,3.9,0,0,1-3.3-3.7A3.8,3.8,0,0,1,294,414.3Z"
            transform="translate(-285.4 -409.3)"
            fill="none"
          />
          <path
            d="M293.5,421v-6.1a3.2,3.2,0,0,0,0,6.1m1,1.2a4.3,4.3,0,1,1,0-8.5Z"
            transform="translate(-285.4 -409.3)"
            fill="currentColor"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default AddGrowingSeasonIcon;
