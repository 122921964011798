import initialState from './model';
import * as actionTypes from './actionTypes';

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.ONSITE_JD_RECEIVE_URL:
      return {
        ...state,
        authUrl: action.authUrl,
      };

    case actionTypes.ONSITE_JD_RECEIVE_NODES:
      return {
        ...state,
        jdNodes: action.jdNodes,
      };
    case actionTypes.ONSITE_JD_SELECT_NODE:
      return {
        ...state,
        selectedNode: action.nodeId,
        loading: true,
      };

    case actionTypes.ONSITE_JD_TRANSFER_SUCCESS:
      return {
        ...state,
        transferSuccess: true,
        loading: false,
      };

    case actionTypes.ONSITE_REQUEST_CONVERSION:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.ONSITE_CONVERSION_ERROR:
      return {
        ...state,
        conversionError: action.conversionError,
        loading: false,
      };
    case actionTypes.ONSITE_FINISH_PROCESS:
      return initialState;

    case actionTypes.ONSITE_EQUIPMENT_LIST_REQUEST:
      return state;

    case actionTypes.ONSITE_EQUIPMENT_LIST_RECIEVED:
      return {
        ...state,
        onsiteEquipmentList: action.equipmentList,
      };
    default:
      return state;
  }
}

export default reducer;
