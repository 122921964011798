import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';
import { Popover, PopoverPosition, PopoverAlign } from 'react-tiny-popover';

import { makeStyles, Theme } from '../../styles';

interface ToolTipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  /** className(s) provided to the span wrapping the children */
  spanClassName?: string;
  position?: Exclude<PopoverPosition, 'custom'>[];
  align?: Exclude<PopoverAlign, 'custom'>;
}

const useStyles = makeStyles(
  ({ palette, spacing, componentSizing, zIndex }: Theme) => ({
    tooltip: {
      maxWidth: componentSizing.tooltipWidth,
      backgroundColor: palette.background.default,
      padding: spacing(),
      border: `1px solid ${palette.secondary.main}`,
      borderRadius: spacing(0.5),
    },
    container: {
      zIndex: zIndex.tooltip,
    },
  }),
);

function ToolTip({
  children,
  content,
  spanClassName,
  align,
  position,
}: ToolTipProps): React.ReactElement {
  const classes = useStyles();
  // unsafe because it could be called when the component is unmounted creating a memory leak
  const [hover, unsafeSetHover] = useState(false);

  // ensures the set state is only called if the component is mounted
  const mountedRef = useRef(false);
  useLayoutEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const setHover = useCallback((hoverState: boolean) => {
    if (mountedRef.current) {
      unsafeSetHover(hoverState);
    }
  }, []);

  return (
    <Popover
      isOpen={hover}
      containerClassName={classes.container}
      content={<div className={classes.tooltip}>{content}</div>}
      align={align}
      positions={position}
    >
      <span
        className={spanClassName}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {children}
      </span>
    </Popover>
  );
}

export default ToolTip;
