import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RestLink } from 'apollo-link-rest';
import { v4 as uuid } from 'uuid';

import { snakeToCamel } from 'utilities/snakeToCamel';
import { authHandlerLink } from './authHandler';
import { sentryErrorLogLink } from './errorHandler';
import cache from './cache';

const setRequestIdLink = setContext((_, prevContext) => ({
  ...prevContext,
  headers: {
    ...prevContext?.headers,
    // a unique request ID for debugging: this is logged on the server for each request
    'X-Request-ID': uuid(),
  },
}));

const restLink = (apiUrl: string) =>
  new RestLink({
    uri: apiUrl,
    fieldNameNormalizer: field => snakeToCamel(field),
  });

const httpLink = (gqlGatewayUrl: string) =>
  new HttpLink({
    uri: `${gqlGatewayUrl}/graphql`,
  });

export default (gqlGatewayUrl: string, apiUrl: string) =>
  new ApolloClient({
    link: ApolloLink.from([
      authHandlerLink,
      restLink(apiUrl),
      setRequestIdLink,
      sentryErrorLogLink,
      httpLink(gqlGatewayUrl),
    ]),
    cache,
    queryDeduplication: true,
    name: 'webapp',
    version: process.env.APP_VERSION,
  });
