/**
 * This code is being borrowed from the excellent Material-ui library.
 * We want to use the `createMuiTheme` function without any of the core library overhead.
 * Original code can be found here: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createSpacing.js
 * And here: https://github.com/mui-org/material-ui/blob/master/packages/material-ui-system/src/spacing.js
 */

export function createUnarySpacing(theme) {
  const themeSpacing = theme.spacing || 8;

  if (typeof themeSpacing === 'number') {
    return abs => {
      if (process.env.NODE_ENV !== 'production') {
        if (typeof abs !== 'number') {
          console.error(
            `Material-UI: Expected spacing argument to be a number, got ${abs}.`,
          );
        }
      }
      return themeSpacing * abs;
    };
  }

  if (Array.isArray(themeSpacing)) {
    return abs => {
      if (process.env.NODE_ENV !== 'production') {
        if (abs > themeSpacing.length - 1) {
          console.error(
            [
              `Material-UI: The value provided (${abs}) overflows.`,
              `The supported values are: ${JSON.stringify(themeSpacing)}.`,
              `${abs} > ${
                themeSpacing.length - 1
              }, you need to add the missing values.`,
            ].join('\n'),
          );
        }
      }

      return themeSpacing[abs];
    };
  }

  if (typeof themeSpacing === 'function') {
    return themeSpacing;
  }

  if (process.env.NODE_ENV !== 'production') {
    console.error(
      [
        `Material-UI: The \`theme.spacing\` value (${themeSpacing}) is invalid.`,
        'It should be a number, an array or a function.',
      ].join('\n'),
    );
  }

  return () => undefined;
}

let warnOnce;

export default function createSpacing(spacingInput = 8) {
  // Already transformed.
  if (spacingInput.mui) {
    return spacingInput;
  }

  // Material Design layouts are visually balanced. Most measurements align to an 8dp grid applied, which aligns both spacing and the overall layout.
  // Smaller components, such as icons and type, can align to a 4dp grid.
  // https://material.io/design/layout/understanding-layout.html#usage
  const transform = createUnarySpacing({
    spacing: spacingInput,
  });

  const spacing = (...args) => {
    if (process.env.NODE_ENV !== 'production') {
      if (!(args.length <= 4)) {
        console.error(
          `Material-UI: Too many arguments provided, expected between 0 and 4, got ${args.length}`,
        );
      }
    }

    if (args.length === 0) {
      return transform(1);
    }

    if (args.length === 1) {
      return transform(args[0]);
    }

    return args
      .map(argument => {
        if (typeof argument === 'string') {
          return argument;
        }
        const output = transform(argument);
        return typeof output === 'number' ? `${output}px` : output;
      })
      .join(' ');
  };

  // Backward compatibility, to remove in v5.
  Object.defineProperty(spacing, 'unit', {
    get: () => {
      if (process.env.NODE_ENV !== 'production') {
        if (!warnOnce || process.env.NODE_ENV === 'test') {
          console.error(
            [
              'Material-UI: theme.spacing.unit usage has been deprecated.',
              'It will be removed in v5.',
              'You can replace `theme.spacing.unit * y` with `theme.spacing(y)`.',
              '',
              'You can use the `https://github.com/mui-org/material-ui/tree/master/packages/material-ui-codemod/README.md#theme-spacing-api` migration helper to make the process smoother.',
            ].join('\n'),
          );
        }

        warnOnce = true;
      }
      return spacingInput;
    },
  });
  spacing.mui = true;

  return spacing;
}
