import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '../../styles';

const useStyles = makeStyles(() => ({
  value: {
    marginLeft: 5,
    verticalAlign: 'middle',
  },
}));

function ProgressButton(props) {
  const { progress } = props;
  const styles = useStyles();
  return (
    <div>
      {!progress && (
        <button
          type="button"
          className="button--primary upload-survey"
          onClick={props.submit}
        >
          {props.children}
        </button>
      )}
      {progress && (
        <div>
          <progress value={progress} max="100">
            {progress}%
          </progress>
          <span className={styles.value}>{progress}%</span>
        </div>
      )}
    </div>
  );
}

ProgressButton.propTypes = {
  progress: PropTypes.number,
  submit: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default ProgressButton;
