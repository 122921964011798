/**
 * To further customise the styles read the docs here: https://react-select.com/styles
 */
import { StylesConfig, Styles } from 'react-select';
import { Direction } from './DropDown';
import { useTheme } from '../../styles';

export const useCustomStyles = (): Styles<any, any> => {
  const { palette, spacing, typography, zIndex }: any = useTheme();

  const setControlBorderRadius = (
    menuIsOpen: boolean,
    direction?: Direction,
  ) => {
    if (!menuIsOpen) return spacing(0.5);
    if (direction === 'bottom') return spacing(0.5, 0.5, 0, 0);
    return spacing(0, 0, 0.5, 0.5);
  };

  const customStyles: StylesConfig<any, any> = {
    container: (provided, { selectProps }) => ({
      ...provided,
      fontFamily: typography.fontFamily,
      fontSize: 14,
      borderRadius: spacing(0.5),
      margin: selectProps.noMargin ? '0px' : spacing(1, 0),
    }),
    control: (
      provided,
      { isFocused, menuIsOpen, selectProps: { menuPlacement } }: any,
    ) => {
      const focusOpen = isFocused && menuIsOpen;
      return {
        ...provided,
        border: `1px solid ${palette.grey.lightTint}`,
        color: palette.text.main,
        cursor: 'pointer',
        boxShadow: focusOpen ? 'none' : undefined,
        borderColor: focusOpen
          ? `${palette.secondary.main} !important`
          : undefined,
        borderRadius: setControlBorderRadius(menuIsOpen, menuPlacement),
        margin: 'unset !important',
        padding: 'unset !important',
        height: spacing(4),
        minHeight: spacing(4),
        '&:hover': focusOpen && {
          borderColor: palette.secondary.main,
        },
      };
    },
    valueContainer: provided => ({
      ...provided,
      height: '100%',
      paddingTop: 0,
      paddingBottom: 0,
      flexWrap: 'initial',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
      margin: 0,
      height: '100%',
      color: palette.text.main,
    }),
    indicatorsContainer: provider => ({
      ...provider,
      height: '100%',
      color: palette.text.main,
      padding: spacing(0.5),
    }),
    menu: (provided, { selectProps: { menuPlacement } }) => ({
      ...provided,
      margin:
        menuPlacement === 'bottom' ? '-1px 0px 0px 0px' : '0px 0px -1px 0px',
      borderRadius:
        menuPlacement === 'bottom'
          ? spacing(0, 0, 0.5, 0.5)
          : spacing(0.5, 0.5, 0, 0),
      border: `1px solid ${palette.secondary.main}`,
      boxShadow: 'none',
      overflow: 'hidden',
      zIndex: zIndex.leafletMax + 200,
      maxHeight: 250,
      scrollbarWidth: 'none',
    }),
    menuPortal: provided => ({
      ...provided,
      zIndex: zIndex.leafletMax + 200,
    }),
    menuList: provided => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: 250,
      zIndex: zIndex.leafletMax + 200,
    }),
    option: (provided, { data, isSelected, isFocused }) => ({
      ...provided,
      color: data.value === 'deselect' ? palette.grey.main : palette.text.main,
      fontFamily: typography.fontFamily,
      fontSize: 14,
      cursor: 'pointer',
      backgroundColor: isSelected && palette.background.highlight,
      background: isFocused && palette.grey.lighter,
      '&:not(:last-child)': {
        borderBottom: `1px solid ${palette.grey.lightTint}`,
      },
    }),
    multiValue: provided => ({
      ...provided,
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '33%',
      minWidth: '10%',
    }),
  };

  return customStyles;
};
