import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function SatelliteIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 51 50">
      <g fill="currentColor" fillRule="evenodd">
        <path d="M29.615 29.056l-.75-.97-2.096 1.537a1.05 1.05 0 0 1-1.451-.204l-3.727-4.811-1.81 1.326a.175.175 0 0 1-.242-.034l-.51-.66a.175.175 0 0 1 .034-.248l1.802-1.321-3.693-4.768a1.05 1.05 0 0 1 .21-1.49l1.077-.79-.77-.992a.7.7 0 0 1 .14-.994l2.116-1.55a.7.7 0 0 1 .967.135l.792 1.023 1.122-.823a1.05 1.05 0 0 1 1.451.204l3.727 4.811 1.81-1.326a.175.175 0 0 1 .242.034l.511.66a.175.175 0 0 1-.035.248l-1.802 1.321 3.693 4.768a1.05 1.05 0 0 1-.21 1.49l-2.05 1.503.75.97c3.342-1.961 7.713-1.21 10.087 1.855L28.669 39c-2.375-3.065-1.912-7.36.946-9.944zm7.88 8.859c-.538.394-1.299.294-1.7-.223-.4-.518-.29-1.257.249-1.65.537-.395 1.298-.295 1.699.222.4.517.29 1.256-.248 1.65zM10.594 26.835l2.33 3.092-4.622 3.484-2.277-2.97a.175.175 0 0 1 .035-.247l4.534-3.359zm.67-.495l4.481-3.32a.175.175 0 0 1 .243.035l2.218 2.892-4.617 3.48-2.326-3.087zm7.434.25l2.277 2.969a.175.175 0 0 1-.035.247l-4.534 3.359-2.33-3.092 4.622-3.484zm-2.961 7.07l-4.482 3.32a.175.175 0 0 1-.243-.035l-2.218-2.892 4.617-3.48 2.326 3.087zM33.189 9.914l2.258 2.997-4.42 3.33-2.008-2.806a.175.175 0 0 1 .034-.239l4.136-3.282zm.608-.482l4.284-3.4a.175.175 0 0 1 .25.036L40.503 9.1l-4.435 3.342-2.27-3.011zm7.176.327l2.008 2.806c.054.075.04.18-.034.239l-4.136 3.282-2.258-2.997 4.42-3.33zm-2.77 6.81l-4.284 3.399a.175.175 0 0 1-.25-.036L31.497 16.9l4.435-3.342 2.27 3.011z" />
      </g>
    </SvgIcon>
  );
}

export default SatelliteIcon;
