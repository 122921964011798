import { useState, useCallback, useLayoutEffect } from 'react';
import type { LatLngTuple } from 'leaflet';

const geoOptions = {
  enableHighAccuracy: true,
  maximumAge: 30000,
  timeout: 27000,
};

const LONDON_LOCATION = [51.505, -0.09] as LatLngTuple;

type Geolocation = {
  /** array with [Lat, Lng] position */
  position: LatLngTuple;
  error?: GeolocationPositionError;
  status: 'default' | 'set';
};

function useMyLocation(): Geolocation {
  const [watchId, setWatchId] = useState<number>();

  const [geolocation, setState] = useState<Geolocation>({
    position: LONDON_LOCATION,
    status: 'default',
  });

  const geoSuccess = useCallback(({ coords }: GeolocationPosition) => {
    setState({
      position: [coords.latitude, coords.longitude],
      status: 'set',
    });
  }, []);

  const geoError = (error: GeolocationPositionError) => {
    // maintain the last position if there was an error
    setState(prevState => ({ ...prevState, error }));
  };

  useLayoutEffect(() => {
    if (!watchId) {
      const id = navigator.geolocation.watchPosition(
        geoSuccess,
        geoError,
        geoOptions,
      );
      setWatchId(id);
    }

    return () => {
      if (watchId) navigator.geolocation.clearWatch(watchId);
    };
  }, [geoSuccess, geolocation, watchId]);

  return geolocation;
}

export default useMyLocation;
