import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function FolderEmptyIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="-10 -17 70 70">
      <g fill="currentColor">
        <path d="M45.75 8V4.06h-26.5l-.5-.18L16.36 3H4.75v5h41m3 3h-47V0h15.14l2.89 1.06h29z" />
        <path d="M47.35 34.5H3.16L1.6 9.5h47.48z" />
        <path d="M3.19 11l1.38 22H46l1.52-22H3.19M0 8h50.69l-1.94 28h-47z" />
      </g>
    </SvgIcon>
  );
}

export default FolderEmptyIcon;
