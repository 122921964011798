import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '../../styles';

const useStyles = makeStyles(() => ({
  handle: {
    position: 'absolute',
    marginLeft: '-8px',
    marginTop: '-5px',
    zIndex: 2,
    width: 16,
    top: 25,
    height: 16,
    cursor: 'pointer',
    borderRadius: '50%',
  },

  handleValue: {
    position: 'relative',
    float: 'none',
    marginLeft: '-8px',
    marginTop: 15,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

export default function SliderHandle({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
  colour,
  formatNumber,
}) {
  const classes = useStyles();
  return (
    <div
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      {...getHandleProps(id)}
    >
      <div
        className={classes.handle}
        style={{
          left: `${percent}%`,
          background: colour,
        }}
      >
        <p className={classes.handleValue}>{formatNumber(value)}</p>
      </div>
    </div>
  );
}

SliderHandle.defaultProps = {
  colour: '#000000',
};

SliderHandle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  colour: PropTypes.string,
  formatNumber: PropTypes.func.isRequired,
};
