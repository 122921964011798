import { AnalysisConfig } from '../types/Analyses';

const cropHealth: AnalysisConfig = {
  id: 1,
  name: 'cropHealth',
  displayName: 'NDVI (Crop Health)',
  inAPI: 'Crop Health',
  type: 'analysis',
  url: 'analysis-crop_health',
  clouds: true,
};

export default cropHealth;
