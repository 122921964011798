import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { SurveySourceType } from 'types/surveySourceType';
import { ApplicationConfig } from './ApplicationConfig';

const pgrApplication: ApplicationConfig = {
  id: 30,
  name: 'pgrApplication',
  displayName: 'PGR (NDVI) (Contoured)',
  inAPI: 'PGR Application / Gridded NDVI',
  type: 'application',
  apiEndPoint: fieldSurveyId =>
    `/v1.0/hb-application/field-survey/${fieldSurveyId}/application-pgr/analysis-gridded_ndvi`,
  url: 'application-pgr/analysis-gridded_ndvi',
  base: 'cropHealth',
  surveySources: {
    [SurveySourceType.UAV]: true,
    [SurveySourceType.SATELLITE]: true,
  },
  config: analysisBuilder()
    .addUnits('unit.litresPerHectare')
    .addBarStep(1)
    .addDefaultRange({
      lower: 0,
      upper: 120,
    })
    .addColourList(['#FCE5C6', '#DBABA0', '#AA788A', '#665176', '#003256'])
    .build(),
};

export default pgrApplication;
