/* eslint-disable camelcase */
import { round } from 'lodash-es';
import unitConstants, {
  UnitSystem,
  UnitId,
  UnitContext,
} from '../unit-constants';

const { UNIT_SYSTEMS, UNIT_IDS, UNIT_CONTEXT } = unitConstants;

/**
 * Pure function that returns a converted value.
 */
function getUnitValue(
  value: number,
  unitId: UnitId,
  systemFrom: UnitSystem = 'metric',
  systemTo: UnitSystem,
  conversionTables: any,
  precision = 2,
  context: UnitContext = 'general',
): number {
  if (!value) return value;

  if (!UNIT_SYSTEMS.includes(systemTo))
    throw new Error(
      `The selected system is not supported. Please choose one of the following unit systems: [${UNIT_SYSTEMS.join(
        ', ',
      )}]`,
    );

  if (!UNIT_SYSTEMS.includes(systemFrom))
    throw new Error(
      `The selected system is not supported. Please choose one of the following unit systems: [${UNIT_SYSTEMS.join(
        ', ',
      )}]`,
    );

  if (!UNIT_IDS.includes(unitId))
    throw new Error(
      `The selected unit id is not supported. Please select one of the following unit ids: [${UNIT_CONTEXT.join(
        ', ',
      )}]`,
    );

  if (!UNIT_CONTEXT.includes(context))
    throw new Error(
      `The selected context is not supported. Please select on of the following unit contexts: [${UNIT_CONTEXT.join(
        ', ',
      )}]`,
    );

  if (systemFrom === systemTo) {
    return round(value, precision);
  }

  if (systemFrom === 'metric') {
    return round(
      value * conversionTables[systemTo].conversionFactors[unitId][context],
      precision,
    );
  }

  return round(
    value / conversionTables[systemFrom].conversionFactors[unitId][context],
    precision,
  );
}

export default getUnitValue;
