import React from 'react';
import { SvgIcon } from '../../components';

function LogoutIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        d="M11.53 12h10.22M22.25 12l-4 4M22.25 12l-4-4M18.11 3.5H3M18.11 20.5H3M3 20.5v-17"
      />
    </SvgIcon>
  );
}

export default LogoutIcon;
