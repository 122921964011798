import { EN, PT, UK, RU } from 'libraries/unitConversion/unit-constants';

const languages = [EN, PT, UK, RU];

// clean old localStore data while migrating to new authentication system
function migrate() {
  const unitSystem = localStorage.getItem('unitSystem');
  // checking language object doesn't contain old keys
  if (unitSystem) {
    if (!languages.includes(JSON.parse(unitSystem).language))
      localStorage.clear();
  }
}

migrate();
