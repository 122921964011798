import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function DeleteIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path
          d="M290.9,415.3h13v14a1,1,0,0,1-1,1h-11a.9.9,0,0,1-1-1v-14Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <rect
          x="6"
          y="6.5"
          width="12"
          height="14"
          rx="0.5"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <line
        x1="4.5"
        y1="6.2"
        x2="19.9"
        y2="6.2"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="12.2"
        y1="8.8"
        x2="12.2"
        y2="17.8"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="8.6"
        y1="8.8"
        x2="8.6"
        y2="17.8"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="15.8"
        y1="8.8"
        x2="15.8"
        y2="17.8"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <g>
        <path
          d="M294.4,412.3h6v4h-6Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <rect
          x="9.5"
          y="3.5"
          width="5"
          height="3"
          fill="none"
          stroke="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default DeleteIcon;
