import React from 'react';
import { SvgIcon } from '../../components';

function CrossIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <line
        stroke="currentColor"
        strokeWidth="2px"
        strokeLinecap="round"
        x1="10"
        y1="10"
        x2="22"
        y2="22"
      />
      <line
        stroke="currentColor"
        strokeWidth="2px"
        strokeLinecap="round"
        x1="22"
        y1="10"
        x2="10"
        y2="22"
      />
    </SvgIcon>
  );
}

export default CrossIcon;
