const pushStyles = {
  '.push': { margin: 'var(--spacing-root)' },
  '.push--double': { margin: 'var(--spacing-double)' },
  '.push--half': { margin: 'var(--spacing-half)' },
  '.push--ends': {
    marginTop: 'var(--spacing-root)',
    marginBottom: 'var(--spacing-root)',
  },
  '.push--sides': {
    marginLeft: 'var(--spacing-root)',
    marginRight: 'var(--spacing-root)',
  },
  '.push--left': { marginLeft: 'var(--spacing-root)' },
  '.push--right': { marginRight: 'var(--spacing-root)' },
  '.push--top': { marginTop: 'var(--spacing-root)' },
  '.push--double-top': { marginTop: 'var(--spacing-double)' },
  '.push--btm': { marginBottom: 'var(--spacing-root)' },
  '.push--center': { textAlign: 'center' },
  '.push--none': { margin: '0' },
};

export default pushStyles;
