/* eslint-disable no-underscore-dangle */
import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import apiMiddleware from 'services/api/apiMiddleware';
import serviceWorkerEventMiddleware from 'services/serviceWorker/serviceWorkerEventMiddleware';

import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  {},
  compose(
    applyMiddleware(
      sagaMiddleware,
      apiMiddleware,
      serviceWorkerEventMiddleware,
    ),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f,
  ),
);

sagaMiddleware.run(rootSaga);

if (module.hot) {
  module.hot.accept('./rootReducer', () => {
    const nextRootReducer = rootReducer;

    store.replaceReducer(nextRootReducer);
  });
}

export default store;
