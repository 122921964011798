/**
 * NOTE: These applications are to be deprecated later this year (2020)
 */
import { SurveySourceType } from 'types/surveySourceType';
import { ApplicationConfig } from '../ApplicationConfig';
import sclerotiniaApplication from '../../reduxState/sclerotiniaApplication/sclerotiniaApplicationAnalysis';
import cerealDesiccationApplication from '../../reduxState/griddedDesiccationApplication/desiccationApplicationAnalysis';
import variableTotalHerbicide from '../../reduxState/variableTotalHerbicide/variableTotalHerbicideAnalysis';
import nitrogenApplication from '../../reduxState/nitrogenApplication/nitrogenApplicationAnalysis';
import pgrApplicationFromGAI from '../../reduxState/pgrApplicationFromGAI/pgrApplicationFromGAIAnalysis';
import nitrogenApplicationFromGAI from '../../reduxState/nitrogenApplicationFromGAI/nitrogenApplicationFromGAIAnalysis';
import variablePostEmergenceHerbicide from '../../reduxState/variablePostEmergenceHerbicide/variablePostEmergenceHerbicideAnalysis';
import variablePreEmergenceHerbicide from '../../reduxState/variablePreEmergenceHerbicide/variablePreEmergenceHerbicideAnalysis';
import ndviSeedApplication from '../../reduxState/NDVISeedApplication/NDVISeedApplicationAnalysis';

export type GriddedApplications = {
  nitrogenApplication: ApplicationConfig;
  sclerotiniaApplication: ApplicationConfig;
  griddedDesiccationApplication: ApplicationConfig;
  nitrogenApplicationFromGAI: ApplicationConfig;
  pgrApplicationFromGAI: ApplicationConfig;
  variableTotalHerbicide: ApplicationConfig;
  variablePreEmergenceHerbicide: ApplicationConfig;
  variablePostEmergenceHerbicide: ApplicationConfig;
  NDVISeedApplication: ApplicationConfig;
};

const griddedApplicationContourConfig: GriddedApplications = {
  nitrogenApplication: {
    id: 2,
    name: 'nitrogenApplication',
    displayName: 'Nitrogen (NDVI) (Gridded)',
    inAPI: 'Nitrogen Application from NDVI',
    type: 'application',
    apiEndPoint: (fieldSurveyId: string): string =>
      `/v1.1/field-survey/${fieldSurveyId}/nitrogen-variable-rate-application`,
    url: 'nitrogen-variable-rate-application',
    base: 'cropHealth',
    config: nitrogenApplication,
    gridded: true,
    surveySources: {
      [SurveySourceType.UAV]: true,
      [SurveySourceType.SATELLITE]: true,
    },
  },
  sclerotiniaApplication: {
    id: 10,
    name: 'sclerotiniaApplication',
    displayName: 'Sclerotinia (Gridded)',
    inAPI: 'Sclerotinia Application',
    type: 'application',
    apiEndPoint: (fieldSurveyId: string): string =>
      `/v1.0/field-survey/${fieldSurveyId}/sclerotinia-variable-rate-application`,
    url: 'sclerotinia-variable-rate-application',
    base: 'cropHealth',
    binary: true,
    config: sclerotiniaApplication,
    gridded: true,
    surveySources: {
      [SurveySourceType.UAV]: true,
      [SurveySourceType.SATELLITE]: true,
    },
    status: 'deprecated',
  },
  griddedDesiccationApplication: {
    id: 13,
    name: 'griddedDesiccationApplication',
    displayName: 'Desiccation (Spot) (Gridded)',
    inAPI: 'Cereal Desiccation Application',
    type: 'application',
    apiEndPoint: (fieldSurveyId: string): string =>
      `/v1.0/field-survey/${fieldSurveyId}/cereal-desiccation-application`,
    url: 'cereal-desiccation-application',
    base: 'cropHealth',
    binary: true,
    config: cerealDesiccationApplication,
    gridded: true,
    surveySources: {
      [SurveySourceType.UAV]: true,
      [SurveySourceType.SATELLITE]: true,
    },
    status: 'deprecated',
  },
  nitrogenApplicationFromGAI: {
    id: 16,
    name: 'nitrogenApplicationFromGAI',
    displayName: 'Nitrogen (GAI) (Gridded)',
    inAPI: 'Nitrogen Application from GAI',
    type: 'application',
    apiEndPoint: (fieldSurveyId: string): string =>
      `/v2.1/field-survey/${fieldSurveyId}/nitrogen-variable-rate-application-from-green-area-index`,
    url: 'nitrogen-variable-rate-application-from-green-area-index',
    base: 'gai',
    config: nitrogenApplicationFromGAI,
    gridded: true,
    surveySources: {
      [SurveySourceType.UAV]: true,
      [SurveySourceType.SATELLITE]: true,
    },
  },
  pgrApplicationFromGAI: {
    id: 18,
    name: 'pgrApplicationFromGAI',
    displayName: 'PGR Application from GAI',
    inAPI: 'PGR Application from GAI',
    type: 'application',
    apiEndPoint: (fieldSurveyId: string): string =>
      `/v1.1/field-survey/${fieldSurveyId}/pgr-application-from-green-area-index`,
    url: 'pgr-application-from-green-area-index',
    base: 'gai',
    config: pgrApplicationFromGAI,
    gridded: true,
    surveySources: {
      [SurveySourceType.UAV]: true,
      [SurveySourceType.SATELLITE]: true,
    },
    status: 'deprecated',
  },
  variableTotalHerbicide: {
    id: 21,
    name: 'variableTotalHerbicide',
    displayName: 'Non-selective Herbicide (Gridded)',
    inAPI: 'Non-selective Herbicide',
    type: 'application',
    apiEndPoint: (fieldSurveyId: string): string =>
      `/v1.0/field-survey/${fieldSurveyId}/non-selective-herbicide-application/binary`,
    url: 'non-selective-herbicide-application',
    base: 'cropHealth',
    binary: true,
    config: variableTotalHerbicide,
    gridded: true,
    surveySources: { [SurveySourceType.UAV]: true },
    status: 'deprecated',
  },
  variablePreEmergenceHerbicide: {
    id: 22,
    name: 'variablePreEmergenceHerbicide',
    displayName: 'Pre-emergence Herbicide (Gridded)',
    inAPI: 'Variable Pre-emergence Herbicide',
    type: 'application',
    apiEndPoint: (fieldSurveyId: string): string =>
      `/v1.0/field-survey/${fieldSurveyId}/pre-emergence-herbicide-application`,
    url: 'pre-emergence-herbicide-application',
    base: 'cropHealth',
    config: variablePreEmergenceHerbicide,
    gridded: true,
    surveySources: {
      [SurveySourceType.UAV]: true,
    },
    status: 'deprecated',
  },
  variablePostEmergenceHerbicide: {
    id: 23,
    name: 'variablePostEmergenceHerbicide',
    displayName: 'Post-emergence Herbicide (Variable) (Gridded)',
    inAPI: 'Variable Post-emergence Herbicide',
    type: 'application',
    apiEndPoint: (fieldSurveyId: string): string =>
      `/v1.0/field-survey/${fieldSurveyId}/variable-post-emergence-herbicide-application`,
    url: 'variable-post-emergence-herbicide-application',
    base: 'weedDetection',
    config: variablePostEmergenceHerbicide,
    gridded: true,
    surveySources: { [SurveySourceType.UAV]: true },
    status: 'deprecated',
  },
  NDVISeedApplication: {
    id: 25,
    name: 'NDVISeedApplication',
    displayName: 'Seed (Gridded)',
    inAPI: 'Variable Seed Rate Application from Normalised NDVI',
    type: 'application',
    apiEndPoint: (fieldSurveyId: string): string =>
      `/v1.0/field-survey/${fieldSurveyId}/variable-seed-rate-application-from-normalised-ndvi`,
    url: 'variable-seed-rate-application-from-normalised-ndvi',
    base: 'cropHealth',
    config: ndviSeedApplication,
    gridded: true,
    surveySources: {
      [SurveySourceType.UAV]: true,
      [SurveySourceType.SATELLITE]: true,
    },
  },
};

export default griddedApplicationContourConfig;
