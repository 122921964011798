import React from 'react';
import { LoadingSpinner } from '@hummingbirdtechgroup/wings-ui';
import { makeStyles } from '../../styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
}));

function SuspenseFallback(): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span>
        <LoadingSpinner size="large" />
      </span>
    </div>
  );
}

export default SuspenseFallback;
