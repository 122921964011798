import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { AnalysisConfig } from '../types/Analyses';

const lodgingRisk: AnalysisConfig = {
  id: 12,
  name: 'lodgingRisk',
  displayName: 'Lodging Risk',
  inAPI: 'Lodging Risk',
  type: 'analysis',
  clouds: true,
  url: 'analysis-lodging_risk',
  config: analysisBuilder()
    .addUnits('unit.none')
    .addBarStep(1)
    .addDefaultRange({
      lower: 0,
      upper: 3,
    })
    .addColourList(['#FFFFFF', '#F4A8A8', '#ff0000', '#800000'])
    .disableTooltips()
    .build(),
  status: 'deprecated',
};

export default lodgingRisk;
