import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { SurveySourceType } from 'types/surveySourceType';
import { ApplicationConfig } from './ApplicationConfig';

const preEmHerbicideApplication: ApplicationConfig = {
  id: 34,
  name: 'preEmHerbicideApplication',
  displayName: 'Pre-emergence Herbicide (Contoured)',
  inAPI: 'Pre-emergence Herbicide Application / Gridded NDVI Max',
  type: 'application',
  apiEndPoint: fieldSurveyId =>
    `/v1.0/hb-application/field-survey/${fieldSurveyId}/application-pre_emergence_herbicide/analysis-gridded_ndvi_max`,
  url: 'application-pre_emergence_herbicide/analysis-gridded_ndvi_max',
  base: 'ndviMax',
  surveySources: {
    [SurveySourceType.UAV]: true,
  },
  config: analysisBuilder()
    .addUnits('unit.litresPerhectare')
    .addBarStep(1)
    .addDefaultRange({
      lower: 0,
      upper: 120,
    })
    .addColourList(['#fafcd9', '#ebe3a8', '#e0c777', '#d9aa48', '#d48b15'])
    .build(),
};

export default preEmHerbicideApplication;
