import { IStringifyOptions, stringify } from 'qs';

const INDICES: IStringifyOptions = {
  arrayFormat: 'indices',
  skipNulls: true,
  encode: false,
};

export const serializeParams = (params: Record<string, any>) =>
  stringify(params, INDICES);
