import React from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function ChevronDoubleRightIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon viewBox="0 0 20.6 24" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        d="M10.2,23.54,21.7,12,10.2.54"
        transform="translate(-1.8 -0.04)"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        d="M2.3,23.54,13.8,12,2.3.54"
        transform="translate(-1.8 -0.04)"
      />
    </SvgIcon>
  );
}

export default ChevronDoubleRightIcon;
