import { Action } from 'redux';

const DEFAULT = 'en-UK';

interface TAction extends Action<'SELECT_LANGUAGE'> {
  language: string;
}

function languageReducer(state = DEFAULT, action: TAction) {
  switch (action.type) {
    case 'SELECT_LANGUAGE':
      return action.language;
    default:
      return state;
  }
}

export default languageReducer;
