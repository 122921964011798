import React, { ReactElement } from 'react';
import { AxisBottom } from '@visx/axis';
import { scaleLinear } from '@visx/scale';
import { round } from 'lodash-es';
import { makeStyles } from 'libraries/wings-ui/styles';
import { Box, IconButton } from '@hummingbirdtechgroup/wings-ui';
import RangeSlider from '../RangeSlider';
import { P } from '../ContentTags';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '../../icons';

const useStyles = makeStyles(({ spacing, palette }: any) => ({
  gradScaleRoot: {
    margin: spacing(1),
  },
  axisRoot: {
    overflow: 'visible',
    height: spacing(2),
    width: '100%',
  },
  control: {
    margin: spacing(1, 'auto'),
  },
  thresholdValue: {
    color: palette.secondary.main,
    padding: spacing(0.25),
    margin: spacing(0.25),
    minWidth: spacing(5.5),
    textAlign: 'center',
  },
}));

const TINY_FACTOR = 1;
const INCREMENT_FACTOR = 10;

type ThresholdType = 'min' | 'max';

type Props = {
  domain: [number, number];
  onChange: (values: readonly number[]) => void;
  rangeValues: number[];
  /** Size on an increment */
  step: number;
  /** Number of significant digits to display the range values at */
  precision?: number;
};
function GraduatedRangeSlider({
  domain,
  onChange,
  rangeValues,
  step,
  precision = 3,
}: Props): ReactElement {
  const classes = useStyles();

  const gradScale = React.useMemo(
    () =>
      scaleLinear<number | any>({
        range: [0, '100%'],
        clamp: true,
        domain,
      }),
    [domain],
  );

  const min = rangeValues[0];
  const max = rangeValues[0] ?? domain[0];

  const handleDecrement = (
    currentThreshold: number,
    type: ThresholdType,
    factor: 1 | 10,
  ) => {
    const decrementor = step * factor;
    let newValue = currentThreshold - decrementor;
    if (newValue < domain[0]) [newValue] = domain;

    const values = type === 'min' ? [newValue, max] : [min, newValue];
    onChange(values);
  };

  const handleIncrement = (
    currentThreshold: number,
    type: ThresholdType,
    factor: 1 | 10,
  ) => {
    const incrementor = step * factor;
    let newValue = currentThreshold + incrementor;
    if (newValue > domain[1]) [, newValue] = domain;
    const values = type === 'min' ? [newValue, max] : [min, newValue];
    onChange(values);
  };

  return (
    <div className={classes.gradScaleRoot}>
      <RangeSlider
        domain={domain}
        onChange={onChange}
        rangeValues={rangeValues}
        step={step}
      />
      <svg className={classes.axisRoot}>
        <AxisBottom
          top={0}
          left={0}
          hideAxisLine
          scale={gradScale}
          tickLength={4}
        />
      </svg>
      {/* Add fine grained buttons, then make it work for two paddles */}
      <Box width="100%" justifyContent="space-between">
        {rangeValues.map((threshold, i) => {
          const thresholdType = i === 0 ? 'min' : 'max';
          return (
            <Box
              key={thresholdType}
              width="100%"
              justifyContent="space-around"
              className={classes.control}
            >
              <IconButton
                onClick={() =>
                  handleDecrement(threshold, thresholdType, INCREMENT_FACTOR)
                }
                icon={<ChevronDoubleLeftIcon size="xs" />}
              />
              <IconButton
                onClick={() =>
                  handleDecrement(threshold, thresholdType, TINY_FACTOR)
                }
                icon={<ChevronLeftIcon size="xs" />}
              />
              <P
                className={classes.thresholdValue}
                variant="body2"
                theme="bold"
              >
                {round(threshold, precision)}
              </P>
              <IconButton
                onClick={() =>
                  handleIncrement(threshold, thresholdType, TINY_FACTOR)
                }
                icon={<ChevronRightIcon size="xs" />}
              />
              <IconButton
                onClick={() =>
                  handleIncrement(threshold, thresholdType, INCREMENT_FACTOR)
                }
                icon={<ChevronDoubleRightIcon size="xs" />}
              />
            </Box>
          );
        })}
      </Box>
    </div>
  );
}

export default GraduatedRangeSlider;
