import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Logo } from 'libraries/wings-ui/components';
import { Typography, Button } from '@hummingbirdtechgroup/wings-ui';
import { withStyles } from 'libraries/wings-ui/styles';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getUser } from '@hummingbirdtechgroup/wings-auth';

const styles = ({ palette }) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    background: palette.background.highlight,
    '&:h1': {
      fontSize: 24,
    },
    '&:input': {
      maxWidth: '100%',
    },
  },
  islandContainer: {
    padding: 10,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  island: {
    position: 'relative',
    padding: '25px 45px',
    maxWidth: '600px',
    flexShrink: 1,
    width: '100%',
    background: 'white',
    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
    borderRadius: 5,
    textAlign: 'center',
    '@media screen and (max-width: 500px)': {
      padding: 25,
    },
  },
  logo: {
    position: 'absolute',
    left: '-55px',
    top: '-40px',
    width: 130,
    '&:@media screen and (max-width: 550px)': {
      display: 'block',
      position: 'static',
      margin: '0 auto',
      width: 130,
    },
  },
  subHeader: {
    marginTop: 16,
  },
  buttonPad: {
    padding: '8px 24px',
    marginTop: 32,
    marginBottom: 32,
  },
});

function getCompanyFromEmail(email) {
  if (!email) return null;
  const domains = email.split('@');
  const company = domains[1].split('.')[0];

  return company;
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { reloading: false };
    this.user = getUser();
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { level, language } = this.props;
    const user = this.user;
    const company = getCompanyFromEmail(user?.email);

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      scope.setUser({ email: user?.email, username: user?.name, id: user?.id });
      scope.setTag('user_role', user?.role);
      scope.setTag('page_locale', language);
      scope.setTag('unit_system', user?.unit_system);
      scope.setExtra('user_company', company);
      scope.setTag('error_boundary_level', level);
      scope.setTransactionName(level);
      scope.setLevel(Sentry.Severity.Critical);
      scope.addBreadcrumb({
        category: 'componentStack',
        level: Sentry.Severity.Info,
        data: errorInfo.componentStack,
      });
      const eventId = Sentry.captureException(error);
      this.setState({
        eventId,
        company,
        username: user?.name,
        email: user?.email,
      });
    });

    console.error(
      `ErrorBoundary: [${level}], Error:${error}, componentStack: ${errorInfo.componentStack}`,
    );
  }

  handleRetry = () => {
    this.setState({ reloading: true });
    window.location.reload();
  };

  handleGiveFeeback = (username, email, eventId) => () => {
    Sentry.showReportDialog({
      eventId,
      user: { name: username, email },
      title: 'Thanks for helping us out',
      subtitle:
        'Please provide further details to help us improve our platform',
      subtitle2: '',
      labelComments: 'What were you trying to do when the error happened?',
    });
  };

  render() {
    const {
      hasError,
      reloading,
      eventId,
      company,
      username,
      email,
    } = this.state;
    const { children, classes } = this.props;
    if (hasError) {
      return (
        <div className={classes.wrapper}>
          <div className={classes.islandContainer}>
            <div className={classes.island}>
              <Logo className={classes.logo} />
              <Typography variant="title3">
                <FormattedMessage id="errorboundary.oops" />
              </Typography>
              <Typography variant="title3">
                <FormattedMessage id="errorboundary.somethingWrong" />
              </Typography>
              <Typography className={classes.subHeader} variant="title2">
                <FormattedMessage id="errorboundary.tryAgain" />
              </Typography>
              <Button
                className={classes.buttonPad}
                loading={reloading}
                onClick={this.handleRetry}
              >
                <Typography>
                  <FormattedMessage id="errorboundary.reload" />
                </Typography>
              </Button>
              {company !== 'hummingbirdtech' ? (
                <Typography>
                  <FormattedMessage id="errorboundary.errorPersists" />
                </Typography>
              ) : (
                <>
                  <Typography>
                    <FormattedMessage
                      id="errorboundary.provideHelp"
                      values={{ username }}
                    />
                  </Typography>
                  <Button
                    className={classes.buttonPad}
                    onClick={this.handleGiveFeeback(username, email, eventId)}
                  >
                    <Typography>
                      <FormattedMessage id="errorboundary.giveFeedback" />
                    </Typography>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object,
  level: PropTypes.string.isRequired,
  language: PropTypes.string,
};

const mapStateToProps = state => ({
  language: state.language,
});

export default connect(mapStateToProps)(withStyles(styles)(ErrorBoundary));
