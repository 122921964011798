/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/**
 * This code is being borrowed from the excellent Material-ui library.
 * We want to use the `createMuiTheme` function without any of the core library overhead.
 * Original code can be found here: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createPalette.js
 */

import { deepmerge } from '../../utilities';

import { darken, getContrastRatio, lighten } from './colorManipulator';

export const light = {
  // The colors used to style the text.
  text: {
    // The most important text.
    primary: 'rgba(0, 0, 0, 0.87)',
    // Secondary text.
    secondary: 'rgba(0, 0, 0, 0.54)',
    // Disabled text have even lower visual prominence.
    disabled: 'rgba(0, 0, 0, 0.38)',
    // Text hints.
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  // The color used to divide different elements.
  divider: 'rgba(0, 0, 0, 0.12)',
  // The background colors used to style the surfaces.
  // Consistency between these values is important.
  background: {
    default: '#fff',
    highlight: '#f2fcfd',
    map: '#688968',
  },
  // The colors used to style the action elements.
  action: {
    // The color of an active action like an icon button.
    active: 'rgba(0, 0, 0, 0.54)',
    // The color of an hovered action.
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    // The color of a selected action.
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    // The color of a disabled action.
    disabled: 'rgba(0, 0, 0, 0.26)',
    // The background color of a disabled action.
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
};

export const dark = {
  text: {
    primary: '#fff',
    secondary: 'rgba(255, 255, 255, 0.7)',
    disabled: 'rgba(255, 255, 255, 0.5)',
    hint: 'rgba(255, 255, 255, 0.5)',
    icon: 'rgba(255, 255, 255, 0.5)',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#fff',
    default: '#303030',
  },
  action: {
    active: '#fff',
    selected: '#aaeeff',
    disabled: '#2093ce40',
    hover: 'rgba(255, 255, 255, 0.08)',
    hoverOpacity: 0.08,
    selectedOpacity: 0.16,
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(255, 255, 255, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.24,
  },
};

function addLightOrDark(intent, direction, shade, tonalOffset) {
  const tonalOffsetLight = tonalOffset.light || tonalOffset;
  const tonalOffsetDark = tonalOffset.dark || tonalOffset * 1.5;

  if (!intent[direction]) {
    if (intent.hasOwnProperty(shade)) {
      intent[direction] = intent[shade];
    } else if (direction === 'light') {
      intent.light = lighten(intent.main, tonalOffsetLight);
    } else if (direction === 'dark') {
      intent.dark = darken(intent.main, tonalOffsetDark);
    }
  }
}

export default function createPalette(palette) {
  const {
    // Primary color has not been decided yet
    primary = {
      light: '#66c3ff',
      main: '#2093ce',
      dark: '#00659d',
    },
    secondary = {
      light: '#66c3ff',
      main: '#2093ce',
      dark: '#00659d',
    },
    error = {
      light: '#e2687f',
      main: '#db435f',
      dark: '#992e42',
    },
    warning = {
      light: '#efb077',
      main: '#ec9d56',
      dark: '#a56d3c',
    },
    info = {
      light: '#dfedf0',
      main: '#d8e9ed',
      dark: '#97a3a5',
    },
    success = {
      light: '#33d5d7',
      main: '#00cbcd',
      dark: '#008e8f',
    },
    type = 'light',
    contrastThreshold = 3,
    tonalOffset = 0.2,
    ...other
  } = palette;

  // Use the same logic as
  // Bootstrap: https://github.com/twbs/bootstrap/blob/1d6e3710dd447de1a200f29e8fa521f8a0908f70/scss/_functions.scss#L59
  // and material-components-web https://github.com/material-components/material-components-web/blob/ac46b8863c4dab9fc22c4c662dc6bd1b65dd652f/packages/mdc-theme/_functions.scss#L54
  function getContrastText(background) {
    const contrastText =
      getContrastRatio(background, dark.text.primary) >= contrastThreshold
        ? dark.text.primary
        : light.text.primary;

    if (process.env.NODE_ENV !== 'production') {
      const contrast = getContrastRatio(background, contrastText);
      if (contrast < 3) {
        console.error(
          [
            `Material-UI: The contrast ratio of ${contrast}:1 for ${contrastText} on ${background}`,
            'falls below the WCAG recommended absolute minimum contrast ratio of 3:1.',
            'https://www.w3.org/TR/2008/REC-WCAG20-20081211/#visual-audio-contrast-contrast',
          ].join('\n'),
        );
      }
    }

    return contrastText;
  }

  const augmentColor = (
    color,
    mainShade = 500,
    lightShade = 300,
    darkShade = 700,
  ) => {
    color = { ...color };
    if (!color.main && color[mainShade]) {
      color.main = color[mainShade];
    }

    if (!color.main) {
      throw new Error(
        'Material-UI: The color provided to augmentColor(color) is invalid.\n' +
          'The color object needs to have a `main` property or a `%s` property.',
        mainShade,
      );
    }

    if (typeof color.main !== 'string') {
      throw new Error(
        'Material-UI: The color provided to augmentColor(color) is invalid.\n' +
          '`color.main` should be a string, but `%s` was provided instead.\n\n' +
          'Did you intend to use one of the following approaches?\n\n' +
          'import { green } from "@material-ui/core/colors";\n\n' +
          'const theme1 = createMuiTheme({ palette: {\n' +
          '  primary: green,\n' +
          '} });\n\n' +
          'const theme2 = createMuiTheme({ palette: {\n' +
          '  primary: { main: green[500] },\n' +
          '} });',
        JSON.stringify(color.main),
      );
    }

    addLightOrDark(color, 'light', lightShade, tonalOffset);
    addLightOrDark(color, 'dark', darkShade, tonalOffset);
    if (!color.contrastText) {
      color.contrastText = getContrastText(color.main);
    }

    return color;
  };

  const types = { dark, light };

  if (process.env.NODE_ENV !== 'production') {
    if (!types[type]) {
      console.error(
        `Material-UI: The palette type \`${type}\` is not supported.`,
      );
    }
  }

  const paletteOutput = deepmerge(
    {
      // The palette type, can be light or dark.
      type,
      // The colors used to represent primary interface elements for a user.
      primary: augmentColor(primary),
      // The colors used to represent secondary interface elements for a user.
      secondary: augmentColor(secondary),
      // The colors used to represent interface elements that the user should be made aware of.
      error: augmentColor(error),
      // The colors used to represent potentially dangerous actions or important messages.
      warning: augmentColor(warning),
      // The colors used to present information to the user that is neutral and not necessarily important.
      info: augmentColor(info),
      // The colors used to indicate the successful completion of an action that user triggered.
      success: augmentColor(success),
      // The grey colors.
      grey: {
        main: '#bbcbce',
        tint: '#7a8587',
        light: '#efefef',
        lighter: '#edfcff',
        lightest: '#f7feff',
        cloudMask: '#767d7f',
        offBlack: '#3d4344',
        transparentBlack: '#00000030',
        black: '#000',
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold,
      // Takes a background color and returns the text color that maximizes the contrast.
      getContrastText,
      // Generate a rich color object.
      augmentColor,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset,
      // The light and dark type object.
      ...types[type],
    },
    other,
  );

  return paletteOutput;
}
