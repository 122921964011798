import { makeStyles } from '../../styles';

export const useModalStyles = makeStyles(
  ({ palette, spacing, shadows, zIndex }: any) => ({
    backdrop: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: palette.grey.transparentBlack,
      zIndex: zIndex.leafletMax + 100,
    },
    modal: {
      position: 'fixed',
      background: palette.background.default,
      boxShadow: shadows[1],
      minWidth: spacing(62),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: spacing(0.5),

      '&:@media screen and (max-width: 500px)': {
        minWidth: 'initial',
        width: '90%',
      },
    },

    small: {
      minWidth: 496,
    },

    large: {
      minWidth: '45%',
      '&:@media screen and (max-width: 1200px)': {
        minWidth: '65%',
      },
    },

    content: {
      padding: spacing(2, 2),
    },

    logo: {
      position: 'absolute',
      left: '-55px',
      top: '-40px',
      width: 130,

      '&:@media screen and (max-width: 500px)': {
        left: '-32px',
        top: '-32px',
        width: 88,
      },
    },

    close: {
      color: palette.secondary.main,
      paddingRight: spacing(),
      position: 'absolute',
      top: 0,
      right: 0,
    },
  }),
);
