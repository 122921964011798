import React from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function HelpIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 22.3 22.3">
      <path
        d="M11.324.32a11,11,0,1,0,11,11,11,11,0,0,0-11-11Zm0,20.541a9.537,9.537,0,1,1,9.537-9.537,9.537,9.537,0,0,1-9.537,9.537Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <path
        d="M7.9,2.656A3.485,3.485,0,0,0,3.6,6.045H5.067A2.017,2.017,0,1,1,7.818,7.916a2.465,2.465,0,0,0-1.467,2.3V12.1H7.818V10.212a.983.983,0,0,1,.558-.932,3.485,3.485,0,0,0,2.1-4.05A3.419,3.419,0,0,0,7.9,2.656Z"
        transform="translate(4.24 2.895)"
        fill="currentColor"
      />
      <rect
        width="1.467"
        height="1.467"
        transform="translate(10.591 15.726)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default HelpIcon;
