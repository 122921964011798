export function requestApplicationAction(
  applicationKey,
  fieldSurveyId,
  unitSystem,
  farmId,
) {
  return {
    type: 'REQUEST_APPLICATION',
    applicationKey,
    fieldSurveyId,
    unitSystem,
    farmId,
  };
}

export function requestApplicationErrorAction(
  applicationKey,
  fieldSurveyId,
  errorCode,
) {
  return {
    type: 'REQUEST_APPLICATION_ERROR',
    applicationKey,
    fieldSurveyId,
    errorCode,
  };
}

export function receiveApplicationAction(
  applicationKey,
  fieldSurveyId,
  application,
) {
  return {
    type: 'RECEIVE_APPLICATION',
    applicationKey,
    fieldSurveyId,
    application,
  };
}

export function saveApplicationAction(
  applicationKey,
  fieldSurveyId,
  application,
) {
  return {
    type: 'SAVE_APPLICATION',
    applicationKey,
    fieldSurveyId,
    application,
  };
}

export function updateApplicationAction(
  applicationKey,
  fieldSurveyId,
  application,
) {
  return {
    type: 'UPDATE_APPLICATION',
    applicationKey,
    fieldSurveyId,
    application,
  };
}

export function saveApplicationEditorStateAction(
  applicationKey,
  fieldSurveyId,
  state,
) {
  return {
    type: 'SAVE_APPLICATION_EDITOR_STATE',
    applicationKey,
    fieldSurveyId,
    state,
  };
}

export function setApplicationActiveLevel(id) {
  return {
    type: 'SELECT_APPLICATION_LEVEL',
    id,
  };
}

export function selectApplicationAction(application) {
  return {
    type: 'SELECT_APPLICATION',
    application,
  };
}

export default saveApplicationAction;
