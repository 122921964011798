import React from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function CropIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path
          d="M281.8,357.6c-.4-.1-.6-.2-.7-.5a2.8,2.8,0,0,1-.1-1.9,5.1,5.1,0,0,1,1.8-3,5.5,5.5,0,0,1,.8,3.4,3,3,0,0,1-.8,1.8A1.6,1.6,0,0,1,281.8,357.6Z"
          transform="translate(-266.8 -349.1)"
          fill="none"
        />
        <path
          d="M282.6,353.1a4.6,4.6,0,0,0-1.1,2.2c-.2,1-.1,1.5,0,1.6l.4.2h.6a3.8,3.8,0,0,0,.6-1.5,4.5,4.5,0,0,0-.5-2.4m.3-1.6a6.5,6.5,0,0,1,1.2,4.2c-.4,2.2-1.5,2.5-2.4,2.4s-1.6-.8-1.2-3A5.9,5.9,0,0,1,282.9,351.5Z"
          transform="translate(-266.8 -349.1)"
          fill="currentColor"
        />
      </g>
      <line
        x1="4.1"
        y1="20.2"
        x2="0.5"
        y2="23.6"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <g>
        <path
          d="M278.3,361a1.1,1.1,0,0,1-.8-.5,3.6,3.6,0,0,1-.1-1.9,6.4,6.4,0,0,1,1.8-3,4.9,4.9,0,0,1,.8,3.4,3,3,0,0,1-.7,1.8A1.6,1.6,0,0,1,278.3,361Z"
          transform="translate(-266.8 -349.1)"
          fill="none"
        />
        <path
          d="M279.1,356.5a4.7,4.7,0,0,0-1.2,2.2,2.8,2.8,0,0,0,.1,1.6l.3.2h.7a2.9,2.9,0,0,0,.5-1.5,3.7,3.7,0,0,0-.4-2.4m.3-1.6a6.1,6.1,0,0,1,1.1,4.2c-.3,2.2-1.4,2.5-2.3,2.4s-1.6-.8-1.2-3A5.9,5.9,0,0,1,279.4,354.9Z"
          transform="translate(-266.8 -349.1)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M274.7,364.4a1,1,0,0,1-.8-.4,3.6,3.6,0,0,1-.1-1.9,5.8,5.8,0,0,1,1.9-3,5.6,5.6,0,0,1,.7,3.4,3,3,0,0,1-.7,1.7A1.2,1.2,0,0,1,274.7,364.4Z"
          transform="translate(-266.8 -349.1)"
          fill="none"
        />
        <path
          d="M275.5,359.9a5,5,0,0,0-1.2,2.3,3,3,0,0,0,.1,1.6h.4a.6.6,0,0,0,.6-.1,2.5,2.5,0,0,0,.6-1.4,5.1,5.1,0,0,0-.5-2.5m.3-1.6a6.4,6.4,0,0,1,1.1,4.3c-.3,2.2-1.4,2.5-2.3,2.3s-1.6-.7-1.2-2.9A6.3,6.3,0,0,1,275.8,358.3Z"
          transform="translate(-266.8 -349.1)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M271.1,367.9a.7.7,0,0,1-.7-.4,2.4,2.4,0,0,1-.1-1.9,5.1,5.1,0,0,1,1.8-3,5.4,5.4,0,0,1,.7,3.4,2.5,2.5,0,0,1-.7,1.7A1.2,1.2,0,0,1,271.1,367.9Z"
          transform="translate(-266.8 -349.1)"
          fill="none"
        />
        <path
          d="M271.9,363.4a4.7,4.7,0,0,0-1.2,2.2c-.1,1.1,0,1.5.1,1.6s.1.2.4.2a.6.6,0,0,0,.6-.1,3,3,0,0,0,.6-1.4,5.1,5.1,0,0,0-.5-2.5m.3-1.6a6.3,6.3,0,0,1,1.1,4.2c-.3,2.2-1.4,2.5-2.3,2.4s-1.6-.7-1.2-2.9A6.3,6.3,0,0,1,272.2,361.8Z"
          transform="translate(-266.8 -349.1)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M288.4,358a5.5,5.5,0,0,1-3,1.8,3.8,3.8,0,0,1-2-.2,1.5,1.5,0,0,1-.4-.8,1.2,1.2,0,0,1,.3-.9,2.5,2.5,0,0,1,1.7-.8A6,6,0,0,1,288.4,358Z"
          transform="translate(-266.8 -349.1)"
          fill="none"
        />
        <path
          d="M287.6,358.2a4.2,4.2,0,0,0-2.5-.6c-1,.2-1.3.4-1.4.6s-.2.2-.2.6l.2.4a4.2,4.2,0,0,0,1.6.1,7,7,0,0,0,2.3-1.1m1.6-.2a6.3,6.3,0,0,1-3.8,2.2c-2.2.3-2.8-.4-2.9-1.3s.3-2,2.5-2.3A6.7,6.7,0,0,1,289.2,358Z"
          transform="translate(-266.8 -349.1)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M285.1,355.4h0c-.3-.3-.4-.5-.3-.8s.4-.9,1-1.6a6.3,6.3,0,0,1,3.3-1.3,5.3,5.3,0,0,1-1.4,3.2,2.8,2.8,0,0,1-1.7.9A1.2,1.2,0,0,1,285.1,355.4Z"
          transform="translate(-266.8 -349.1)"
          fill="none"
        />
        <path
          d="M288.5,352.3a4.9,4.9,0,0,0-2.3,1.1c-.8.7-.9,1.1-.9,1.3s-.1.2.1.4a1,1,0,0,0,.6.2c.2,0,.6-.1,1.3-.7a5.1,5.1,0,0,0,1.2-2.3m1.1-1.1a6.4,6.4,0,0,1-1.6,4.1c-1.6,1.5-2.6,1.1-3.3.5s-.8-1.6.8-3.1A6.1,6.1,0,0,1,289.6,351.2Z"
          transform="translate(-266.8 -349.1)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M284.9,361.5a6.5,6.5,0,0,1-3.1,1.7,3.4,3.4,0,0,1-1.9-.2,1.5,1.5,0,0,1-.4-.8c0-.4,0-.7.3-.9a2.5,2.5,0,0,1,1.7-.8A5.5,5.5,0,0,1,284.9,361.5Z"
          transform="translate(-266.8 -349.1)"
          fill="none"
        />
        <path
          d="M284,361.6a3.8,3.8,0,0,0-2.4-.6,2.7,2.7,0,0,0-1.5.6,2.2,2.2,0,0,0-.1.6c0,.3.1.3.2.4a4.2,4.2,0,0,0,1.6.1,5.7,5.7,0,0,0,2.2-1.1m1.6-.2a5.9,5.9,0,0,1-3.7,2.2c-2.2.3-2.8-.4-2.9-1.3s.3-2,2.5-2.3A6.6,6.6,0,0,1,285.6,361.4Z"
          transform="translate(-266.8 -349.1)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M281.3,364.9a5.2,5.2,0,0,1-3.1,1.7,3.6,3.6,0,0,1-1.9-.1,1.5,1.5,0,0,1-.4-.8,1.2,1.2,0,0,1,.3-1,3,3,0,0,1,1.7-.7A5.5,5.5,0,0,1,281.3,364.9Z"
          transform="translate(-266.8 -349.1)"
          fill="none"
        />
        <path
          d="M280.4,365a4.5,4.5,0,0,0-2.4-.5,2.1,2.1,0,0,0-1.5.6,1.5,1.5,0,0,0-.1.6c0,.2.1.3.2.3s.5.3,1.6.1a3.8,3.8,0,0,0,2.2-1.1m1.6-.2a5.9,5.9,0,0,1-3.7,2.3c-2.2.3-2.8-.4-2.9-1.3s.3-2,2.5-2.3A6.2,6.2,0,0,1,282,364.8Z"
          transform="translate(-266.8 -349.1)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M277.7,368.4a5.4,5.4,0,0,1-3.1,1.7,2.7,2.7,0,0,1-1.9-.2c-.2-.1-.3-.3-.4-.7a1.2,1.2,0,0,1,.3-1,2.5,2.5,0,0,1,1.7-.7A5.5,5.5,0,0,1,277.7,368.4Z"
          transform="translate(-266.8 -349.1)"
          fill="none"
        />
        <path
          d="M276.9,368.5a5.1,5.1,0,0,0-2.5-.5,2.9,2.9,0,0,0-1.5.5,3.5,3.5,0,0,0-.1.6c0,.3.1.4.2.4s.5.2,1.6.1a4.5,4.5,0,0,0,2.3-1.1m1.6-.2a6.8,6.8,0,0,1-3.8,2.3c-2.2.3-2.8-.4-2.9-1.3s.3-2,2.5-2.3A6.3,6.3,0,0,1,278.5,368.3Z"
          transform="translate(-266.8 -349.1)"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default CropIcon;
