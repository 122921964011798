import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function AddUserIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <line
        x1="17.9"
        y1="22.9"
        x2="17.9"
        y2="14.2"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <line
        x1="22.2"
        y1="18.5"
        x2="13.5"
        y2="18.5"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <g>
        <path
          d="M289.7,421.1h5a2.9,2.9,0,0,1,3,3v5h-11v-5A2.9,2.9,0,0,1,289.7,421.1Z"
          transform="translate(-284.2 -413.6)"
          fill="#fff"
        />
        <path
          d="M289.7,421.6h5a2.5,2.5,0,0,1,2.5,2.5v4a.5.5,0,0,1-.5.5h-9a.5.5,0,0,1-.5-.5v-4A2.5,2.5,0,0,1,289.7,421.6Z"
          transform="translate(-284.2 -413.6)"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <g>
        <circle cx="7.9" cy="4" r="3" fill="#fff" />
        <circle cx="7.9" cy="4" r="2.5" fill="none" stroke="currentColor" />
      </g>
    </SvgIcon>
  );
}

export default AddUserIcon;
