import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function FolderReadonlyEmptyIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="-10 -4 70 70">
      <mask id="m4">
        <rect x="0" y="0" width="100" height="100" fill="white" />
        <path
          fill="black"
          d="M24.86 42.19A24.87 24.87 0 0117.4 41a33.76 33.76 0 01-6.16-2.65 37.22 37.22 0 01-4.8-3.15 36.18 36.18 0 014.8-3.2 33.82 33.82 0 016.16-2.66 25.17 25.17 0 017.46-1.19 25 25 0 017.48 1.2 34.37 34.37 0 016.17 2.67 36.75 36.75 0 014.77 3.13 37.49 37.49 0 01-4.81 3.15 33.76 33.76 0 01-6.16 2.7 24.86 24.86 0 01-7.45 1.19z"
        />
      </mask>
      <mask id="m5">
        <rect x="0" y="0" width="100" height="100" fill="white" />
        <circle cx="22.36" cy="33.69" r="1.5" fill="black" />
      </mask>
      <g fill="currentColor">
        <path d="M45.75 21.22v-3.94h-26.5l-.5-.18-2.39-.88H4.75v5h41m3 3h-47v-11h15.14l2.89 1.06h29z" />
        <path d="M3.19 24.22l1.38 22H46l1.52-22H3.19m-3.19-3h50.69l-1.94 28h-47z" />
        <path d="M47.35 47.72H3.16l-1.56-25h47.48l-1.73 25z" mask="url(#m4)" />
        <path d="M24.86 29.19a23.93 23.93 0 00-7.13 1.14 32.54 32.54 0 00-6 2.56 36.62 36.62 0 00-3.66 2.3c3.06 2.18 9.46 6 16.75 6A24.36 24.36 0 0032 40.05a33.16 33.16 0 005.95-2.56 37.3 37.3 0 003.66-2.3c-3.06-2.19-9.45-6-16.74-6m0-2c11 0 20 8 20 8s-9 8-20 8-20-8-20-8 8.94-8 19.99-8z" />
        <circle cx="24.86" cy="35.19" r="5" mask="url(#m5)" />
      </g>
    </SvgIcon>
  );
}

export default FolderReadonlyEmptyIcon;
