/* eslint-disable camelcase */
import unitConstants, {
  Language,
  UnitSystem,
  UnitId,
  UnitContext,
  EN,
  ConversionTables,
} from '../unit-constants';

const { LANGUAGES, UNIT_SYSTEMS, UNIT_CONTEXT, UNIT_IDS } = unitConstants;

/**
 * Pure function that returns a converted label string.
 * Will default to english of a language does not have a type.
 * Will default to metric if the other system does not have a value at least for english.
 */
function getUnitLabel(
  lang: Language = EN,
  context: UnitContext = 'general',
  unitId: UnitId,
  system: UnitSystem,
  conversionTable: ConversionTables,
): string {
  if (!LANGUAGES.includes(lang))
    throw new Error(
      `Selected language is not supported - [${lang}] - Please select one of the following: [${LANGUAGES.join(
        ', ',
      )}]`,
    );

  if (!UNIT_SYSTEMS.includes(system))
    throw new Error(
      `The selected system is not supported - [${system}] - Please choose one of the following unit systems: [${UNIT_SYSTEMS.join(
        ', ',
      )}]`,
    );

  if (!UNIT_CONTEXT.includes(context))
    throw new Error(
      `The selected context is not supported - [${context}] - Please select on of the following unit contexts: [${UNIT_CONTEXT.join(
        ', ',
      )}]`,
    );

  if (!UNIT_IDS.includes(unitId))
    throw new Error(
      `The selected unit id is not supported - [${unitId}] - Please select one of the following unit ids: [${UNIT_CONTEXT.join(
        ', ',
      )}]`,
    );

  let newLabel = conversionTable[system][lang][unitId][context];

  if (!newLabel) {
    newLabel = conversionTable[system][EN][unitId][context];
  }

  if (!newLabel) {
    newLabel = conversionTable.metric[EN][unitId][context];
  }

  return newLabel;
}

export default getUnitLabel;
