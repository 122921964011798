import {
  requestApplicationSaga,
  saveApplicationSaga,
  updateApplicationSaga,
} from './applicationStateManagement/applicationSagas';

export const applicationSagas = [
  requestApplicationSaga,
  saveApplicationSaga,
  updateApplicationSaga,
];
