import React from 'react';
import type { SliderItem, GetTrackProps } from 'react-compound-slider';
import { makeStyles } from 'libraries/wings-ui/styles';

const useStyles = makeStyles(({ palette, spacing }: any) => ({
  track: {
    position: 'absolute',
    height: 5,
    zIndex: 1,
    background: palette.secondary.main,
    borderRadius: spacing(0.25),
    cursor: 'pointer',
  },
}));

type TrackProps = {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
};

export function Track({
  source,
  target,
  getTrackProps,
}: TrackProps): React.ReactElement {
  const classes = useStyles();
  return (
    <div
      className={classes.track}
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}
