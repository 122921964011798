import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '../../styles';

const useStyles = makeStyles(({ palette, shadows }: any) => ({
  root: {
    boxShadow: shadows[2],
    boxSizing: 'border-box',
    border: `1px solid ${palette.secondary.main}`,
    backgroundColor: palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '0px 0px 3px 3px',
    width: '100%',
  },
  itemWrapper: {
    minHeight: 172,
    maxHeight: ({ maxMenuHeight }: any) => maxMenuHeight,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    '&::-webkit-scrollbar, -moz-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
  },
  menuControl: {
    width: '100%',
    height: 'auto',
    alignSelf: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px 0px 3px 3px',
    borderTop: `1px solid ${palette.grey.light}`,
  },
}));

type Props = {
  children: React.ReactNode;
  menuControl?: React.ReactNode;
  className?: string;
  maxMenuheight: number;
};

function Menu({
  children,
  menuControl,
  className,
  maxMenuheight,
}: Props): React.ReactElement {
  const classes = useStyles({ maxMenuheight });
  return (
    <div data-testid="menu" className={classNames(className, classes.root)}>
      <div className={classes.itemWrapper}>{children}</div>
      {menuControl && (
        <span className={classes.menuControl}>{menuControl}</span>
      )}
    </div>
  );
}

export default Menu;
