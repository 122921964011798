import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function FieldIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path
          d="M307.9,431.8h-21v-7.9c2.9-2.8,18.6-3,21-2.2Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M298.8,421.8c-5.4.1-8.2.9-11.4,2.4v7.1h20v-9.2c-.9-.1-3.7-.3-8.6-.3m0-1a59.6,59.6,0,0,1,9.6.5v11h-22v-8.6S290.7,420.8,298.8,420.8Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <path
        d="M307.2,421.7c-3.5,2.1-4.5,6.4-5.3,10.1"
        transform="translate(-285.4 -409.3)"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M294.1,421.6a17.2,17.2,0,0,0-7.2,7.2"
        transform="translate(-285.4 -409.3)"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M300.4,421.3c-3.5,2.5-5.3,6.5-6,10.5"
        transform="translate(-285.4 -409.3)"
        fill="none"
        stroke="currentColor"
      />
      <g>
        <path
          d="M292.9,412.8c5.1,0,5.1,8.1,0,8S287.7,412.8,292.9,412.8Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M292.9,413.3c4.5,0,4.5,7.1,0,7S288.3,413.3,292.9,413.3Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <line
        x1="11"
        y1="4"
        x2="12"
        y2="3"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="7.5"
        y1="2.5"
        x2="7.5"
        y2="1"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="4"
        y1="4"
        x2="3"
        y2="3"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="12.5"
        y1="7.5"
        x2="14"
        y2="7.5"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="1"
        y1="7.5"
        x2="2.5"
        y2="7.5"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="4"
        y1="11"
        x2="3"
        y2="12"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export default FieldIcon;
