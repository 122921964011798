function selectedFieldSurveyReducer(state = null, action = {}) {
  switch (action.type) {
    case 'FIELD_SURVEY_SELECTED':
      return action.fieldSurveyId || null;
    default:
      return state;
  }
}

export default selectedFieldSurveyReducer;
