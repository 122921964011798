import { round } from 'lodash-es';
import { getUnitLabelLocalStorage } from 'libraries/unitConversion';
import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { AnalysisConfig } from '../types/Analyses';

const STEP = 0.2;
const UNIT = 'unit.GAI';

const gai: AnalysisConfig = {
  id: 3,
  name: 'gai',
  displayName: 'Green Area Index',
  inAPI: 'Green Area Index',
  type: 'analysis',
  clouds: true,
  url: 'analysis-green_area_index',
  config: analysisBuilder()
    .addUnits(UNIT)
    .addBarStep(STEP)
    .addDefaultRange({
      lower: 0,
      upper: 5,
    })
    .addColourList(['#F7FBF4', '#8FEB81', '#22C408', '#1E9E0A', '#00451C'])
    .addTooltipFormatter((item: any, data: any) => [
      `This field contains ${round(item.yLabel, 2)} ${getUnitLabelLocalStorage({
        unitId: 'hectares',
        context: 'name',
      })}`,
      `with a GAI between ${data.datasets[0].data[item.index].x} and ${
        data.datasets[0].data[item.index + 1].x
      }`,
    ])
    .build(),
};

export default gai;
