import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function LightbulbIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <line
        x1="19.5"
        y1="3"
        x2="17.5"
        y2="5"
        fill="none"
        stroke="currentColor"
      />
      <line x1="4.5" y1="3" x2="6.5" y2="5" fill="none" stroke="currentColor" />
      <line x1="12" x2="12" y2="3" fill="none" stroke="currentColor" />
      <g>
        <path
          d="M299.1,427.3h-3.5C281.7,409.4,312.9,409.3,299.1,427.3Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M297.4,414.3c-7.8.9-4.8,8.4-1.5,12.5h2.9c3.3-4.3,6.3-11.5-1.4-12.5m0-1a6,6,0,0,1,6,6c0,3-4,8.5-4,8.5h-4s-4-5.4-4-8.5A6,6,0,0,1,297.4,413.3Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <line
        x1="9.5"
        y1="20.5"
        x2="14.5"
        y2="20.5"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <line
        x1="9.5"
        y1="23"
        x2="14.5"
        y2="23"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <line
        x1="18"
        y1="20.5"
        x2="18"
        y2="20.5"
        fill="none"
        stroke="currentColor"
      />
    </SvgIcon>
  );
}

export default LightbulbIcon;
