import getUnitLabel from './get-unit-label';
import conversionTables from '../conversion-tables';
import { UnitId, UnitContext, Language, UnitSystem } from '../unit-constants';

type Options = {
  context?: UnitContext;
  unitId: UnitId;
};

function getUnitLabelLocalStorage(opts: Options): string | null {
  const unitSystem: {
    language: Language;
    system: UnitSystem;
    // @ts-ignore
  } | null = JSON.parse(localStorage.getItem('unitSystem'));

  if (!unitSystem) {
    console.error(
      'There is no language set, please ensure you use this function within a UnitConversionProvider',
    );
    return null;
  }

  return getUnitLabel(
    unitSystem.language,
    opts.context,
    opts.unitId,
    unitSystem.system,
    conversionTables,
  );
}

export default getUnitLabelLocalStorage;
