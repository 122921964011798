import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function CompareSurveysIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 26 14">
      <g strokeLinecap="round">
        <path
          fill="none"
          stroke="currentColor"
          strokeDasharray="3 4.5"
          d="M.5 6.95h25"
        />
        <g fill="currentColor">
          <path d="M10.033 2.456h1.842V.5h2.75v1.956h1.842l-3.218 1.959-3.216-1.959z" />
          <path d="M14.684 2.956h-.56V1h-1.749v1.956h-.56l1.434.873 1.435-.873m3.566-1L13.25 5l-5-3.044h3.125V0h3.75v1.956h3.125z" />
        </g>
        <g fill="currentColor">
          <path d="M16.467 11.444h-1.842V13.4h-2.75v-1.956h-1.842l3.216-1.959 3.218 1.959z" />
          <path d="M14.684 10.944l-1.435-.873-1.433.873h.56V12.9h1.749v-1.956h.559m3.566 1h-3.125V13.9h-3.75v-1.956H8.25l5-3.044 5 3.044z" />
        </g>
      </g>
    </SvgIcon>
  );
}

export default CompareSurveysIcon;
