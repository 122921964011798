import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function MonitorIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="5 10 168 168">
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeMiterlimit="10"
        d="M145 120v-6H31v6h34.7l19.3 2.4a2 2 0 002 1.6h2a2 2 0 002-1.6l19.4-2.4z"
      />
      <rect
        width="114"
        height="89"
        fill="none"
        stroke="currentColor"
        strokeWidth="4"
        rx="7"
        transform="translate(31 40)"
      />
      <path
        stroke="currentColor"
        strokeWidth="4"
        d="M73.5 128.5l-2 9.5M102.5 128.5l2 9.5"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="4"
        d="M65 138h46a6 6 0 016 6v3H59v-3a6 6 0 016-6z"
      />
    </SvgIcon>
  );
}

export default MonitorIcon;
