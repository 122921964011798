import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';

import { AnalysisConfig } from '../types/Analyses';

const yellowBud: AnalysisConfig = {
  id: 6,
  name: 'yellowBud',
  displayName: 'In Flower',
  inAPI: 'In Flower',
  type: 'analysis',
  clouds: true,
  url: 'analysis-yellow_bud_index',
  config: analysisBuilder()
    .addUnits('unit.percentage')
    .addBarStep(5)
    .addDefaultRange({
      lower: 0,
      upper: 100,
    })
    .addTileFillOpacity(1)
    .addColourList(['#993404', '#d95f0e', '#fe9929', '#fed98e', '#ffffd4'])
    .build(),
  status: 'deprecated',
};

export default yellowBud;
