import React from 'react';
import { styled } from 'libraries/wings-ui/styles';

function filterMenuItemProps(props) {
  const { active, ...rest } = props;
  return rest;
}
const MenuItem = styled(props => (
  <div data-testid="menu.item" {...filterMenuItemProps(props)} />
))(({ active, theme: { palette, spacing } }) => ({
  backgroundColor: active ? palette.secondary.main : palette.background.default,
  color: active ? '#FFFFFF' : palette.text.main,
  width: '100%',
  padding: spacing(0.5, 1),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: !active && `${palette.secondary.main}25`,
  },
  boxSizing: 'border-box',
}));

export default MenuItem;
