import { maxBy, minBy, get, round } from 'lodash-es';

function rangeUtil(dataset) {
  return {
    upper: round(get(maxBy(dataset, 'value'), 'value', 0) + 0.5),
    lower: round(get(minBy(dataset, 'value'), 'value', 0) - 0.5),
  };
}

export default rangeUtil;
