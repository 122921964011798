import React, { ReactElement, useEffect, useState } from 'react';
import { makeStyles } from 'libraries/wings-ui/styles';

const useStyles = makeStyles(({ spacing, palette }: any) => ({
  banner: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: spacing(4),
    width: '100%',
    backgroundColor: `${palette.warning.main}70`,
    fontSize: 16,
    color: 'black',
    zIndex: 1000000000,
    pointerEvents: 'none',
  },
}));

type Environment = 'integration' | 'staging' | 'development';

const DEVELOPMENT = 'development';
const STAGING = '.staging.';
const INTEGRATION = '.int.';

function EnvironmentBanner(): ReactElement | null {
  const classes = useStyles();
  const [deploymentEnv, setDeploymentEnv] = useState<Environment | null>(null);

  useEffect(() => {
    if (window.location.host.includes(INTEGRATION)) {
      setDeploymentEnv('integration');
    } else if (window.location.host.includes(STAGING)) {
      setDeploymentEnv('staging');
    } else if (process.env.NODE_ENV === DEVELOPMENT) {
      setDeploymentEnv('development');
    }
  }, []);

  if (!deploymentEnv) return null;
  return (
    <div className={classes.banner}>
      <span>
        WARNING: You are on the <b>{deploymentEnv.toUpperCase()}</b> deployment
        using the <b>{process.env.API_ENV?.toUpperCase()}</b> API/Database
      </span>
    </div>
  );
}

export default EnvironmentBanner;
