import React, { ReactElement, forwardRef } from 'react';
import Select, { createFilter } from 'react-select';
import { TwoColumnOption, TwoColumnValueContainer } from './TwoColumnList';
import { useCustomStyles } from './dropDown.styles';

export type Option = {
  value: unknown;
  label: unknown;
};

export type DropDownOption = Option | undefined | null;

export type Direction = 'auto' | 'bottom' | 'top';

export interface DropDownProps {
  allowAll?: boolean;
  allOption?: DropDownOption;
  /**
   * When true a greyed out option using the placeholder will appear as the first option
   * Clicking it will set the selected option back to undefined to deselect the options
   */
  deselectable?: boolean;
  /** Styles applied to the DropDown container */
  className?: string;
  disabled?: boolean;
  /** Turns the DropDown into a multi select */
  isMulti?: boolean;
  /** Removes the stand 8px margin when true */
  loading?: boolean;
  menuType?: 'standard' | 'twoColumnMenu';
  noMargin?: boolean;
  onChange: (value: DropDownOption) => void;
  onMenuClose?: () => void;
  options: DropDownOption[];
  placeholder?: string;
  rest?: any;
  value?: DropDownOption | unknown;
  menuPlacement?: Direction;
  defaultMenuIsOpen?: boolean;
  menuIsOpen?: boolean;
}

const DropDown = forwardRef(
  (
    {
      allowAll,
      allOption,
      deselectable,
      menuType,
      loading,
      className,
      disabled,
      value: valueProp = [],
      options: optionsProp = [],
      onChange,
      onMenuClose,
      menuPlacement = 'bottom',
      defaultMenuIsOpen,
      menuIsOpen,
      placeholder,
      ...rest
    }: DropDownProps,
    ref: any,
  ): ReactElement => {
    const customStyles = useCustomStyles();

    const options = [...(optionsProp as any)];
    if (allowAll) options.unshift(allOption);
    if (deselectable)
      options.unshift({
        label: `--- ${placeholder?.toLowerCase()} ---`,
        value: 'deselect',
      });

    let value = valueProp;
    if (allowAll && Array.isArray(valueProp)) {
      value = [allOption];
    }

    const handleChange = (selected: DropDownOption) => {
      if (Array.isArray(selected) && allowAll) {
        const arrayContainsAll = selected.find(
          option => option.value === allOption?.value,
        );
        // @ts-ignore
        if (arrayContainsAll) return onChange([allOption]);
      }
      if (deselectable && selected?.value === 'deselect')
        return onChange(undefined);
      return onChange(selected);
    };

    const customComponents = () => {
      if (menuType === 'twoColumnMenu')
        return {
          Option: TwoColumnOption,
          ValueContainer: TwoColumnValueContainer,
        };

      return undefined;
    };

    return (
      <Select
        ref={ref}
        {...rest}
        placeholder={placeholder}
        onMenuClose={onMenuClose}
        menuPlacement={menuPlacement}
        menuIsOpen={menuIsOpen}
        defaultMenuIsOpen={defaultMenuIsOpen}
        isLoading={loading}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        styles={customStyles}
        className={className}
        classNamePrefix="dropDown"
        onChange={selected => handleChange(selected)}
        options={options}
        value={value}
        components={customComponents() as any}
        /**
         * Reduces lag when searching through a long list of options
         * See: https://github.com/JedWatson/react-select/issues/3128
         */
        filterOption={createFilter({ ignoreAccents: false })}
        data-test="select"
      />
    );
  },
);

export default DropDown;
