import type { AppEnvironment } from '.';

export type Config = {
  apiUrl: string;
  surveyUrl: string;
  gqlGatewayUrl: string;
  /** Bucket for UAV image uploads */
  bucketUrl: string;
  /** Map tiles like NDVI, RGB, VARI or Terrain  */
  analysisTileUrl: string;
  /** Where static images are stored for display purposes */
  applicationUrl: string;
  staticAssetsBucket?: string;
  googleMapsApiKey?: string;
  uploaderUrl?: string;
  downloadApplicationFileReportSheetUrl?: string;
  adminSlackBotUrl: string;
  slackChannelApplicationFileDownload: string;
};

type EnvConfig = Record<AppEnvironment, Config>;

export const defaultConfig = {
  apiUrl: 'http://localhost:8080/api',
  surveyUrl: 'http://localhost:8080/api/survey-service/api',
  gqlGatewayUrl: 'https://farm-api.int.hummingbirdtech.com',
  bucketUrl: 'http://localhost:8080/api/upload?uploadType=media',
  analysisTileUrl: 'http://localhost:8080/tileServer',
  applicationUrl: 'https://applications.hummingbirdtech.com/',
  staticAssetsBucket: 'https://storage.googleapis.com/hb_web_static_assets',
  googleMapsApiKey: 'AIzaSyCIbJEGHxLAP_kSWXQ1jUGknbRmcgSy5j8',
  uploaderUrl: 'https://uploader.staging.hummingbirdtech.com/',
  downloadApplicationFileReportSheetUrl:
    'https://script.google.com/a/hummingbirdtech.com/macros/s/AKfycbzNAzDGfS98zI-gLTxcCTPuVeg6rn2hGdtL2tkM/exec',
  adminSlackBotUrl:
    'https://europe-west1-hummingbirdtech-production.cloudfunctions.net/admin-slack-bot',
  slackChannelApplicationFileDownload: 'C0218BZ5J10',
};

export const envConfigs: EnvConfig = {
  production: {
    apiUrl: 'https://api.hummingbirdtech.com',
    surveyUrl: 'https://api.hummingbirdtech.com/survey-service/api',
    gqlGatewayUrl: 'https://farm-api.hummingbirdtech.com',
    bucketUrl:
      'https://www.googleapis.com/upload/storage/v1/b/hummingbirdtech-production-raw/o?uploadType=resumable',
    analysisTileUrl:
      'https://storage.googleapis.com/hummingbirdtech-production-maps',
    applicationUrl: 'https://applications.hummingbirdtech.com/',
    googleMapsApiKey: 'AIzaSyCODSR5yZwK0U3GZq8siANHhF6cfMAGytE',
    uploaderUrl: 'https://uploader.hummingbirdtech.com/',
    downloadApplicationFileReportSheetUrl:
      'https://script.google.com/macros/s/AKfycbxjq3VIgzC3ZSHLazInjbR5PW9Wn8su75ZblmbMqlUUwNORskFj/exec',
    adminSlackBotUrl:
      'https://europe-west1-hummingbirdtech-production.cloudfunctions.net/admin-slack-bot',
    slackChannelApplicationFileDownload: 'C0218BZ5J10',
  },
  staging: {
    apiUrl: 'https://api.stg.hummingbirdtech.com',
    surveyUrl: 'https://api.stg.hummingbirdtech.com/survey-service/api',
    gqlGatewayUrl: 'https://farm-api.stg.hummingbirdtech.com',
    bucketUrl:
      'https://www.googleapis.com/upload/storage/v1/b/hbt-staging-ortho-raw/o?uploadType=resumable',
    analysisTileUrl: 'https://storage.googleapis.com/hbt-staging-maps',
    applicationUrl: 'https://applications.staging.hummingbirdtech.com/',
    uploaderUrl: 'https://uploader.staging.hummingbirdtech.com/',
    downloadApplicationFileReportSheetUrl:
      'https://script.google.com/a/hummingbirdtech.com/macros/s/AKfycbzNAzDGfS98zI-gLTxcCTPuVeg6rn2hGdtL2tkM/exec',
    adminSlackBotUrl:
      'https://europe-west1-hummingbirdtech-production.cloudfunctions.net/admin-slack-bot',
    slackChannelApplicationFileDownload: 'C02114XCWS2',
  },
  demo: {
    apiUrl: 'https://api.demo.hummingbirdtech.com',
    surveyUrl: 'https://api.demo.hummingbirdtech.com/survey-service/api',
    gqlGatewayUrl: 'https://farm-api.demo.hummingbirdtech.com',
    bucketUrl:
      'https://www.googleapis.com/upload/storage/v1/b/hummingbirdtech-staging-raw/o?uploadType=resumable',
    analysisTileUrl: 'https://storage.googleapis.com/hummingbirdtech-prod-maps',
    applicationUrl: 'https://applications.staging.hummingbirdtech.com/',
    googleMapsApiKey: 'AIzaSyCODSR5yZwK0U3GZq8siANHhF6cfMAGytE',
    downloadApplicationFileReportSheetUrl:
      'https://script.google.com/a/hummingbirdtech.com/macros/s/AKfycbzNAzDGfS98zI-gLTxcCTPuVeg6rn2hGdtL2tkM/exec',
    adminSlackBotUrl:
      'https://europe-west1-hummingbirdtech-production.cloudfunctions.net/admin-slack-bot',
    slackChannelApplicationFileDownload: 'C02114XCWS2',
  },
  internal: {
    apiUrl: 'https://api.int.hummingbird-tech.io',
    surveyUrl: 'https://api.int.hummingbird-tech.io/survey-service/api',
    gqlGatewayUrl: 'https://farm-api.int.hummingbirdtech.com',
    bucketUrl:
      'https://www.googleapis.com/upload/storage/v1/b/hbt-staging-ortho-raw/o?uploadType=resumable',
    analysisTileUrl: 'https://storage.googleapis.com/hbt-staging-maps',
    applicationUrl: 'https://applications.staging.hummingbirdtech.com/',
    downloadApplicationFileReportSheetUrl:
      'https://script.google.com/a/hummingbirdtech.com/macros/s/AKfycbzNAzDGfS98zI-gLTxcCTPuVeg6rn2hGdtL2tkM/exec',
    adminSlackBotUrl:
      'https://europe-west1-hummingbirdtech-production.cloudfunctions.net/admin-slack-bot',
    slackChannelApplicationFileDownload: 'C02114XCWS2',
  },
};
