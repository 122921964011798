import { AnalysisConfig } from '../types/Analyses';

const vari: AnalysisConfig = {
  id: 20,
  name: 'vari',
  displayName: 'VARI',
  inAPI: 'VARI',
  type: 'analysis',
  url: 'analysis-vari',
  clouds: true,
};

export default vari;
