import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import {
  getUnitValueLocalStorage,
  getUnitLabelLocalStorage,
} from 'libraries/unitConversion';
import { AnalysisConfig } from '../types/Analyses';

const plantMeanSize: AnalysisConfig = {
  id: 35,
  name: 'plantMeanSize',
  displayName: 'Plant Mean Size',
  inAPI: 'Plant Sizing',
  type: 'analysis',
  url: 'analysis-mean_size',
  clouds: true,
  config: analysisBuilder()
    .addUnits(getUnitLabelLocalStorage({ unitId: 'centimeters-square' }))
    .addBarStep(1000)
    .addDefaultRange({
      lower: 0,
      upper: 10000,
    })
    .addTooltipScale(1000)
    .addColourList(['#ffa600', '#a05195', '#289f2c', '#22ffff'])
    .addTooltipConverter((value: number) =>
      getUnitValueLocalStorage({
        unitId: 'centimeters-square',
        value,
        precision: 0,
      }),
    )
    .build(),
};

export default plantMeanSize;
