/**
 * This component is modified from Material-UI's core version so we can remove the core MUI lib as a dependancy
 * The original component is found here: https://github.com/mui-org/material-ui/tree/next/packages/material-ui/src/SvgIcon
 */

import * as React from 'react';
import classNames from 'classnames';
import { makeStyles } from '../../styles';
import { capitalize } from '../../utilities';

const useStyles = makeStyles(({ palette, spacing }: any) => ({
  /* Styles applied to the root element. */
  root: {
    userSelect: 'none',
    width: spacing(4),
    height: spacing(4),
    display: 'inline-block',
    fill: 'currentColor',
    flexShrink: 0,
    fontSize: '24px',
  },
  xs: {
    height: spacing(1.5),
    width: spacing(1.5),
  },
  small: {
    height: spacing(2),
    width: spacing(2),
  },
  medium: {
    height: 20,
    width: 20,
  },
  mediumLarge: {
    height: spacing(3),
    width: spacing(3),
  },
  large: {
    height: spacing(4),
    width: spacing(4),
  },
  xl: {
    height: spacing(6),
    width: spacing(6),
  },
  /* Styles applied to the root element if `color="primary"`. */
  colorPrimary: {
    color: palette.secondary.main,
  },
  /* Styles applied to the root element if `color="secondary"`. */
  colorSecondary: {
    color: palette.grey.main,
  },
  /* Styles applied to the root element if `color="error"`. */
  colorError: {
    color: palette.error.main,
  },
}));

export type IconSize =
  | 'xs'
  | 'small'
  | 'medium'
  | 'mediumLarge'
  | 'large'
  | 'xl';

export interface SvgIconProps {
  children?: React.ReactNode;
  className?: string;
  color?: string;
  size?: IconSize;
  htmlColor?: string;
  viewBox?: string;
  xmlns?: string;
  role?: string;
  style?: any;
  onClick?(...args: any): any;
}

const SvgIcon = React.forwardRef(
  (props: SvgIconProps, ref): React.ReactElement => {
    const classes = useStyles();
    const {
      children,
      className,
      color = 'inherit',
      htmlColor,
      viewBox = '0 0 24 24',
      size,
      ...rest
    } = props;

    const Svg = 'svg';
    return (
      <Svg
        className={classNames(className, classes.root, {
          // @ts-ignore
          [classes[`color${capitalize(color)}`]]: color !== 'inherit',
          [classes?.[size as IconSize]]: size,
        })}
        focusable="false"
        viewBox={viewBox}
        color={htmlColor}
        ref={ref as any}
        {...rest}
      >
        {children}
      </Svg>
    );
  },
);

export default SvgIcon;
