export function makeRequest(requestName) {
  return {
    type: 'API_REQUEST',
    requestName,
  };
}

export function requestSuccess(requestName) {
  return {
    type: 'API_REQUEST_SUCCESS',
    requestName,
  };
}

export function requestFailure(requestName, status = null) {
  return {
    type: 'API_REQUEST_FAILURE',
    requestName,
    status,
  };
}
