import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

type SvgProps = {
  width: string;
  height: string;
  viewBox: string;
  fill: string;
  fillRule: string;
  stroke: string;
  strokeLinecap: string;
  strokeWidth: string;
};

const svgProps: SvgProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: 'none',
  fillRule: 'evenodd',
  stroke: '#383B41',
  strokeLinecap: 'round',
  strokeWidth: '2',
};

function ArrowIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} {...svgProps}>
      <path d="M10 18l6-6-6-6" />
    </SvgIcon>
  );
}

export default ArrowIcon;
