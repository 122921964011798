/* eslint-disable no-param-reassign */
/**
 * This code is being borrowed from the excellent Material-ui library.
 * We want to use the `createMuiTheme` function without any of the core library overhead.
 * Original code can be found here: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createTheme.js
 *
 * The function has been modified to suit the needs of this project
 */

import { deepmerge } from '../../utilities';
import createPalette from './createPalette';
import createSpacing from './createSpacing';

function createTheme(options = {}, ...args) {
  const {
    palette: paletteInput = {},
    spacing: spacingInput,
    ...other
  } = options;

  const palette = createPalette(paletteInput);
  const spacing = createSpacing(spacingInput);

  let muiTheme = deepmerge(
    {
      direction: 'ltr',
      overrides: {}, // Inject custom styles
      palette,
      props: {}, // Provide default props
      shadows: [],
      spacing,
    },
    other,
  );

  muiTheme = args.reduce((acc, argument) => deepmerge(acc, argument), muiTheme);

  if (process.env.NODE_ENV !== 'production') {
    const pseudoClasses = [
      'checked',
      'disabled',
      'error',
      'focused',
      'focusVisible',
      'required',
      'expanded',
      'selected',
    ];
    const traverse = (node, parentKey, depth = 1) => {
      let key;

      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (key in node) {
        const child = node[key];
        if (depth === 1) {
          if (key.indexOf('Mui') === 0 && child) {
            traverse(child, key, depth + 1);
          }
        } else if (
          pseudoClasses.indexOf(key) !== -1 &&
          Object.keys(child).length > 0
        ) {
          if (process.env.NODE_ENV !== 'production') {
            console.error(
              [
                `Material-UI: The \`${parentKey}\` component increases ` +
                  `the CSS specificity of the \`${key}\` internal state.`,
                'You can not override it like this: ',
                JSON.stringify(node, null, 2),
                '',
                'Instead, you need to use the $ruleName syntax:',
                JSON.stringify(
                  {
                    root: {
                      [`&$${key}`]: child,
                    },
                  },
                  null,
                  2,
                ),
                '',
                'https://material-ui.com/r/pseudo-classes-guide',
              ].join('\n'),
            );
          }
          // Remove the style to prevent global conflicts.
          node[key] = {};
        }
      }
    };

    traverse(muiTheme.overrides);
  }

  return muiTheme;
}

export default createTheme;
