import ReactGA from 'react-ga';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';

export function fireGAEvent(
  category: EventCategory,
  action: EventAction,
  label?: string,
  value?: number,
): void {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
}
