import React from 'react';
import { Typography } from './util';
import { ContentTag } from './util/Element';
import { Variant, Theme } from './util/Typography';

type Props = {
  children: React.ReactNode;
  variant?: Variant;
  theme?: Theme;
  className?: string;
  truncate?: boolean;
};

const TH = (props: Props) => <Typography {...props} type={ContentTag.TH} />;

export default TH;
