import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function UploadFileIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g fill="none" stroke="currentColor" strokeLinecap="round">
        <path d="M297.4,423.1V412.8" transform="translate(-285.4 -409.3)" />
        <line x1="12" y1="3" x2="16" y2="7" />
        <line x1="12" y1="3" x2="8" y2="7" />
        <line x1="3.5" y1="10.4" x2="3.5" y2="21" />
        <line x1="20.5" y1="10.4" x2="20.5" y2="21" />
        <line x1="20.5" y1="21" x2="3.5" y2="21" />
      </g>
    </SvgIcon>
  );
}

export default UploadFileIcon;
