const leafletStyles = {
  '.leaflet-pane,\n.leaflet-tile,\n.leaflet-marker-icon,\n.leaflet-marker-shadow,\n.leaflet-tile-container,\n.leaflet-map-pane svg,\n.leaflet-map-pane canvas,\n.leaflet-zoom-box,\n.leaflet-image-layer,\n.leaflet-layer': {
    position: 'absolute',
    left: '0',
    top: '0',
  },
  '.leaflet-container': { overflow: 'hidden' },
  '.leaflet-tile,\n.leaflet-marker-icon,\n.leaflet-marker-shadow': {
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    userSelect: 'none',
    WebkitUserDrag: 'none',
  },
  '.leaflet-safari .leaflet-tile': {
    imageRendering: '-webkit-optimize-contrast',
  },
  '.leaflet-safari .leaflet-tile-container': {
    width: '1600px',
    height: '1600px',
    WebkitTransformOrigin: '0 0',
  },
  '.leaflet-marker-icon,\n.leaflet-marker-shadow': { display: 'block' },
  '.leaflet-container .leaflet-overlay-pane svg,\n.leaflet-container .leaflet-marker-pane img,\n.leaflet-container .leaflet-tile-pane img,\n.leaflet-container img.leaflet-image-layer': {
    maxWidth: 'none !important',
  },
  '.leaflet-container.leaflet-touch-zoom': {
    msTouchAction: 'pan-x pan-y',
    touchAction: 'pan-x pan-y',
  },
  '.leaflet-container.leaflet-touch-drag': { msTouchAction: 'pinch-zoom' },
  '.leaflet-container.leaflet-touch-drag.leaflet-touch-drag': {
    msTouchAction: 'none',
    touchAction: 'none',
  },
  '.leaflet-tile': { filter: 'inherit', visibility: 'hidden' },
  '.leaflet-tile-loaded': { visibility: 'inherit' },
  '.leaflet-zoom-box': {
    width: '0',
    height: '0',
    MozBoxSizing: 'border-box',
    boxSizing: 'border-box',
    zIndex: 800,
  },
  '.leaflet-overlay-pane svg': { MozUserSelect: 'none' },
  '.leaflet-pane': { zIndex: 400, width: 'inherit' },
  '.leaflet-tile-pane': { zIndex: 200 },
  '.leaflet-overlay-pane': { zIndex: 450 },
  '.leaflet-shadow-pane': { zIndex: 500 },
  '.leaflet-marker-pane': { zIndex: 600 },
  '.leaflet-tooltip-pane': { zIndex: 650 },
  '.leaflet-popup-pane': { zIndex: 9999 },
  '.leaflet-map-pane canvas': { zIndex: 100 },
  '.leaflet-map-pane svg': { zIndex: 200 },
  '.leaflet-vml-shape': { width: '1px', height: '1px' },
  '.lvml': {
    behavior: 'url(#default#VML)',
    display: 'inline-block',
    position: 'absolute',
  },
  '.leaflet-control': {
    position: 'relative',
    zIndex: 800,
    pointerEvents: 'visiblePainted',
    cssFloat: 'left',
    clear: 'both',
    fallbacks: {
      pointerEvents: 'auto',
    },
  },
  '.leaflet-top,\n.leaflet-bottom': {
    position: 'absolute',
    zIndex: 1000,
    pointerEvents: 'none',
  },
  '.leaflet-top': { top: '0' },
  '.leaflet-right': { right: '0' },
  '.leaflet-bottom': { bottom: '0' },
  '.leaflet-left': { left: '0' },
  '.leaflet-right .leaflet-control': { cssFloat: 'right', marginRight: '10px' },
  '.leaflet-top .leaflet-control': { marginTop: '10px' },
  '.leaflet-bottom .leaflet-control': { marginBottom: '10px' },
  '.leaflet-left .leaflet-control': { marginLeft: '10px' },
  '.leaflet-fade-anim .leaflet-tile': { willChange: 'opacity' },
  '.leaflet-fade-anim .leaflet-popup': {
    opacity: 0,
    WebkitTransition: 'opacity 0.2s linear',
    MozTransition: 'opacity 0.2s linear',
    OTransition: 'opacity 0.2s linear',
    transition: 'opacity 0.2s linear',
  },
  '.leaflet-fade-anim .leaflet-map-pane .leaflet-popup': { opacity: 1 },
  '.leaflet-zoom-animated': {
    WebkitTransformOrigin: '0 0',
    msTransformOrigin: '0 0',
    transformOrigin: '0 0',
  },
  '.leaflet-zoom-anim .leaflet-zoom-animated': {
    willChange: 'transform',
    WebkitTransition: '-webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1)',
    MozTransition: '-moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1)',
    OTransition: '-o-transform 0.25s cubic-bezier(0, 0, 0.25, 1)',
    transition: 'transform 0.25s cubic-bezier(0, 0, 0.25, 1)',
  },
  '.leaflet-zoom-anim .leaflet-tile,\n.leaflet-pan-anim .leaflet-tile': {
    WebkitTransition: 'none',
    MozTransition: 'none',
    OTransition: 'none',
    transition: 'none',
  },
  '.leaflet-zoom-anim .leaflet-zoom-hide': { visibility: 'hidden' },
  '.leaflet-interactive': { cursor: 'pointer' },
  '.leaflet-grab': {
    cursor: '-webkit-grab',
    fallbacks: {
      cursor: '-moz-grab',
    },
  },
  '.leaflet-crosshair,\n.leaflet-crosshair .leaflet-interactive': {
    cursor: 'crosshair',
  },
  '.leaflet-popup-pane,\n.leaflet-control': { cursor: 'auto' },
  '.leaflet-drag-target': {
    cursor: 'move',
    fallbacks: [{ cursor: '-webkit-grabbing' }, { cursor: 'moz-grabbing' }],
  },
  '.leaflet-marker-icon,\n.leaflet-marker-shadow,\n.leaflet-image-layer,\n.leaflet-pane > svg path,\n.leaflet-tile-container': {
    pointerEvents: 'none',
  },
  '.leaflet-marker-icon.leaflet-interactive,\n.leaflet-image-layer.leaflet-interactive,\n.leaflet-pane > svg path.leaflet-interactive': {
    pointerEvents: 'visiblePainted',
    fallbacks: {
      pointerEvents: 'auto',
    },
  },
  '.leaflet-pane > svg path.leaflet-interactive.leaflet-non-interactive': {
    pointerEvents: 'none',
  },
  '.leaflet-default-icon-path': {
    backgroundImage: "url('../images/marker-icon.svg')",
  },
  '.leaflet-container .leaflet-control-attribution': {
    background: ['#fff', 'rgba(255, 255, 255, 0.7)'],
    margin: '0',
  },
  '.leaflet-control-attribution,\n.leaflet-control-scale-line': {
    padding: '0 5px',
    color: '#333',
  },
  '.leaflet-control-attribution a': { textDecoration: 'none' },
  '.leaflet-control-attribution a:hover': { textDecoration: 'underline' },
  '.leaflet-container .leaflet-control-attribution,\n.leaflet-container .leaflet-control-scale': {
    fontSize: '11px',
  },
  '.leaflet-left .leaflet-control-scale': { marginLeft: '5px' },
  '.leaflet-bottom .leaflet-control-scale': { marginBottom: '5px' },
  '.leaflet-control-scale-line': {
    border: '2px solid #777',
    borderTop: 'none',
    lineHeight: 1.1,
    padding: '2px 5px 1px',
    fontSize: '11px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    MozBoxSizing: 'border-box',
    boxSizing: 'border-box',
    background: ['#fff', 'rgba(255, 255, 255, 0.5)'],
  },
  '.leaflet-control-scale-line:not(:first-child)': {
    borderTop: '2px solid #777',
    borderBottom: 'none',
    marginTop: '-2px',
  },
  '.leaflet-control-scale-line:not(:first-child):not(:last-child)': {
    borderBottom: '2px solid #777',
  },
  '.leaflet-touch .leaflet-control-attribution,\n.leaflet-touch .leaflet-control-layers,\n.leaflet-touch .leaflet-bar': {
    boxShadow: 'none',
  },
  '.leaflet-touch .leaflet-control-layers,\n.leaflet-touch .leaflet-bar': {
    border: '0',
    backgroundClip: 'padding-box',
  },
  '.leaflet-popup': {
    position: 'absolute',
    textAlign: 'center',
    marginBottom: '20px',
  },
  '.leaflet-popup-content-wrapper': {
    padding: '1px',
    textAlign: 'left',
    borderRadius: '12px',
  },
  '.leaflet-popup-content': { margin: '13px 19px', lineHeight: 1.4 },
  '.leaflet-popup-content p': { margin: '18px 0' },
  '.leaflet-popup-tip-container': {
    width: '40px',
    height: '20px',
    position: 'absolute',
    left: '50%',
    marginLeft: '-20px',
    overflow: 'hidden',
    pointerEvents: 'none',
  },
  '.leaflet-popup-tip': {
    width: '17px',
    height: '17px',
    padding: '1px',
    margin: '-10px auto 0',
    WebkitTransform: 'rotate(45deg)',
    MozTransform: 'rotate(45deg)',
    msTransform: 'rotate(45deg)',
    OTransform: 'rotate(45deg)',
    transform: 'rotate(45deg)',
  },
  '.leaflet-popup-content-wrapper,\n.leaflet-popup-tip': {
    background: 'white',
    color: '#333',
    boxShadow: '0 3px 14px rgba(0, 0, 0, 0.4)',
  },
  '.leaflet-container a.leaflet-popup-close-button': {
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '4px 4px 0 0',
    border: 'none',
    textAlign: 'center',
    width: '18px',
    height: '14px',
    font: '16px/14px Tahoma, Verdana, sans-serif',
    color: '#c3c3c3',
    textDecoration: 'none',
    fontWeight: 'bold',
    background: 'transparent',
  },
  '.leaflet-container a.leaflet-popup-close-button:hover': { color: '#999' },
  '.leaflet-popup-scrolled': {
    overflow: 'auto',
    borderBottom: '1px solid #ddd',
    borderTop: '1px solid #ddd',
  },
  '.leaflet-oldie .leaflet-popup-content-wrapper': { zoom: 1 },
  '.leaflet-oldie .leaflet-popup-tip': {
    width: '24px',
    margin: '0 auto',
    msFilter:
      "'progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)'",
    filter:
      'progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)',
  },
  '.leaflet-oldie .leaflet-popup-tip-container': { marginTop: '-1px' },
  '.leaflet-oldie .leaflet-control-zoom,\n.leaflet-oldie .leaflet-control-layers,\n.leaflet-oldie .leaflet-popup-content-wrapper,\n.leaflet-oldie .leaflet-popup-tip': {
    border: '1px solid #999',
  },
  '.leaflet-div-icon': { background: '#fff', border: '1px solid #666' },
  '.leaflet-tooltip': {
    position: 'absolute',
    padding: '5px',
    borderRadius: '3px',
    color: 'var(--c-text-main)',
    whiteSpace: 'nowrap',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    pointerEvents: 'none',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.4)',
    border: '1px solid var(--c-body-bg)',
    backgroundColor: 'var(--c-highlight-bg)',
  },
  '.leaflet-tooltip.leaflet-clickable': {
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  '.leaflet-tooltip-top:before,\n.leaflet-tooltip-bottom:before,\n.leaflet-tooltip-left:before,\n.leaflet-tooltip-right:before': {
    position: 'absolute',
    pointerEvents: 'none',
    border: '6px solid transparent',
    background: 'transparent',
    content: "''",
  },
  '.leaflet-tooltip-bottom': { marginTop: '6px' },
  '.leaflet-tooltip-top': { marginTop: '-6px' },
  '.leaflet-tooltip-left': { marginLeft: '-6px' },
  '.leaflet-tooltip-right': { marginLeft: '6px' },
  '.leaflet-tooltip-bottom:before,\n.leaflet-tooltip-top:before': {
    left: '50%',
    marginLeft: '-6px',
  },
  '.leaflet-tooltip-bottom:after,\n.leaflet-tooltip-top:after': {
    left: '50%',
    marginLeft: '-7px',
  },
  '.leaflet-tooltip-top:before': {
    bottom: '0',
    marginBottom: '-12px',
    borderTopColor: 'var(--c-body-bg)',
  },
  '.leaflet-tooltip-bottom:before': {
    top: '0',
    marginTop: '-12px',
    marginLeft: '-6px',
    borderBottomColor: 'var(--c-body-bg)',
  },
  '.leaflet-tooltip-left:before,\n.leaflet-tooltip-right:before': {
    top: '50%',
    marginTop: '-6px',
  },
  '.leaflet-tooltip-left:before': {
    right: '0',
    marginRight: '-12px',
    borderLeftColor: 'var(--c-body-bg)',
  },
  '.leaflet-tooltip-right:before': {
    left: '0',
    marginLeft: '-12px',
    borderRightColor: 'var(--c-highlight-bg)',
  },
  '.leaflet-tooltip-top:after,\n.leaflet-tooltip-bottom:after,\n.leaflet-tooltip-left:after,\n.leaflet-tooltip-right:after': {
    position: 'absolute',
    pointerEvents: 'none',
    border: '7px solid transparent',
    background: 'transparent',
    content: "''",
  },
  '.leaflet-tooltip-top:after': {
    bottom: '0',
    marginBottom: '-12px',
    borderTopColor: 'var(--c-highlight-bg)',
  },
  '.leaflet-tooltip-bottom:after': {
    top: '0',
    marginTop: '-12px',
    marginLeft: '-7px',
    borderBottomColor: 'var(--c-highlight-bg)',
  },
  '.leaflet-tooltip-left:after,\n.leaflet-tooltip-right:after': {
    top: '50%',
    marginTop: '-7px',
  },
  '.leaflet-tooltip-left:after': {
    right: '0',
    marginRight: '-12px',
    borderLeftColor: 'var(--c-highlight-bg)',
  },
  '.leaflet-tooltip-right:after': {
    left: '0',
    marginLeft: '-12px',
    borderRightColor: 'var(--c-highlight-bg)',
  },
  '.leaflet-polygon-marker,\n.leaflet-mouse-marker': {
    background: 'var(--c-highlight-bg)',
    border: '1px solid var(--hb-blue)',
    borderRadius: '50%',
  },
  '.leaflet-marker-icon.leaflet-div-icon.leaflet-editing-icon.leaflet-marker-draggable': {
    height: '16px !important',
    width: '16px !important',
    marginLeft: '-8px !important',
    marginTop: '-8px !important',
    background: '#fff',
    border: '1px solid var(--hb-blue)',
    borderRadius: '50%',
  },
};

export default leafletStyles;
