import { makeStyles } from 'libraries/wings-ui/styles';

const useScrollStyles = makeStyles(({ spacing }: any) => ({
  scroll: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      '&:vertical': {
        width: spacing(),
      },
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: spacing(),
      border: 'none',
      backgroundColor: 'rgba(0, 0, 0, .4)',
    },
  },
}));
export default useScrollStyles;
