import { AnalysisConfig } from '../types/Analyses';

const osrDesiccationApplication: AnalysisConfig = {
  id: 14,
  name: 'osrDesiccationApplication',
  displayName: 'OSR Desiccation',
  inAPI: 'OSR Desiccation Map',
  type: 'analysis',
  url: 'application-desiccation/analysis-gridded_ndvi',
  status: 'deprecated',
  clouds: true,
  alwaysShown: true,
};

export default osrDesiccationApplication;
