import contourConfig from 'constants/contourConfig';
import applicationReducerFactory from 'modules/FieldsMap/partials/Applications/reduxState/applicationStateManagement/applicationReducerFactory';

export function transformer(application) {
  return {
    enabled: true,
    range: {
      lower: 0,
      upper: 1,
    },
    application: application.map(gridSquare => ({
      ...gridSquare,
      value: gridSquare.spray ? 1 : 0,
      tooltip: gridSquare.spray ? 'Spray' : 'No spray',
    })),
  };
}

const variableTotalHerbicideReducer = applicationReducerFactory(
  contourConfig.variableTotalHerbicide.name,
  transformer,
);

export default variableTotalHerbicideReducer;
