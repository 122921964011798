import applicationReducerFactory from './applicationStateManagement/applicationReducerFactory';
import * as contourConfig from '../config';

import nitrogenApplicationReducer from './nitrogenApplication/nitrogenApplicationReducer';
import nitrogenApplicationFromGAIReducer from './nitrogenApplicationFromGAI/nitrogenApplicationFromGAIReducer';
import sclerotiniaApplicationReducer from './sclerotiniaApplication/sclerotiniaApplicationReducer';
import variableTotalHerbicideReducer from './variableTotalHerbicide/variableTotalHerbicideReducer';
import griddedDesiccationApplicationReducer from './griddedDesiccationApplication/desiccationApplicationReducer.js';
import variablePreEmergenceHerbicideReducer from './variablePreEmergenceHerbicide/variablePreEmergenceHerbicideReducer';
import variablePostEmergenceHerbicideReducer from './variablePostEmergenceHerbicide/variablePostEmergenceHerbicideReducer';
import pgrApplicationFromGAIReducer from './pgrApplicationFromGAI/pgrApplicationFromGAIReducer';
import NDVISeedApplicationReducer from './NDVISeedApplication/NDVISeedApplicationReducer';

import selectedApplicationReducer from './applicationStateManagement/selectedApplicationReducer';
import selectedApplicationLevelReducer from './applicationStateManagement/selectedApplicationLevelReducer';
import previousApplicationEditorStateReducer from './applicationStateManagement/previousApplicationEditorStateReducer';

const applicationManagementReducers = {
  previousApplicationEditorState: previousApplicationEditorStateReducer,
  selectedApplication: selectedApplicationReducer,
  selectedApplicationLevel: selectedApplicationLevelReducer,
};

const griddedApplicationReducers = {
  nitrogenApplication: nitrogenApplicationReducer,
  nitrogenApplicationFromGAI: nitrogenApplicationFromGAIReducer,
  sclerotiniaApplication: sclerotiniaApplicationReducer,
  variableTotalHerbicide: variableTotalHerbicideReducer,
  griddedDesiccationApplication: griddedDesiccationApplicationReducer,
  variablePreEmergenceHerbicide: variablePreEmergenceHerbicideReducer,
  variablePostEmergenceHerbicide: variablePostEmergenceHerbicideReducer,
  pgrApplicationFromGAI: pgrApplicationFromGAIReducer,
  NDVISeedApplication: NDVISeedApplicationReducer,
};

const simplifiedApplicationKeys = [
  contourConfig.desiccationApplication.name,
  contourConfig.fungicideApplication.name,
  contourConfig.linearNDVIApplication.name,
  contourConfig.pgrApplication.name,
  contourConfig.pgrFromGaiApplication.name,
  contourConfig.postEmHerbicideApplication.name,
  contourConfig.preEmHerbicideApplication.name,
];

export const simplifiedApplicationReducers = simplifiedApplicationKeys.reduce(
  (acc: any, applicationKey: string) => {
    acc[applicationKey] = applicationReducerFactory(applicationKey);
    return acc;
  },
  {},
);

export const applicationReducers = {
  ...applicationManagementReducers,
  ...simplifiedApplicationReducers,
  ...griddedApplicationReducers,
};
