import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function ShapefileIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 120 120">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="3"
        d="M84 93H36m0-43h20m0-22.86V50m0-23h28m0 0v66m-48 0V50m0 0l20-23"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        d="M57.48 58.53l-9 10m14 13l8-7m-8-16l8 11m-22 4l9 8"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        d="M60 59a3 3 0 113-3 3 3 0 01-3 3zm13 16a3 3 0 113-3 3 3 0 01-3 3zm-27-1a3 3 0 113-3 3 3 0 01-3 3zm14 13a3 3 0 113-3 3 3 0 01-3 3z"
      />
    </SvgIcon>
  );
}

export default ShapefileIcon;
