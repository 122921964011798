import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function CropVarietyIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path
          d="M307,428.3c-.2.2-.4.3-.6.2a2.6,2.6,0,0,1-1-.9,3.4,3.4,0,0,1-.6-2.4,3.8,3.8,0,0,1,2.1,1.2C307.6,427.3,307.6,427.9,307,428.3Z"
          transform="translate(-291 -420.2)"
          fill="none"
        />
        <path
          d="M305,425.5a3.5,3.5,0,0,0,.6,2c.8,1,1.1.8,1.3.6s.6-.5-.2-1.5a3,3,0,0,0-1.7-1.1m-.5-.6a4.2,4.2,0,0,1,2.6,1.4c.9,1.2.6,1.9.1,2.2s-1.1.5-2-.7A4.5,4.5,0,0,1,304.5,424.9Z"
          transform="translate(-291 -420.2)"
          fill="currentColor"
        />
      </g>
      <line
        x1="16.5"
        y1="18.9"
        x2="16.5"
        y2="22.2"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <g>
        <path
          d="M307,431.7a.7.7,0,0,1-.6.1,2.6,2.6,0,0,1-1-.9,3.4,3.4,0,0,1-.6-2.4,3.5,3.5,0,0,1,2.1,1.3C307.6,430.6,307.6,431.2,307,431.7Z"
          transform="translate(-291 -420.2)"
          fill="none"
        />
        <path
          d="M305,428.8a3.5,3.5,0,0,0,.6,2c.8,1,1.1.8,1.3.7s.6-.5-.2-1.6a3.6,3.6,0,0,0-1.7-1.1m-.5-.6a4.2,4.2,0,0,1,2.6,1.4c.9,1.2.6,1.9.1,2.3s-1.1.4-2-.8A4.5,4.5,0,0,1,304.5,428.2Z"
          transform="translate(-291 -420.2)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M307,435c-.2.2-.4.3-.6.2a2.6,2.6,0,0,1-1-.9,3.4,3.4,0,0,1-.6-2.4,3.8,3.8,0,0,1,2.1,1.2C307.6,434,307.6,434.6,307,435Z"
          transform="translate(-291 -420.2)"
          fill="none"
        />
        <path
          d="M305,432.2a3.5,3.5,0,0,0,.6,2c.8,1,1.1.8,1.3.6s.6-.4-.2-1.5a3,3,0,0,0-1.7-1.1m-.5-.6a4.2,4.2,0,0,1,2.6,1.4c.9,1.2.6,1.9.1,2.2s-1.1.5-2-.7A4.5,4.5,0,0,1,304.5,431.6Z"
          transform="translate(-291 -420.2)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M307,438.4c-.2.2-.4.3-.6.2a2.6,2.6,0,0,1-1-.9,3.4,3.4,0,0,1-.6-2.4,3.8,3.8,0,0,1,2.1,1.2C307.6,437.4,307.6,438,307,438.4Z"
          transform="translate(-291 -420.2)"
          fill="none"
        />
        <path
          d="M305,435.6a3.5,3.5,0,0,0,.6,2c.8,1,1.1.8,1.3.6s.6-.5-.2-1.5a3,3,0,0,0-1.7-1.1m-.5-.6a4.2,4.2,0,0,1,2.6,1.4c.9,1.2.6,1.9.1,2.2s-1.1.5-2-.7A4.5,4.5,0,0,1,304.5,435Z"
          transform="translate(-291 -420.2)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M310.4,425.2a3.4,3.4,0,0,1-.6,2.4,2.6,2.6,0,0,1-1,.9c-.2.1-.4,0-.7-.2s-.5-1,.1-1.9A4,4,0,0,1,310.4,425.2Z"
          transform="translate(-291 -420.2)"
          fill="none"
        />
        <path
          d="M310.2,425.5a3.5,3.5,0,0,0-1.8,1.1c-.8,1-.3,1.4-.1,1.5l.4.2a2.8,2.8,0,0,0,.9-.8,3.5,3.5,0,0,0,.6-2m.5-.6a4.5,4.5,0,0,1-.7,2.9c-.9,1.2-1.5,1.1-2,.7s-.9-1,0-2.2A4.4,4.4,0,0,1,310.7,424.9Z"
          transform="translate(-291 -420.2)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M307.5,425.7c-.3,0-.5,0-.6-.2a2.3,2.3,0,0,1-.3-1.3,4.1,4.1,0,0,1,1-2.4,4.1,4.1,0,0,1,1,2.4C308.6,425.2,308.2,425.7,307.5,425.7Z"
          transform="translate(-291 -420.2)"
          fill="none"
        />
        <path
          d="M307.6,422.2a4,4,0,0,0-.8,2,1.5,1.5,0,0,0,.3,1.1c0,.1.1.2.4.2s.8,0,.8-1.3a4,4,0,0,0-.7-2m0-.8a4.8,4.8,0,0,1,1.2,2.8c0,1.5-.7,1.8-1.3,1.8s-1.2-.3-1.2-1.8A4.6,4.6,0,0,1,307.6,421.4Z"
          transform="translate(-291 -420.2)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M310.4,428.5a3.4,3.4,0,0,1-.6,2.4,2.6,2.6,0,0,1-1,.9c-.2.1-.4,0-.7-.1s-.5-1.1.1-1.9A3.7,3.7,0,0,1,310.4,428.5Z"
          transform="translate(-291 -420.2)"
          fill="none"
        />
        <path
          d="M310.2,428.8a4.4,4.4,0,0,0-1.8,1.1c-.8,1.1-.3,1.4-.1,1.6h.4a1.9,1.9,0,0,0,.9-.8,3.3,3.3,0,0,0,.6-2m.5-.6a4.5,4.5,0,0,1-.7,2.9c-.9,1.2-1.5,1.1-2,.8s-.9-1.1,0-2.3A4.4,4.4,0,0,1,310.7,428.2Z"
          transform="translate(-291 -420.2)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M310.4,431.9a3.4,3.4,0,0,1-.6,2.4,2.6,2.6,0,0,1-1,.9c-.2.1-.4,0-.7-.2s-.5-1,.1-1.9A4,4,0,0,1,310.4,431.9Z"
          transform="translate(-291 -420.2)"
          fill="none"
        />
        <path
          d="M310.2,432.2a3.5,3.5,0,0,0-1.8,1.1c-.8,1.1-.3,1.4-.1,1.5s.3.2.4.2a2.8,2.8,0,0,0,.9-.8,3.5,3.5,0,0,0,.6-2m.5-.6a4.5,4.5,0,0,1-.7,2.9c-.9,1.2-1.5,1.1-2,.7s-.9-1,0-2.2A4.4,4.4,0,0,1,310.7,431.6Z"
          transform="translate(-291 -420.2)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M310.4,435.3a3.4,3.4,0,0,1-.6,2.4,2.6,2.6,0,0,1-1,.9c-.2.1-.4,0-.7-.2s-.5-1,.1-1.9A4,4,0,0,1,310.4,435.3Z"
          transform="translate(-291 -420.2)"
          fill="none"
        />
        <path
          d="M310.2,435.6a3.5,3.5,0,0,0-1.8,1.1c-.8,1-.3,1.4-.1,1.5s.3.2.4.2a2.8,2.8,0,0,0,.9-.8,3.5,3.5,0,0,0,.6-2m.5-.6a4.5,4.5,0,0,1-.7,2.9c-.9,1.2-1.5,1.1-2,.7s-.9-1,0-2.2A4.4,4.4,0,0,1,310.7,435Z"
          transform="translate(-291 -420.2)"
          fill="currentColor"
        />
      </g>
      <path
        d="M295,424.9a3.8,3.8,0,0,1,2.6,1.4c.9,1.2.6,1.9.1,2.2s-1.1.5-2-.7A4.3,4.3,0,0,1,295,424.9Z"
        transform="translate(-291 -420.2)"
        fill="currentColor"
      />
      <line
        x1="7"
        y1="18.9"
        x2="7"
        y2="22.2"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M295,428.2a3.8,3.8,0,0,1,2.6,1.4c.9,1.2.6,1.9.1,2.3s-1.1.4-2-.8A4.3,4.3,0,0,1,295,428.2Z"
        transform="translate(-291 -420.2)"
        fill="currentColor"
      />
      <path
        d="M295,431.6a3.8,3.8,0,0,1,2.6,1.4c.9,1.2.6,1.9.1,2.2s-1.1.5-2-.7A4.3,4.3,0,0,1,295,431.6Z"
        transform="translate(-291 -420.2)"
        fill="currentColor"
      />
      <path
        d="M295,435a3.8,3.8,0,0,1,2.6,1.4c.9,1.2.6,1.9.1,2.2s-1.1.5-2-.7A4.3,4.3,0,0,1,295,435Z"
        transform="translate(-291 -420.2)"
        fill="currentColor"
      />
      <path
        d="M301.2,424.9a4,4,0,0,0-2.7,1.4c-.9,1.2-.5,1.9,0,2.2s1.1.5,2-.7A4.3,4.3,0,0,0,301.2,424.9Z"
        transform="translate(-291 -420.2)"
        fill="currentColor"
      />
      <path
        d="M298.1,421.4a4.7,4.7,0,0,1,1.2,2.8c0,1.5-.7,1.8-1.3,1.8s-1.2-.3-1.2-1.8A4.9,4.9,0,0,1,298.1,421.4Z"
        transform="translate(-291 -420.2)"
        fill="currentColor"
      />
      <path
        d="M301.2,428.2a4,4,0,0,0-2.7,1.4c-.9,1.2-.5,1.9,0,2.3s1.1.4,2-.8A4.3,4.3,0,0,0,301.2,428.2Z"
        transform="translate(-291 -420.2)"
        fill="currentColor"
      />
      <path
        d="M301.2,431.6a4,4,0,0,0-2.7,1.4c-.9,1.2-.5,1.9,0,2.2s1.1.5,2-.7A4.3,4.3,0,0,0,301.2,431.6Z"
        transform="translate(-291 -420.2)"
        fill="currentColor"
      />
      <path
        d="M301.2,435a4,4,0,0,0-2.7,1.4c-.9,1.2-.5,1.9,0,2.2s1.1.5,2-.7A4.3,4.3,0,0,0,301.2,435Z"
        transform="translate(-291 -420.2)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default CropVarietyIcon;
