import React from 'react';
import classNames from 'classnames';
import { makeStyles } from 'libraries/wings-ui/styles';
import { TickIcon } from 'libraries/wings-ui/icons';

type StyleProps = {
  nomargin?: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles(({ palette, spacing }: any) => ({
  root: {
    appearance: 'none',
    cursor: 'pointer',
    outline: 'none',
    width: spacing(2),
    height: spacing(2),
    minWidth: spacing(2),
    minHeight: spacing(2),
    borderRadius: '3px',
    border: `2px solid ${palette.secondary.main}`,
    '&:disabled': {
      cursor: 'default',
      borderColor: palette.action.disabled,
      '&:hover': {
        borderColor: palette.action.disabled,
      },
    },
    '&:checked': {
      backgroundColor: palette.secondary.main,
      color: 'white',
    },
  },
  hoverRoot: {
    cursor: ({ disabled }: StyleProps) => (disabled ? 'default' : 'pointer'),
    position: 'relative',
    color: 'white',
    margin: ({ nomargin }: StyleProps) => (nomargin ? '' : spacing(0.5, 1)),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: spacing(3.25),
    height: spacing(3.25),
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: ({ disabled }: StyleProps) =>
        disabled ? '' : `${palette.secondary.main}25`,
    },
  },
}));

type Props = {
  id?: string;
  checked: boolean;
  className?: string;
  disabled?: boolean;
  disableMargin?: boolean;
  onChange: () => void;
  required?: boolean;
};

function Checkbox({
  id,
  checked = false,
  className,
  disabled = false,
  disableMargin = false,
  onChange,
  required = false,
}: Props): React.ReactElement {
  const classes = useStyles({ nomargin: disableMargin, disabled });
  return (
    <span
      onClick={onChange}
      className={classNames(classes.hoverRoot, className)}
    >
      <input
        id={id}
        readOnly
        checked={checked}
        required={required}
        disabled={disabled}
        type="checkbox"
        className={classes.root}
      />

      <TickIcon
        style={{
          position: 'absolute',
          top: 8,
          left: 7,
          height: 12,
          width: 12,
          fontSize: 14,
          color: 'inherit',
          display: checked ? 'block' : 'none',
          pointerEvents: 'none',
        }}
      />
    </span>
  );
}

export default Checkbox;
