const initialState = {
  authUrl: null,
  jdNodes: null,
  selectedNode: null,
  transferSuccess: null,
  transferError: null,
  conversionError: null,
  loading: false,
  onsiteEquipmentList: undefined,
};

export default initialState;
