const colourLegendStyles = {
  '.ColourLegend': {
    position: 'relative',
    display: 'block',
    width: '100%',
    lineHeight: '20px',
    padding: '0',
  },
  '.ColourLegend__Item': {
    cssFloat: 'left',
    listStyle: 'none',
    listStyleType: 'none',
    margin: '20px 4px',
  },
  '.ColourLegend__Item--no-float': { cssFloat: 'none' },
  '.ColourLegend__Item--horizontal': {
    listStyle: 'none',
    listStyleType: 'none',
    textAlign: 'center',
    cssFloat: 'left',
    margin: '0',
    padding: '0',
    fontSize: '10px',
  },
  '.ColourLegend__Item--horizontal:first-child .ColourLegend__Indicator--horizontal': {
    marginLeft: '0',
  },
  '.ColourLegend__Item--horizontal:last-child .ColourLegend__Indicator--horizontal': {
    marginRight: '0',
  },
  '.ColourLegend__Indicator': {
    width: '10px',
    height: '10px',
    display: 'inline-block',
    marginRight: '4px',
    border: '1px solid #adadad',
  },
  '.ColourLegend__Indicator--horizontal': {
    position: 'relative',
    width: '50px',
    margin: '0 1.2px',
    height: '20px',
    display: 'block',
  },
  '.ColourLegend__Units': {
    listStyleType: 'none',
    textAlign: 'right',
    width: '100%',
  },
};

export default colourLegendStyles;
