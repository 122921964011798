import React from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function HorizontalMoreIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path
          d="M292,419a2,2,0,0,1,0,4A2,2,0,0,1,292,419Z"
          transform="translate(-286 -409)"
          fill="none"
        />
        <path
          d="M292,419.5a1.5,1.5,0,0,1,0,3A1.5,1.5,0,0,1,292,419.5Z"
          transform="translate(-286 -409)"
          fill="currentColor"
          stroke="currentColor"
        />
      </g>
      <g>
        <path
          d="M298,419a2,2,0,0,1,0,4A2,2,0,0,1,298,419Z"
          transform="translate(-286 -409)"
          fill="none"
        />
        <path
          d="M298,419.5a1.5,1.5,0,0,1,0,3A1.5,1.5,0,0,1,298,419.5Z"
          transform="translate(-286 -409)"
          fill="currentColor"
          stroke="currentColor"
        />
      </g>
      <g>
        <path
          d="M304,419a2,2,0,0,1,0,4A2,2,0,0,1,304,419Z"
          transform="translate(-286 -409)"
          fill="none"
        />
        <path
          d="M304,419.5a1.5,1.5,0,0,1,0,3A1.5,1.5,0,0,1,304,419.5Z"
          transform="translate(-286 -409)"
          fill="currentColor"
          stroke="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default HorizontalMoreIcon;
