import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function CombineHarvesterIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <g>
          <path
            d="M296.5,429.1h4.4v-1.7h6.3V426h6.4c.1.3.9,3,0,3.2h-.5l-1,6h2.8l3.9,5.7v.8h-3.3l-1.4-1.3v-1.3h-3.5c-1-5.6-7.3-6-8.3,0h-.6c-1.4-4-5.2-3.3-6.9-.2C294.3,436.5,296.1,430.2,296.5,429.1Z"
            transform="translate(-294.7 -421.4)"
            fill="none"
          />
          <path
            d="M308.2,427v1.4h-6.3v1.7h-4.6a37.5,37.5,0,0,0-1.5,6.2,3.9,3.9,0,0,1,6,.9c1.8-5.2,8.4-4.1,9.6.9h3.7v1.8l.8.8h1.5l-3-4.5h-3.5c.3-1.4,1.1-6.5,1.3-8h.9a10.4,10.4,0,0,1-.3-1.2h-4.6m-1-1h6.4c.1.3.9,3,0,3.2h-.5l-1,6h2.8l3.9,5.7v.8h-3.3l-1.4-1.3v-1.3h-3.5c-1-5.6-7.3-6-8.3,0h-.6c-1.4-4-5.1-3.3-6.9-.2-.6-.8,1.4-9.5,1.7-9.8h4.4v-1.7h6.3Z"
            transform="translate(-294.7 -421.4)"
            fill="currentColor"
          />
        </g>
        <g>
          <path
            d="M309.8,432.4h-1.9l1-3.2h1.9Z"
            transform="translate(-294.7 -421.4)"
            fill="none"
          />
          <path
            d="M309.3,429.7l-.7,2.2h.8l.7-2.2h-.8m-.7-1h2.8l-1.3,4.2h-2.9Z"
            transform="translate(-294.7 -421.4)"
            fill="currentColor"
          />
        </g>
        <line
          x1="3.2"
          y1="9.8"
          x2="8"
          y2="9.8"
          fill="none"
          stroke="currentColor"
        />
        <line
          x1="3.2"
          y1="11.8"
          x2="8"
          y2="11.8"
          fill="none"
          stroke="currentColor"
        />
        <g>
          <path
            d="M306.4,442c-4.8,0-4-7.6.6-6.6S310.3,442.1,306.4,442Zm0-5.1c-2.3,0-2.3,3.6,0,3.5s2.3-3.5,0-3.5Z"
            transform="translate(-294.7 -421.4)"
            fill="none"
          />
          <path
            d="M306.4,442c-4.4.1-4.4-6.8,0-6.7S310.7,442.1,306.4,442Zm0-5.1c-2.3,0-2.3,3.6,0,3.5S308.7,436.9,306.4,436.9Z"
            transform="translate(-294.7 -421.4)"
            fill="currentColor"
          />
        </g>
        <g>
          <path
            d="M298.6,442c-3.6,0-3.1-5.7.5-5S301.5,442.1,298.6,442Zm0-3.8c-1.8-.1-1.7,2.7,0,2.6S300.3,438.1,298.6,438.2Z"
            transform="translate(-294.7 -421.4)"
            fill="none"
          />
          <path
            d="M298.6,442c-3.3,0-3.3-5.1,0-5S301.8,442,298.6,442Zm0-3.8a1.3,1.3,0,1,0,0,2.6C300.3,440.8,300.3,438.1,298.6,438.2Z"
            transform="translate(-294.7 -421.4)"
            fill="currentColor"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default CombineHarvesterIcon;
