import {
  getUnitLabelLocalStorage,
  getUnitValueLocalStorage,
} from 'libraries/unitConversion';
import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { AnalysisConfig } from '../types/Analyses';

const gapLineDetection: AnalysisConfig = {
  id: 26,
  name: 'gapLineDetection',
  displayName: 'Gap Line Detection',
  inAPI: 'Gap Line Detection',
  type: 'analysis',
  clouds: true,
  url: 'analysis-gap_line',
  config: analysisBuilder()
    .addUnits(getUnitLabelLocalStorage({ unitId: 'meters' }))
    .addColourBinList([
      { min: -1, max: 1, colour: '#fff' },
      { min: 1, max: 1.5, colour: '#00FF00' },
      { min: 1.5, max: 2.5, colour: '#0000FF' },
      { min: 2.5, max: 100000000000, colour: '#FF0000' },
    ])
    .addTooltipConverter((value: number) =>
      getUnitValueLocalStorage({
        unitId: 'meters',
        value,
      }),
    )
    .build(),
};

export default gapLineDetection;
