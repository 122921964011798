import config from 'config';
import { stringify } from 'qs';
import request from 'services/api/ApiClient';
import contourConfig from 'constants/contourConfig';

export function getApplicationClient(
  applicationKey,
  fieldSurveyId,
  unitSystem,
  farmId,
) {
  const requestData = {
    baseURL:
      applicationKey === 'nitrogenMeanSizeApplication'
        ? config.gqlGatewayUrl
        : config.apiUrl,
    method: 'GET',
    requestName: `GET_${applicationKey}_APPLICATION`,
    url: contourConfig[applicationKey].apiEndPoint(fieldSurveyId, farmId),
    params: {
      unit_system: unitSystem || null,
    },
    paramsSerializer: params =>
      stringify(params, {
        arrayFormat: 'indices',
        skipNulls: true,
        encode: false,
      }),
  };
  return request(requestData);
}

export function saveApplicationClient(
  applicationKey,
  fieldSurveyId,
  application,
) {
  const requestData = {
    baseURL: config.apiUrl,
    data: application,
    method: 'PUT',
    requestName: `PUT_${applicationKey}_APPLICATION`,
    url: contourConfig[applicationKey].apiEndPoint(fieldSurveyId),
  };

  return request(requestData);
}

export function updateApplicationClient(
  applicationKey,
  fieldSurveyId,
  application,
) {
  const requestData = {
    baseURL: config.apiUrl,
    data: application,
    method: 'POST',
    requestName: `POST_${applicationKey}_APPLICATION`,
    url: contourConfig[applicationKey].apiEndPoint(fieldSurveyId),
  };

  return request(requestData);
}

// TODO: This client is not yet used, as the FileDownloader component uses a hardcoded client
// directly and is not reliant on a saga that would call this. This should be changed.
// export function getApplicationShapefileClient(
//   applicationKey,
//   fieldSurveyId,
//   shapefileId,
// ) {
//   const requestData = {
//     baseURL: config.apiUrl,
//     method: 'GET',
//     requestName: `GET_${applicationKey}_SHAPEFILE`,
//     responseType: 'blob',
//     url: endpoints.applications[applicationKey].getShapefile(
//       fieldSurveyId,
//       shapefileId,
//     ),
//   };

//   return request(requestData);
// }

export default getApplicationClient;
