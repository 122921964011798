import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

type SvgProps = {
  width: string;
  height: string;
  viewBox: string;
  fill: string;
  fillRule: string;
};

const svgProps: SvgProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: '#000000',
  fillRule: 'evenodd',
};

function SurveySelectorCloseIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} {...svgProps}>
      <path d="M17.4 8.143L13.543 12l3.857 3.857c.567.568.825 1.232.257 1.8-.568.568-1.232.31-1.8-.257L12 13.543 8.143 17.4c-.568.568-1.232.825-1.8.257-.568-.568-.311-1.232.257-1.8L10.457 12 6.6 8.143c-.567-.568-.825-1.232-.257-1.8.568-.568 1.232-.31 1.8.257L12 10.457 15.857 6.6c.568-.567 1.232-.825 1.8-.257.568.568.31 1.232-.257 1.8" />
    </SvgIcon>
  );
}

export default SurveySelectorCloseIcon;
