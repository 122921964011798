import { round } from 'lodash-es';
import { getUnitLabelLocalStorage } from 'libraries/unitConversion';

const defaultYAxisConfig = {
  gridLines: {
    zeroLineColor: 'rgba(122, 133, 135, 1)',
    color: 'rgba(216, 233, 237, 1)',
  },
  ticks: {
    display: false,
  },
};

const defaultXAxisConfig = {
  gridLines: {
    color: 'rgba(216, 233, 237, 1)',
    zeroLineColor: 'rgba(216, 233, 237, 1)',
    display: false,
  },
  ticks: {
    stepSize: 0.1,
    maxTicksLimit: 10,
    callback: (value, index, allValues) => round(index / allValues.length, 1),
  },
};

const defaultOptions = {
  events: [],
  legend: {
    display: false,
  },
  scales: {
    yAxes: [defaultYAxisConfig],
    xAxes: [defaultXAxisConfig],
  },
};

export function getDistributionHistogramDefaultOptions(units, formatter) {
  return {
    ...defaultOptions,
    events: ['mousemove', 'mouseout', 'touchstart', 'touchmove', 'touchend'],
    scales: {
      ...defaultOptions.scales,
      yAxes: [
        {
          ...defaultYAxisConfig,
          ticks: {
            display: true,
            maxTicksLimit: 2,
          },
          scaleLabel: {
            display: true,
            labelString: getUnitLabelLocalStorage({ unitId: 'hectares' }),
            padding: 0,
          },
        },
      ],
      xAxes: [
        {
          ...defaultXAxisConfig,
          ticks: {
            barPercentage: 0.95,
            categoryPercentage: 1,
            display: false,
          },
        },
      ],
    },
    units,
    tooltips: {
      enable: true,
      displayColors: false,
      callbacks: {
        title: () => '',
        label: (item, data) =>
          formatter
            ? formatter(item, data)
            : `${round(item.yLabel, 2)} ${getUnitLabelLocalStorage({
                unitId: 'hectares',
              })}`,
      },
    },
  };
}

export default function generateBarChartOptions(histogramTickType) {
  let chartOptions = defaultOptions;

  if (histogramTickType === 'percentage') {
    chartOptions = {
      ...defaultOptions,
      scales: {
        yAxes: [defaultYAxisConfig],
        xAxes: [
          {
            ...defaultXAxisConfig,
            ticks: {
              barPercentage: 2.0,
              categoryPercentage: 1.0,
              stepSize: 0.1,
              maxTicksLimit: 10,
              callback: (value, index) => round(index, 1),
            },
          },
        ],
      },
    };
  }

  if (histogramTickType === 'decimal') {
    chartOptions = {
      ...defaultOptions,
      scales: {
        yAxes: [defaultYAxisConfig],
        xAxes: [
          {
            gridLines: {
              color: 'rgba(216, 233, 237, 1)',
              zeroLineColor: 'rgba(216, 233, 237, 1)',
              display: false,
            },
            ticks: {
              barPercentage: 2.0,
              categoryPercentage: 1.0,
              stepSize: 0.1,
              maxTicksLimit: 10,
              callback: (_, index) => index / 100,
            },
          },
        ],
      },
    };
  }

  return chartOptions;
}
