import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function AnalysisIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path
          d="M304.8,427.7c-.2.2-.6.2-.7,0l-4.3-4.3-.3-.3c-5.5,4.3-12.3-3.1-7.5-8.1s12.5,2,8.2,7.4l.3.3,4.3,4.3A.5.5,0,0,1,304.8,427.7Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M299.5,422.5h0a5,5,0,0,0-7.1-7.1c-4.5,4.9,2.1,11.4,7.1,7.1m5.6,5.6a1,1,0,0,1-1.4,0l-4.3-4.3c-6,4.1-12.8-3.9-7.7-9.1s13.2,1.7,9.1,7.7l4.3,4.3A1,1,0,0,1,305.1,428.1Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M304.9,411.9a1.5,1.5,0,1,1,0,3A1.5,1.5,0,1,1,304.9,411.9Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M304.9,412.4a1,1,0,0,1,0,2A1,1,0,0,1,304.9,412.4Z"
          transform="translate(-285.4 -409.3)"
          stroke="currentColor"
          fill="none"
        />
      </g>
      <g>
        <path
          d="M296.9,416.3c3.3.1,3.3,5,0,5S293.6,416.4,296.9,416.3Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M296.9,416.8c2.6.1,2.6,4,0,4S294.3,416.9,296.9,416.8Z"
          transform="translate(-285.4 -409.3)"
          stroke="currentColor"
          fill="none"
        />
      </g>
      <g>
        <path
          d="M287.9,419.9a1.5,1.5,0,1,1,0,3A1.5,1.5,0,1,1,287.9,419.9Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M287.9,420.4a1,1,0,0,1,0,2A1,1,0,0,1,287.9,420.4Z"
          transform="translate(-285.4 -409.3)"
          stroke="currentColor"
          fill="none"
        />
      </g>
      <line
        x1="4.5"
        y1="11.5"
        x2="4.5"
        y2="11.5"
        stroke="currentColor"
        fill="none"
      />
      <path
        d="M289.1,421l1.3-.4"
        transform="translate(-285.4 -409.3)"
        stroke="currentColor"
        fill="none"
      />
      <path
        d="M300.8,415.7l2.8-1.7"
        transform="translate(-285.4 -409.3)"
        stroke="currentColor"
        fill="none"
      />
      <path
        d="M291,421.5l3.9-1.8"
        transform="translate(-285.4 -409.3)"
        fill="none"
        stroke="currentColor"
        strokeWidth="2px"
      />
      <path
        d="M298.8,417.7l2-1.1"
        transform="translate(-285.4 -409.3)"
        fill="none"
        stroke="currentColor"
        strokeWidth="2px"
      />
    </SvgIcon>
  );
}

export default AnalysisIcon;
