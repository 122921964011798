import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

interface Props extends SvgIconProps {
  invert?: boolean;
  /** stroke color of the plus when color is inverted. Defaults to white */
  stroke?: string;
}

function PlusRoundIcon({
  invert = false,
  stroke = 'white',
  ...rest
}: Props): ReactElement {
  return (
    <SvgIcon {...rest} viewBox="0 0 24 24">
      <g>
        <path
          d="M297.4,409.3a12,12,0,0,1,12,12c-.6,15.9-23.5,15.9-24,0A12,12,0,0,1,297.4,409.3Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M297.4,409.8a11.6,11.6,0,0,1,11.5,11.5c-.6,15.3-22.5,15.3-23,0A11.5,11.5,0,0,1,297.4,409.8Z"
          transform="translate(-285.4 -409.3)"
          fill={invert ? 'currentColor' : 'none'}
          stroke={invert ? stroke : 'currentColor'}
        />
      </g>
      <line
        x1="5.6"
        y1="12"
        x2="18.5"
        y2="12"
        fill="none"
        stroke={invert ? stroke : 'currentColor'}
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <line
        x1="12.1"
        y1="18.5"
        x2="12.1"
        y2="5.6"
        fill="none"
        stroke={invert ? stroke : 'currentColor'}
        strokeLinecap="round"
        strokeWidth="2px"
      />
    </SvgIcon>
  );
}

export default PlusRoundIcon;
