import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function TractorBigIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 176 176">
      <g fill="currentColor">
        <path d="M79.21 88.35a29.94 29.94 0 106.43 9.54 29.91 29.91 0 00-6.43-9.54zM58 123.56a14 14 0 119.9-4.1 13.88 13.88 0 01-9.9 4.1z" />
        <path d="M148.15 99.9l-5.24-7.13V75.93l-14.28-1.57V47.44h-1s-1-5.79-5.23-8.54c-3.51-2.27-9.24-2.46-11.14-2.46h-.63v6.47a51.44 51.44 0 018.37 1.43c1.82.49 1.7 3.1 1.7 3.1h-1.07v25.93l-22.19-2.43L86.06 37.5h-45.9l-1.53 35.06-10.78 6.9v5.74l3 .85a51 51 0 018.29-5.72 48.87 48.87 0 019-3.93 36.16 36.16 0 0110.91-1.78 29.21 29.21 0 0110.82 2A30 30 0 0181.57 85 38 38 0 0188 95.61a45.88 45.88 0 013.28 13.08h4.09a27.7 27.7 0 00-.36 4.37 26.5 26.5 0 1050.92-10.31c-.06-.15-.14-.28-.21-.43a28.41 28.41 0 012.44 4.65zM66.63 66.44h-20v-25h20zm4 4v-29h10.86l9.14 29zm21 15v-6h28v6zm37.29 35.05a10.49 10.49 0 113.08-7.43 10.43 10.43 0 01-3.08 7.43zm9.71-35h-10v-6h10z" />
      </g>
    </SvgIcon>
  );
}

export default TractorBigIcon;
