import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { SurveySourceType } from 'types/surveySourceType';
import { ApplicationConfig } from './ApplicationConfig';

const fungicideApplication: ApplicationConfig = {
  id: 28,
  name: 'fungicideApplication',
  displayName: 'Fungicide (Contoured)',
  inAPI: 'Fungicide Application / Gridded NDVI',
  type: 'application',
  apiEndPoint: fieldSurveyId =>
    `/v1.0/hb-application/field-survey/${fieldSurveyId}/application-fungicide/analysis-gridded_ndvi`,
  url: 'application-fungicide/analysis-gridded_ndvi',
  base: 'cropHealth',
  surveySources: {
    [SurveySourceType.UAV]: true,
    [SurveySourceType.SATELLITE]: true,
  },
  config: analysisBuilder()
    .addUnits('unit.kilogramsPerHectare')
    .addBarStep(1)
    .addDefaultRange({
      lower: 0,
      upper: 120,
    })
    .addColourList(['#C6FCF5', '#51FFE8', '#00CEB3', '#01A38D', '#00564A'])
    .build(),
};

export default fungicideApplication;
