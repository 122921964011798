import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function EditPencilIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path
          d="M304.1,411.9c.5.7,3.1,2.2,2,3.3h0l-11.8,12.6h0l-3.3-3.1h0L302.9,412A.8.8,0,0,1,304.1,411.9Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M303.8,412.4l1.9,1.7a.5.5,0,0,1,0,.7l-11,11.9c-.5.7-2.1-1.4-2.6-1.7a.5.5,0,0,1,0-.7l11-11.9A.5.5,0,0,1,303.8,412.4Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <g>
        <path
          d="M292.1,424.5l2.3,2.2-4.3,2.4Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M291.1,427.9l2.5-1.3-1.3-1.3-1.2,2.6m-2.1,2.3,3-6.5,3.2,3.1Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default EditPencilIcon;
