import React from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function ChevronLeftIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        d="M17 .7L5.5 12.2 17 23.7"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export default ChevronLeftIcon;
