import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useUser } from '@hummingbirdtechgroup/wings-auth';

function useLocation() {
  const url = window.location.pathname + window.location.search;

  return url;
}

function getCompanyFromEmail(email) {
  if (!email) return null;
  const domains = email.split('@');
  const company = domains[1].split('.')[0];

  return company;
}

function GoogleAnalytics() {
  const [user] = useUser();
  const url = useLocation();

  useEffect(() => {
    if (user) {
      const company = getCompanyFromEmail(user.email);
      ReactGA.initialize('UA-159160386-1', {
        // set this to true when debuggin in the console.
        debug: false,
        gaOptions: {
          userId: user.id,
        },
      });
      ReactGA.set({
        dimension1: user.id,
        dimension2: user.role,
        dimension3: company,
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const company = getCompanyFromEmail(user.email);
      ReactGA.set({
        dimension1: user.id,
        dimension2: user.role,
        dimension3: company,
      });
      ReactGA.pageview(url);
    }
  }, [url, user]);

  return null;
}

export default GoogleAnalytics;
