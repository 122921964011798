import { defaultConfig, envConfigs, Config } from './config';

export type AppEnvironment = 'production' | 'staging' | 'demo' | 'internal';

const appEnvironment = process.env.API_ENV as AppEnvironment;

const config: Config = {
  ...defaultConfig,
  ...envConfigs[appEnvironment],
};

export default config;
