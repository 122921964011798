/* eslint-disable camelcase */
import React from 'react';
import { useUnitConversion } from '../unit-conversion-context';
import { UnitId, UnitContext } from '../unit-constants';

type Props = {
  unitContext?: UnitContext;
  unitId: UnitId;
  value: number;
  precision?: number;
};

function UnitConverter({
  unitContext = 'general',
  unitId,
  value,
  precision = 2,
}: Props): React.ReactElement | null {
  const { convertUnitValue } = useUnitConversion();

  return <>{convertUnitValue({ unitId, value, precision, unitContext })}</>;
}

export default UnitConverter;
