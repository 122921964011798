import { isUndefined } from 'lodash-es';
import { getToken } from '@hummingbirdtechgroup/wings-auth';

function authInterceptorFactory() {
  return config => {
    const authToken = getToken();
    if (isUndefined(authToken)) {
      return config;
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${authToken}`,
      },
    };
  };
}

export default authInterceptorFactory;
