import React from 'react';
import { SvgIcon } from '../../components';

function ChevronDownIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        d="M.7 7l11.5 11.5L23.7 7"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export default ChevronDownIcon;
