const radioStyles = {
  '.Radio': {
    display: 'inline-block',
    cursor: 'pointer',
    paddingTop: '5px',
    paddingBottom: '4px',
  },
  '.Radio input': {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    padding: '0',
    border: '0',
  },
  '.Radio input + span': {
    display: 'inline-block',
    content: "''",
    backgroundColor: 'var(--c-island-bg)',
    border: '1px solid var(--hb-blue)',
    borderRadius: '50%',
    height: '15px',
    width: '15px',
  },
  '.Radio input:checked + span': {
    backgroundSize: '60%',
    backgroundImage: "url('../../../../assets/images/icons/tick.svg')",
    backgroundColor: 'var(--hb-blue)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  '.Radio label': {
    position: 'relative',
    top: '-2px',
    marginLeft: '8px',
    cursor: 'pointer',
  },
  '.Radio input:checked ~ label': { fontWeight: 'bold' },
  '.Radio input:disabled + span': {
    borderColor: 'var(--c-tint)',
    backgroundColor: 'transparent',
    cursor: 'not-allowed',
  },
  '.Radio__content': {
    position: 'relative',
    paddingBottom: '7px',
    paddingLeft: '16px',
    marginBottom: '5px',
  },
  '.Radio__edge': {
    position: 'absolute',
    left: '6px',
    top: '-11px',
    height: 'calc(100% + 6px)',
    width: '2px',
    background: 'var(--hb-blue)',
  },
  '.Radio__edge:after': {
    position: 'absolute',
    content: "''",
    left: '-3px',
    bottom: '0',
    height: '2px',
    width: '8px',
    background: 'var(--hb-blue)',
  },
};

export default radioStyles;
