import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { SurveySourceType } from 'types/surveySourceType';
import { ApplicationConfig } from './ApplicationConfig';

const pgrFromGaiApplication: ApplicationConfig = {
  id: 31,
  name: 'pgrFromGaiApplication',
  displayName: 'PGR (GAI) (Contoured)',
  inAPI: 'PGR Application / Green Area Index',
  type: 'application',
  apiEndPoint: fieldSurveyId =>
    `/v1.0/hb-application/field-survey/${fieldSurveyId}/application-pgr/analysis-green_area_index`,
  url: 'application-pgr/analysis-green_area_index',
  base: 'gai',
  surveySources: {
    [SurveySourceType.UAV]: true,
    [SurveySourceType.SATELLITE]: true,
  },
  config: analysisBuilder()
    .addUnits('unit.litersPerHectare')
    .addBarStep(1)
    .addDefaultRange({
      lower: 0,
      upper: 120,
    })
    .addColourList(['#FCCACA', '#CCAB91', '#8F906C', '#4F745C', '#095556'])
    .build(),
};

export default pgrFromGaiApplication;
