import { defaultTheme } from 'libraries/wings-ui/styles';

const { palette } = defaultTheme;

const coloursStyles = {
  ':root': {
    '--hb-green': '#00ceb3',
    '--hb-blue': palette.secondary.main,
    '--hb-darkblue': palette.secondary.dark,
    '--hb-gray': palette.grey.main,
    '--hb-light-grey': palette.grey.light,
    '--hb-creamy-blue': palette.secondary.light,
    '--hb-off-black': palette.grey.offBlack,
    '--hb-red': palette.error.main,
    '--c-success': palette.success.main,
    '--c-negative': palette.error.main,
    '--c-info': palette.info.main,
    '--c-warning': palette.warning.main,
    '--c-selected': palette.action.selected,
    '--c-crop-scale-0': '#b31c08',
    '--c-crop-scale-1': '#1fb308',
    '--c-body-bg': '#2093ce',
    '--c-content-bg': 'white',
    '--c-highlight-bg': '#f2fcfd',
    '--c-island-bg': 'white',
    '--c-input-border': '#dddddd',
    '--c-text-light': 'white',
    '--c-text-main': palette.text.main,
    '--c-text-secondary': palette.text.secondary,
    '--c-text-minor': palette.text.minor,
    '--c-text-tint': palette.text.tint,
    '--c-tint': '#7a8587',
    '--c-tint-light': palette.text.light,
    '--c-tint-lighter': palette.text.lighter,
    '--c-tint-lightest': palette.text.lighterest,
    '--hb-transparent-black': palette.grey.transparentBlack,
  },
};

export default coloursStyles;
