import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import {
  getUnitValueLocalStorage,
  getUnitLabelLocalStorage,
} from 'libraries/unitConversion';
import { AnalysisConfig } from '../types/Analyses';

const plantPopulation: AnalysisConfig = {
  id: 8,
  name: 'plantPopulation',
  displayName: 'Plant Population',
  inAPI: 'Plant Population',
  type: 'analysis',
  clouds: true,
  url: 'analysis-plant_population',
  config: analysisBuilder()
    .addUnits(getUnitLabelLocalStorage({ unitId: 'plants-per-hectare' }))
    .addBarStep(1000)
    .addDefaultRange({
      lower: 0,
      upper: 10000,
    })
    .addTooltipScale(1000)
    .addColourList([
      '#ae2e2e',
      '#c37d53',
      '#c9b483',
      '#b2bf86',
      '#79a65c',
      '#2f8146',
    ])
    .addTooltipConverter((value: number) =>
      getUnitValueLocalStorage({
        unitId: 'plants-per-hectare',
        value,
        precision: 0,
      }),
    )
    .build(),
};

export default plantPopulation;
