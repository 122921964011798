export enum EventCategory {
  ANALYTICS = 'Analytics',
  APPLICATIONS = 'Applications',
  ANALYSES = 'Analyses',
  BASE_MAP = 'Base map',
  DRAWING_PANEL = 'Drawing panel',
  FIELDS = 'Fields',
  FIELDS_MAP = 'Fields map',
  GROWING_SEASONS = 'Growing Seasons',
  NAVIGATION = 'Navigation',
}
