import React, { useReducer, createContext, useContext, useEffect } from 'react';
import fetchUnitLabel from './utils/get-unit-label';
import getUnitValue from './utils/get-unit-value';
import {
  Language,
  UnitSystem,
  UnitId,
  UnitContext,
  EN,
} from './unit-constants';
import conversionTables from './conversion-tables';

type GetUnitLabelArgs = {
  unitId: UnitId;
  unitContext?: UnitContext | undefined;
};

type ConvertUnitValueArgs = {
  value: number;
  unitId: UnitId;
  systemFrom?: UnitSystem;
  systemTo?: UnitSystem;
  precision?: number;
  unitContext?: UnitContext;
};

type State = {
  language: Language;
  unitSystem: UnitSystem;
  getUnitLabel: (args: GetUnitLabelArgs) => string;
  convertUnitValue: (args: ConvertUnitValueArgs) => number;
};

type Action = {
  type: 'UPDATE_SYSTEM';
  payload: { language: Language; unitSystem: UnitSystem };
};

type Dispatch = (action: Action) => void;

const UnitConversionContext = createContext<State | undefined>(undefined);
UnitConversionContext.displayName = 'UnitConversionContext';
const UnitDispatchConversionContext = createContext<Dispatch | undefined>(
  undefined,
);
UnitDispatchConversionContext.displayName = 'UnitDispatchConversionContext';

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'UPDATE_SYSTEM':
      return {
        ...state,
        language: action.payload?.language || state.language || 'en-UK',
        // Use the default system if the payload is undefined
        unitSystem: action.payload?.unitSystem || state.unitSystem || 'metric',
      };

    default:
      return state;
  }
};

const defaultState: State = {
  language: EN,
  unitSystem: 'metric',
  getUnitLabel: () => '',
  convertUnitValue: () => 0,
};

type UnitConversionProviderProps = {
  language: Language;
  unitSystem: UnitSystem;
  defaultSystem: { language: Language; unitSystem: UnitSystem };
  children: React.ReactNode;
};

/**
 * Provides the unit system and conversion methods
 */
function UnitConversionProvider({
  language,
  unitSystem,
  defaultSystem,
  children,
}: UnitConversionProviderProps): React.ReactElement {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    ...defaultSystem,
  });

  // NOTE: using local storage is not a great solution. We should look to find a better one going forward
  useEffect(() => {
    const UnitSystemStorage = JSON.stringify({
      language: language || defaultSystem?.language || 'en-UK',
      system: unitSystem || defaultSystem?.unitSystem || 'metric',
    });

    localStorage.setItem('unitSystem', UnitSystemStorage);
  }, [language, unitSystem, defaultSystem]);

  // Setup system if language or unitSytem props are provided
  useEffect(() => {
    if (language) {
      dispatch({
        type: 'UPDATE_SYSTEM',
        payload: { language, unitSystem },
      });
    }
  }, [language, unitSystem, defaultSystem]);

  const getUnitLabel = (args: GetUnitLabelArgs) =>
    fetchUnitLabel(
      state.language,
      args.unitContext,
      args.unitId,
      state.unitSystem,
      conversionTables,
    );

  const convertUnitValue = (args: ConvertUnitValueArgs) =>
    getUnitValue(
      args.value,
      args.unitId,
      args.systemFrom || 'metric',
      args.systemTo || state.unitSystem,
      conversionTables,
      args.precision,
      args.unitContext,
    );

  return (
    <UnitConversionContext.Provider
      value={{
        ...state,
        getUnitLabel,
        convertUnitValue,
      }}
    >
      <UnitDispatchConversionContext.Provider value={dispatch}>
        {children}
      </UnitDispatchConversionContext.Provider>
    </UnitConversionContext.Provider>
  );
}

/**
 * For updating the unit system state
 */
export function useUnitConversionDispatch(): Dispatch {
  const context = useContext(UnitDispatchConversionContext);

  if (!context)
    throw new Error(
      'useUnitConversionDispatch must be used within a UnitConversionProvider',
    );

  return context;
}

/**
 * To access the unit system state and conversion methods
 */
export function useUnitConversion(): State {
  const context = useContext(UnitConversionContext);

  if (!context)
    throw new Error(
      'useUnitConversion must be used within a UnitConversionProvider',
    );

  return context;
}

export function withUnitConversion(WrappedComponent: any) {
  return function WithUnitConversion(props: unknown): React.ReactElement {
    return (
      <UnitConversionContext.Consumer>
        {unitConversion => (
          <WrappedComponent unitConversion={unitConversion} {...props} />
        )}
      </UnitConversionContext.Consumer>
    );
  };
}

export default UnitConversionProvider;
