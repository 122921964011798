import * as actionTypes from './actionTypes.js';

export const onsiteJohnDeereLogIn = () => ({
  type: actionTypes.ONSITE_JD_AUTH,
});

export const onsiteJohnDeereReceiveURL = authUrl => ({
  type: actionTypes.ONSITE_JD_RECEIVE_URL,
  authUrl,
});

export const onsiteJohnDeereRequestNodes = () => ({
  type: actionTypes.ONSITE_JD_REQUEST_NODES,
});

export const onsiteJohnDeereReceiveNodes = jdNodes => ({
  type: actionTypes.ONSITE_JD_RECEIVE_NODES,
  jdNodes,
});

export const onsiteJohnDeereSelectNode = (
  fieldSurveyId,
  applicationType,
  nodeId,
  order,
  reporter,
) => ({
  type: actionTypes.ONSITE_JD_SELECT_NODE,
  fieldSurveyId,
  applicationType,
  nodeId,
  order,
  reporter,
});

export const onsiteJohnDeereTransferSuccess = () => ({
  type: actionTypes.ONSITE_JD_TRANSFER_SUCCESS,
});

export const onsiteFinishProcess = () => ({
  type: actionTypes.ONSITE_FINISH_PROCESS,
});

export const onsiteRequestConversion = (
  fieldSurveyId,
  applicationId,
  format,
  order,
  reporter,
) => ({
  type: actionTypes.ONSITE_REQUEST_CONVERSION,
  fieldSurveyId,
  applicationId,
  format,
  order,
  reporter,
});

export const onsiteConversionError = conversionError => ({
  type: actionTypes.ONSITE_CONVERSION_ERROR,
  conversionError,
});

export const onsiteGetEquipmentList = () => ({
  type: actionTypes.ONSITE_EQUIPMENT_LIST_REQUEST,
});

export const onsiteEquipmentList = equipmentList => ({
  type: actionTypes.ONSITE_EQUIPMENT_LIST_RECIEVED,
  equipmentList,
});
