import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { makeStyles } from 'libraries/wings-ui/styles';

type StyleProps = {
  withMargin?: boolean;
};

const useStyles = makeStyles(({ spacing, palette }: any) => ({
  toggleRoot: {
    '&[type=checkbox]': {
      boxSizing: 'content-box',
    },
    '&::-webkit-appearance, -moz-appearance': 'none',
    appearance: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: spacing(2.25),
    width: spacing(4),
    outline: 'none',
    margin: ({ withMargin }: StyleProps) => withMargin && spacing(1),
    border: `1px solid ${palette.secondary.main}`,
    borderRadius: spacing(2),
    backgroundColor: palette.background.default,
    cursor: 'pointer',
    transition: 'all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
    '&:after': {
      position: 'absolute',
      content: '""',
      display: 'block',
      left: 1,
      top: 1,
      borderRadius: '50%',
      backgroundColor: palette.secondary.main,
      height: spacing(2),
      width: spacing(2),
      transition:
        'all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),  padding 0.3s ease, margin 0.3s ease',
    },
    '&:checked:after': {
      backgroundColor: palette.secondary.contrastText,
      left: 15,
    },
    '&:checked': {
      backgroundColor: palette.secondary.main,
    },
    '&:disabled': {
      border: `1px solid ${palette.secondary.main}`,
      backgroundColor: palette.secondary.light,
      cursor: 'default',
    },
  },
}));

interface ToggleProps {
  checked: boolean;
  onChange: () => void;
  /**
   * Targets the toggles input element
   */
  className?: string;
  disabled?: boolean;
  /**
   * Adds an 8px margin around the toggle component
   */
  withMargin?: boolean;
  id?: string;
}

function Toggle({
  className,
  checked,
  disabled,
  onChange,
  withMargin,
  id,
}: ToggleProps): ReactElement {
  const classes = useStyles({ withMargin });
  return (
    <input
      id={id}
      onChange={onChange}
      type="checkbox"
      checked={checked}
      disabled={disabled}
      className={classNames(className, classes.toggleRoot)}
    />
  );
}

export default Toggle;
