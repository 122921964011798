/**
 * A simple utility borrowed from the Material-ui style package
 * https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/utils/capitalize.js
 */

export default function capitalize(string: string): string {
  if (typeof string !== 'string') {
    throw new Error(
      'StyleUtililityError: capitalize(string) expects a string argument.',
    );
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}
