import { fork, all } from 'redux-saga/effects';

import { applicationSagas } from 'modules/FieldsMap/partials/Applications/reduxState/saga';

import {
  onsiteJohnDeereAuthSaga,
  onsiteJohnDeerePollNodesSaga,
  onsiteRequestConversionSaga,
  onsiteSelectNodeSaga,
  onsiteRequestEquipmentListSaga,
} from 'modules/FieldsMap/partials/partnerIntegrations/state/sagas';

export default function* rootSaga() {
  const mapsSagas = [
    onsiteJohnDeereAuthSaga,
    onsiteJohnDeerePollNodesSaga,
    onsiteSelectNodeSaga,
    onsiteRequestConversionSaga,
    onsiteRequestEquipmentListSaga,
  ];

  const allSagas = [...mapsSagas, ...applicationSagas];

  yield all(allSagas.map(saga => fork(saga)));
}
