import * as simplifiedApplicationContourConfig from 'modules/FieldsMap/partials/Applications/config';
import griddedApplicationContourConfig from 'modules/FieldsMap/partials/Applications/config/gridded';
import * as analysisConfigs from 'modules/FieldsMap/partials/Analyses/config';

const contourConfig = {
  ...griddedApplicationContourConfig,
  ...simplifiedApplicationContourConfig,
  ...analysisConfigs,
};

export default contourConfig;
