export const EN: Language = 'en-UK';
export const PT: Language = 'pt-BR';
export const RU: Language = 'ru-RU';
export const UK: Language = 'uk-UA';

export type Language = 'en-UK' | 'pt-BR' | 'ru-RU' | 'uk-UA';

export type UnitSystem = 'metric' | 'imperial_ca' | 'imperial_us';

export type UnitContext =
  | 'name'
  | 'general'
  | 'chemical-product-rate'
  | 'bsh-canola';

export type UnitId =
  | 'hectares'
  | 'meters-square'
  | 'centimeters-square'
  | 'litres'
  | 'meters'
  | 'kilometers'
  | 'meters-above-sea-level'
  | 'litres-per-hectare'
  | 'plants-per-hectare'
  | 'kilograms-per-hectare'
  | 'kilograms-nitrogen-per-hectare'
  | 'seeds-per-meter-square'
  | 'tons-per-hectare'
  | 'kilograms'
  | 'grams';

type UnitSet = {
  name: string | null;
  general: string;
} & any;

type Factors = { general: number } & any;
type ConversionFactors = Record<UnitId, Factors>;
type ConversionSet = {
  conversionFactors?: ConversionFactors;
} & Record<Language, UnitSet>;

export type ConversionTables = Record<UnitSystem, ConversionSet>;

export type UnitConstants = {
  LANGUAGES: Language[];
  UNIT_SYSTEMS: UnitSystem[];
  UNIT_CONTEXT: UnitContext[];
  UNIT_IDS: UnitId[];
};

const unitConstants: UnitConstants = {
  LANGUAGES: [EN, PT, RU, UK],
  UNIT_SYSTEMS: ['metric', 'imperial_ca', 'imperial_us'],
  UNIT_CONTEXT: ['name', 'general', 'chemical-product-rate', 'bsh-canola'],
  UNIT_IDS: [
    'hectares',
    'meters-square',
    'centimeters-square',
    'litres',
    'meters',
    'kilometers',
    'meters-above-sea-level',
    'litres-per-hectare',
    'plants-per-hectare',
    'kilograms-per-hectare',
    'kilograms-nitrogen-per-hectare',
    'seeds-per-meter-square',
    'tons-per-hectare',
    'kilograms',
    'grams',
  ],
};

export default unitConstants;
