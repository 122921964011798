import React from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function FarmIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g fill="none">
        <path d="M.2 0h24v24H.2z" />
        <path d="M.7.5h23v23H.7z" />
      </g>
      <path
        d="M306 414.6a3.6 3.6 0 00-3.6 3.6h0v5.5l.9.3 3.9 4.3h-1.4v8h3.9v-18.1a3.6 3.6 0 00-3.6-3.6zm-9 7.4l-5.9 2.5-3.2 3.5h.9v8.4h5.5v-4.1h5.6v4.1h5.3V428h1l-3.3-3.6zm-1.5 2.8h3.1v2h-3.1zm-.9 7.8v3.5h2.3v-3.5zm2.6 0v3.5h2.4v-3.5z"
        transform="translate(-286.7 -413.6)"
        stroke="#1d1d1b"
        strokeMiterlimit="10"
        opacity=".99"
        fill="none"
      />
    </SvgIcon>
  );
}

export default FarmIcon;
