/**
 * Hooks makes use of the JS API from Intercom
 * Documentation founds here: https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#
 * Adding Custom attributes: https://www.intercom.com/help/en/articles/179-send-custom-user-attributes-to-intercom
 */

/* eslint-disable new-cap */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  updateIntercomMessenger,
  hideIntercomMessenger,
  shutdownIntercomMessenger,
} from './intercomControl';
import { initialiseIntercom } from './initialiseIntercom';
import { useTheme } from '../wings-ui/styles';

function getCompanyFromEmail(email) {
  if (!email) return null;
  const domains = email.split('@');
  const company = domains[1].split('.')[0];

  return company;
}

function useIntercomMessenger({ user }) {
  const { pathname } = useLocation();
  const theme = useTheme();
  useEffect(() => {
    initialiseIntercom({
      app_id: 'e475mi00',
      horizontal_padding: 40,
      vertical_padding: 20,
      background_color: theme.palette.secondary.dark,
      action_color: theme.palette.secondary.main,
    });

    return () => shutdownIntercomMessenger();
  }, []);

  useEffect(() => {
    if (!user) return;
    if (user.email) {
      updateIntercomMessenger({
        email: user.email,
        user_id: user.id,
        name: user.name.split(' ')?.[0],
        role: user.role,
        company: getCompanyFromEmail(user.email),
        alignment: 'right',
        hide_default_launcher: true,
      });
    }
    if (!user.email) {
      updateIntercomMessenger({
        alignment: 'right',
        hide_default_launcher: false,
      });
      hideIntercomMessenger();
    }
  }, [user]);

  useEffect(() => {
    updateIntercomMessenger({
      current_url: pathname,
    });
  }, [pathname]);

  return null;
}

export default useIntercomMessenger;
