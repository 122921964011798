function apiRequestReducer(state = {}, action = {}) {
  switch (action.type) {
    case 'API_REQUEST':
      return {
        ...state,
        [action.requestName]: {
          fetching: true,
          error: false,
        },
      };
    case 'API_REQUEST_SUCCESS':
      return {
        ...state,
        [action.requestName]: {
          fetching: false,
          error: false,
        },
      };
    case 'API_REQUEST_FAILURE':
      return {
        ...state,
        [action.requestName]: {
          fetching: false,
          error: true,
        },
      };
    default:
      return state;
  }
}

export default apiRequestReducer;
