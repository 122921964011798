import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function NotesIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path
          d="M304.6,410.3h2a1.1,1.1,0,0,1,1,1v15h-4v-15A1,1,0,0,1,304.6,410.3Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <rect
          x="18.7"
          y="1.5"
          width="3"
          height="15"
          rx="0.5"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <g>
        <path
          d="M304.3,425.9h2.6l-1.3,3.9Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M305.6,428.2l.6-1.8H305l.6,1.8m0,3.2-2-6h4Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <g>
        <rect x="2.2" y="2.2" width="14" height="20" rx="2" fill="none" />
        <rect
          x="2.7"
          y="2.7"
          width="13"
          height="19"
          rx="1.5"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <line
        x1="4.7"
        y1="6.7"
        x2="13.7"
        y2="6.7"
        fill="none"
        stroke="currentColor"
      />
      <line
        x1="4.7"
        y1="8.7"
        x2="13.7"
        y2="8.7"
        fill="none"
        stroke="currentColor"
      />
      <line
        x1="4.7"
        y1="10.7"
        x2="13.7"
        y2="10.7"
        fill="none"
        stroke="currentColor"
      />
      <line
        x1="2"
        y1="5.7"
        x2="3.7"
        y2="5.7"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="2"
        y1="18.7"
        x2="3.7"
        y2="18.7"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export default NotesIcon;
