import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { SurveySourceType } from 'types/surveySourceType';
import { ApplicationConfig } from './ApplicationConfig';

const linearNDVIApplication: ApplicationConfig = {
  id: 33,
  name: 'linearNDVIApplication',
  displayName: 'Open Variable Rate Plan (Contoured)',
  inAPI: 'Linear Application / Gridded NDVI',
  type: 'application',
  apiEndPoint: fieldSurveyId =>
    `/v1.0/hb-application/field-survey/${fieldSurveyId}/application-linear/analysis-gridded_ndvi`,
  url: 'application-linear/analysis-gridded_ndvi',
  base: 'cropHealth',
  surveySources: {
    [SurveySourceType.UAV]: true,
    [SurveySourceType.SATELLITE]: true,
  },
  config: analysisBuilder()
    .addUnits('unit.litresPerHectare')
    .addBarStep(1)
    .addDefaultRange({
      lower: 0,
      upper: 120,
    })
    .addColourList(['#DDEDD2', '#D8C18C', '#E18868', '#D6497A', '#8E30AA'])
    .build(),
};

export default linearNDVIApplication;
