export enum EventAction {
  ADD_FIELDS = 'Add Fields',
  ADD_SOIL_TYPE = 'Add Soil Type',
  CHANGED_FARM = 'Changed Farm',
  CLICK_SURVEY_THUMBNAIL = 'Click Survey Thumbnail',
  COMPARE_FIELD_SURVEYS = 'Compare Field Surveys',
  COMPARE_FIELDS = 'Compare Fields',
  CREATE_GROWING_SEASON = 'Create a Growing Season',
  DELETE_GROWING_SEASON = 'Delete a Growing Season',
  DELETE_FIELD = 'Delete Field',
  DOWNLOAD_FILE = 'Download file',
  DOWNLOAD_KML = 'Download KML',
  DOWNLOADED_EQUIPMENT = 'Downloaded Equipment List',
  DOWNLOADED_SHAPEFILE = 'Downloaded generic shapefile',
  DOWNLOADED_APPLICATION_FILE = 'Download application file',
  DOWNLOADED_ANALYSES_FILE = 'Download analyses file',
  EDIT_GROWING_SEASON = 'Edit a Growing Season',
  EDIT_CROPS = 'Edit Crops',
  EDIT_FIELD = 'Edit Field',
  EDIT_ZONE = 'Edit Zone',
  FARM_PANEL_VISIBILITY = 'Farm Panel Visibility',
  FILTER_FIELDS = 'Filter Fields',
  LEADERBOARD_EXPORT = 'Leaderboard Export',
  LEADERBOARD_FILTER = 'Leaderboard Filter',
  MULTI_EDIT_CROPS = 'Multi-edit Crops',
  OPEN_FIELD_TAB = 'Open Field Tab',
  SAVE_FIELD_BOUNDARY = 'Save Field Boundary',
  SELECT_FIELD = 'Select Field',
  START_CREATE_APPLICATION = 'Start Create Application',
  SWITCH_MAP = 'Switch map',
  UPLOADED_SHAPEFILE = 'Uploaded shapefile',
  VIEW_GROWING_SEASONS = 'View Growing Seasons',
}
