/* eslint-disable new-cap */
import './migrate';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider } from 'react-query';
import { merge } from 'lodash-es';
import Progress from 'react-progress-2';
import {
  ConfigProvider,
  AppEnvironment,
  envConfigs,
} from '@hummingbirdtechgroup/wings-config';
import { UserProvider, useUser } from '@hummingbirdtechgroup/wings-auth';
import config from 'config';
import {
  normalizeStyles,
  tableResetStyles,
  coloursStyles,
  variablesStyles,
  mainStyles,
  typographyStyles,
  scrollbarStyles,
  cruddybrowserStyles,
  iconsStyles,
  listsStyles,
  pushStyles,
  messageStyles,
  loaderStyles,
} from 'constants/styles';
import { Router, browserHistory } from 'libraries/wings-router';
import {
  ThemeProvider,
  defaultTheme,
  makeStyles,
} from 'libraries/wings-ui/styles';
import { useLeafletStyles } from 'libraries/wings-geo/hooks';
import { Provider as ReduxProvider } from 'react-redux';
import { ConfiguredIntlProvider } from 'libraries/i18n';
import { GoogleAnalytics } from 'libraries/googleAnalytics';
import { googleMapsApiLoader } from 'libraries/wings-geo/utilities';
import { useIntercomMessenger } from 'libraries/intercomMessenger';
import { ModuleRouteMemoryProvider } from './appLibraries/routing';
import store from './reduxState/store';
import client from './gqlClient';
import queryClient from './queryClient';
import { SentryScope } from './appLibraries/sentry';
import AppRouter from './AppRouter';
import EnvironmentBanner from './components/EnvironmentBanner';

const useStyles = makeStyles(() => ({
  '@global': {
    ...merge(coloursStyles, variablesStyles),
    ...merge(
      normalizeStyles,
      tableResetStyles,
      mainStyles,
      typographyStyles,
      scrollbarStyles,
    ),
    ...cruddybrowserStyles,
    ...iconsStyles,
    ...listsStyles,
    ...pushStyles,
    ...messageStyles,
    ...loaderStyles,
  },
}));

/**
 * This is the very top level of our app.
 * Any thing that need to work globally should be registered in the App component
 * Any Providers that are needed by the entire app should be registered in AppWithProviders
 * Only AppWithProviders is exported to the root index.js
 *
 * If you need Providers that are module specific, please register them in the root component of that module
 */
function App() {
  const [user] = useUser();

  useIntercomMessenger({ user });
  useStyles();
  useLeafletStyles();

  // load the google maps api once a user is logged in
  React.useEffect(() => {
    if (config.googleMapsApiKey) {
      googleMapsApiLoader({
        apiKey: config.googleMapsApiKey,
        version: '3.43',
        id: 'google-maps-api',
      });
    }
  }, [user]);

  return (
    <>
      <Progress.Component />
      <EnvironmentBanner />
      <AppRouter />
      {/* Analytics and logging */}
      {process.env.NODE_ENV === 'production' && (
        <>
          <GoogleAnalytics />
          <SentryScope />
        </>
      )}
    </>
  );
}

/**
 * Only register globally required Providers.
 */
function AppWithProviders(): React.ReactElement {
  const apiEnv = process.env.API_ENV as AppEnvironment;
  const { gqlGatewayUrl, apiUrl } = envConfigs[apiEnv];

  return (
    <ConfigProvider env={apiEnv}>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={client(gqlGatewayUrl, apiUrl)}>
            <UserProvider>
              <ConfiguredIntlProvider>
                <Router history={browserHistory}>
                  <ModuleRouteMemoryProvider>
                    <ThemeProvider theme={defaultTheme}>
                      <App />
                      <ReactQueryDevtools initialIsOpen={false} />
                    </ThemeProvider>
                  </ModuleRouteMemoryProvider>
                </Router>
              </ConfiguredIntlProvider>
            </UserProvider>
          </ApolloProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </ConfigProvider>
  );
}

export default AppWithProviders;
