import React from 'react';
import type { GetHandleProps, SliderItem } from 'react-compound-slider';
import { makeStyles } from 'libraries/wings-ui/styles';

const useStyles = makeStyles(({ spacing, palette }: any) => ({
  handle: {
    position: 'absolute',
    marginLeft: '-8px',
    marginTop: '-5px',
    zIndex: 2,
    width: spacing(2),
    height: spacing(2),
    cursor: 'pointer',
    borderRadius: '50%',
    border: `solid 1px ${palette.background.default}`,
    background: palette.secondary.main,
  },
}));

type HandleProps = {
  domain: [number, number];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
};

export function Handle({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
}: HandleProps): React.ReactElement {
  const classes = useStyles();
  return (
    <div
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      className={classes.handle}
      style={{
        left: `${percent}%`,
      }}
      {...getHandleProps(id)}
    />
  );
}
