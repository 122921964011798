import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { AnalysisConfig } from '../types/Analyses';

const terrain: AnalysisConfig = {
  id: 9,
  name: 'terrain',
  displayName: 'Terrain',
  inAPI: 'Terrain',
  type: 'analysis',
  url: 'analysis-terrain',
  alwaysShown: true,
  config: analysisBuilder()
    .addUnits('unit.mASL')
    .addBarStep(1)
    .addDefaultRange({
      lower: 0,
      upper: 120,
    })
    .addColourList(['#4289f4', '#1eedaf', '#1eed66', '#eaf41f', '#f45b1e'])
    .build(),
};

export default terrain;
