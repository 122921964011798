import { stringify } from 'qs';
import config from 'config';

/*
 * This URL pointed to deployed Script on Google Cloud. Script connected with Google Sheet: https://docs.google.com/spreadsheets/d/18NiVeuzAEW18SrGLwZct9QSO89nYuaSINfhc1cDBTB4/edit#gid=2066639015,
 * and responsible for parsing URL params and look for column in Google Sheet: param name matches with column name to then write a value
 * Source: https://medium.com/@dmccoy/how-to-submit-an-html-form-to-google-sheets-without-google-forms-b833952cc175
 */

export default function (data: Record<string, any>) {
  const params = stringify(data);
  const url = `${config.downloadApplicationFileReportSheetUrl}?${params}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
