import React from 'react';
import { get } from 'lodash-es';
import {
  useUser,
  hasTokenExpired,
  withAuthData,
  LoginRedirect,
  LogoutRedirect,
} from '@hummingbirdtechgroup/wings-auth';
import { useDispatch } from 'react-redux';
import { SentryRoute } from 'libraries/wings-router';
import { selectLanguage } from 'libraries/i18n/reduxState/languageActionCreators';
import { hasPermission } from 'services/auth';

type Props = {
  path: string;
  exact?: boolean;
  feature?: string | null;
  children?: React.ReactNode;
  component?: React.ReactNode;
  userId?: string;
  lang?: string;
};

/**
 * Routing component to handle any authentication or protection for a route
 * Will be extended to be primary permission and role control on the app.
 */
function ProtectedRoute({
  children,
  component,
  path,
  exact,
  feature = null,
  userId,
  lang,
}: Props): React.ReactElement {
  const [user, fetchUser] = useUser();
  const dispatch = useDispatch();

  if (lang) {
    dispatch(selectLanguage(lang));
  }

  const requestingNewUser = userId && get(user, 'id', null) !== userId;
  if (userId && requestingNewUser) {
    fetchUser(userId);
    return <>Fetching user data</>;
  }

  if (hasTokenExpired()) {
    return <LoginRedirect />;
  }

  if (feature && user) {
    const isPermitted = hasPermission(feature, user.permissions);
    if (!isPermitted) return <LogoutRedirect />;
  }

  return (
    <SentryRoute exact={exact} path={path}>
      {children || component}
    </SentryRoute>
  );
}

export default withAuthData(ProtectedRoute);
