import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';

const analysis = analysisBuilder()
  .addUnits('unit.none')
  .addDefaultRange({
    lower: 0,
    upper: 1,
  })
  .addColourList(['#eaf41f', '#633016'])
  .disableTooltips()
  .build();

export default analysis;
