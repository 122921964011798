import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function ClearSkyIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 19.172 19.172">
      <g transform="translate(2250.035 59)">
        <g
          transform="translate(-2244.323 -53.307)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        >
          <circle cx="3.911" cy="3.911" r="3.911" stroke="none" />
          <circle cx="3.911" cy="3.911" r="3.411" fill="none" />
        </g>
        <line
          y2="4.172"
          transform="translate(-2236.762 -46.006) rotate(-45)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          y1="4.172"
          transform="translate(-2231.364 -49.396) rotate(90)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          y1="4.172"
          transform="translate(-2247.013 -43.056) rotate(-135)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          y2="4.172"
          transform="translate(-2245.364 -49.396) rotate(90)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          y1="4.172"
          transform="translate(-2240.412 -58.5)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          y1="4.172"
          transform="translate(-2240.412 -44.5)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          y1="4.172"
          transform="translate(-2233.812 -55.758) rotate(45)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          y2="4.172"
          transform="translate(-2244.063 -52.808) rotate(135)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </SvgIcon>
  );
}

export default ClearSkyIcon;
