import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function FolderReadonlyIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="-10 -10 70 70">
      <mask id="m1">
        <rect x="0" y="0" width="100" height="100" fill="white" />
        <path
          d="M10.38 3.6H29v24.83H10.38z"
          fill="black"
          transform="rotate(-10.01 19.713 15.994)"
        />
        <path
          d="M22.24 5.54h24.83v18.62H22.24z"
          fill="black"
          transform="rotate(-70.01 34.653 14.852)"
        />
      </mask>
      <mask id="m2">
        <rect x="0" y="0" width="100" height="100" fill="white" />
        <path
          d="M17.91 1.06h18.62v24.83H17.91z"
          fill="black"
          transform="rotate(-1.01 27.114 13.287)"
        />
      </mask>
      <mask id="m3">
        <rect x="0" y="0" width="100" height="100" fill="white" />
        <path
          d="M22.24 5.54h24.83v18.62H22.24z"
          fill="black"
          transform="rotate(-70.01 34.653 14.852)"
        />
      </mask>
      <mask id="m4">
        <rect x="0" y="0" width="100" height="100" fill="white" />
        <path
          fill="black"
          d="M24.86 42.19A24.87 24.87 0 0117.4 41a33.76 33.76 0 01-6.16-2.65 37.22 37.22 0 01-4.8-3.15 36.18 36.18 0 014.8-3.2 33.82 33.82 0 016.16-2.66 25.17 25.17 0 017.46-1.19 25 25 0 017.48 1.2 34.37 34.37 0 016.17 2.67 36.75 36.75 0 014.77 3.13 37.49 37.49 0 01-4.81 3.15 33.76 33.76 0 01-6.16 2.7 24.86 24.86 0 01-7.45 1.19z"
        />
      </mask>
      <mask id="m5">
        <rect x="0" y="0" width="100" height="100" fill="white" />
        <circle cx="22.36" cy="33.69" r="1.5" fill="black" />
      </mask>
      <g fill="currentColor">
        <path
          d="M45.75 21.22v-3.94h-26.5l-.5-.18-2.39-.88H4.75v5h41m3 3h-47v-11h15.14l2.89 1.06h29z"
          mask="url(#m1)"
        />
        <path d="M3.19 24.22l1.38 22H46l1.52-22H3.19m-3.19-3h50.69l-1.94 28h-47z" />
        <g mask="url(#m2)">
          <path d="M8.95 5.81l.08.49L25.9 3.33l3.96 22.48-16.37 2.89L9.44 5.72l-.49.09.08.49-.08-.49-.5.09 4.23 23.96 18.34-3.24-4.31-24.45L8.37 5.41l.08.49.5-.09z" />
          <path d="M12.27 9.64l12.22-2.16-.17-.98-12.23 2.15M12.7 12.09l12.22-2.16-.17-.99-12.23 2.16M13.13 14.53l12.23-2.16-.18-.98-12.22 2.15M13.56 16.98l12.23-2.16-.18-.99-12.22 2.16M13.99 19.42l12.23-2.16-.17-.98-12.23 2.16M14.42 21.87l12.23-2.16-.17-.99-12.23 2.16M14.85 24.31l12.23-2.15-.17-.99-12.23 2.16" />
        </g>
        <g mask="url(#m3)">
          <path d="M18.21 1.71v.5l17.12-.3.41 22.83-16.62.29-.41-23.33-.5.01v.5-.5l-.5.01.42 24.33 18.62-.33L36.32.89l-18.62.33.01.5.5-.01z" />
          <path d="M20.89 6.01l12.41-.22-.02-1-12.41.22M20.93 8.49l12.41-.21-.01-1-12.42.21M20.97 10.98l12.42-.22-.02-1-12.41.22M21.02 13.46l12.41-.22-.02-1-12.41.22M21.06 15.94l12.41-.22-.01-1-12.42.22M21.11 18.42l12.41-.22-.02-1-12.41.22M21.15 20.91l12.41-.22-.02-1-12.41.22" />
        </g>
        <path d="M30.44.64l-.17.47 16.09 5.85-7.8 21.46-15.62-5.68L30.91.81l-.47-.17-.17.47.17-.47-.47-.17-8.31 22.87 17.5 6.36 8.49-23.33L30.14 0l-.17.47.47.17z" />
        <path d="M31.41 5.62l11.66 4.24.34-.94-11.66-4.24M30.56 7.95l11.66 4.25.34-.94L30.9 7.01M29.71 10.28l11.67 4.25.34-.94-11.67-4.25M28.86 12.62l11.66 4.24.35-.94-11.67-4.24M28.01 14.95l11.67 4.25.34-.95-11.67-4.24M27.16 17.28l11.67 4.25.34-.94-11.67-4.25M26.31 19.62l11.67 4.24.34-.94-11.67-4.24" />
        <path d="M47.35 47.72H3.16l-1.56-25h47.48l-1.73 25z" mask="url(#m4)" />
        <path d="M24.86 29.19a23.93 23.93 0 00-7.13 1.14 32.54 32.54 0 00-6 2.56 36.62 36.62 0 00-3.66 2.3c3.06 2.18 9.46 6 16.75 6A24.36 24.36 0 0032 40.05a33.16 33.16 0 005.95-2.56 37.3 37.3 0 003.66-2.3c-3.06-2.19-9.45-6-16.74-6m0-2c11 0 20 8 20 8s-9 8-20 8-20-8-20-8 8.94-8 19.99-8z" />
        <circle cx="24.86" cy="35.19" r="5" mask="url(#m5)" />
      </g>
    </SvgIcon>
  );
}

export default FolderReadonlyIcon;
