import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '../../styles';
import { Typography } from './util';
import { ContentTag } from './util/Element';
import { Variant } from './util/Typography';

type Props = {
  children: React.ReactNode;
  htmlFor?: string;
  variant: Variant;
  className?: string;
  truncate?: boolean;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
}));

const Label = (props: Props) => {
  const classes = useStyles();
  return (
    <Typography
      {...props}
      className={classNames(classes.root, props.className)}
      type={ContentTag.LABEL}
    />
  );
};

export default Label;
