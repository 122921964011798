function previousApplicationEditorStateReducer(state = {}, action) {
  if (action.type === 'SAVE_APPLICATION_EDITOR_STATE') {
    return {
      ...state,
      [action.applicationKey]: {
        [action.fieldSurveyId]: action.state,
      },
    };
  }

  return state;
}

export default previousApplicationEditorStateReducer;
