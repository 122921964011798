import React, { useState } from 'react';
import { Button } from '@hummingbirdtechgroup/wings-ui';
import { makeStyles } from '../../styles';

const useStyles = makeStyles(({ palette, spacing }: any) => ({
  root: { position: 'relative' },
  menu: {
    minWidth: 150,
    margin: 0,
    background: palette.background.default,
    position: 'absolute',
    bottom: 43,
    left: 0,

    border: `1px solid ${palette.grey.lightTint}`,
    '& li + li': {
      borderTop: `1px solid ${palette.grey.lightTint}`,
    },
    animation: '$slide-up 300ms ease-out',
    zIndex: 1000,
  },

  item: {
    padding: spacing(0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = {
  children: React.ReactElement;
  items: React.ReactElement[];
  disabled?: boolean;
};

/*
* Example of menu item
*
* <IconButton
    icon={<UploadFileIcon />}
    label={<FormattedMessage id="field.uploadListBoundaries" />}
    onClick={goTo('upload')}
  />
* */

function DropdownMenu({
  children,
  items,
  disabled,
}: Props): React.ReactElement {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <div
      className={classes.root}
      onClick={(e: any) => e.target.tagName !== 'LI' && setOpen(!open)}
    >
      {open ? (
        <ul className={classes.menu}>
          {items.map(item => (
            <li className={classes.item}>{item}</li>
          ))}
        </ul>
      ) : null}
      <Button margin disabled={disabled}>
        {children}
        &nbsp;
        {open ? '▴' : '▾'}
      </Button>
    </div>
  );
}

export default DropdownMenu;
