import { AnalysisConfig } from '../types/Analyses';

// We are showing NDVI (Crop Health) instead Gridded Ndvi, cause Gridded Ndvi is not for 100% ready, but it based on NDVI
const griddedNdvi: AnalysisConfig = {
  id: 1,
  name: 'cropHealth',
  displayName: 'NDVI (Crop Health)',
  inAPI: 'Gridded NDVI',
  type: 'analysis',
  url: 'analysis-gridded_ndvi',
  clouds: true,
};

export default griddedNdvi;
