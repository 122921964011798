import React from 'react';

type Props = {
  className?: string;
};

function Logo({ className }: Props): React.ReactElement {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      viewBox="0 0 50 40"
    >
      <title>Hummingbird logo</title>
      <path fill="#7F6047" d="M47.197 8.396l2.662.434-1.848-1.143" />
      <path fill="#7C494C" d="M42.018 8.396h5.18l-6.63-1.418" />
      <path fill="#1181A8" d="M40.567 6.977l7.446.71-.816.708" />
      <path fill="#3EB649" d="M39.324 7.156l2.693 1.24-2.71 2.488" />
      <path fill="#A9D040" d="M37.227 3.733l.3 2.595 4.49 2.067" />
      <path fill="#403E3C" d="M37.526 6.29l1.782.866-1.582.865" />
      <path fill="#13A65F" d="M39.324 7.156l-.016 3.728-6.564-.206" />
      <path fill="#FCD539" d="M34.045 13.31l-4.773 7.68-3.776-4.462" />
      <path fill="#896D3A" d="M32.744 10.678l1.3 2.633 5.265-2.427" />
      <path fill="#804C51" d="M32.744 10.678l1.3 2.633-6.12 2.317" />
      <path fill="#5CBC51" d="M31.605 5.093l5.62-1.36.5 4.326" />
      <path fill="#19AA4B" d="M31.605 5.093l1.14 5.585-6.653.205" />
      <path fill="#79C253" d="M31.605 5.093l1.14 5.585 4.98-2.62" />
      <path fill="#DF9D49" d="M29.27 20.99l-3.24 3.9-4.06.99" />
      <path fill="#E5FF27" d="M27.994 8.886l-3.856-1.2 1.954 3.197" />
      <path fill="#13C6B0" d="M26.092 10.883L17.87 7.427l6.27.26" />
      <path fill="#19894C" d="M26.092 10.883l6.652-.206-4.82 4.95" />
      <path fill="#1880C3" d="M26.092 10.883L19.105 18.9l8.818-3.273" />
      <path fill="#1BA4A7" d="M26.092 10.883L19.105 18.9l-2.06-2.888" />
      <path fill="#FADB33" d="M25.496 16.528l3.776 4.46-7.3 4.892" />
      <path fill="#61C198" d="M25.496 16.528L15.802 28.15l3.303-9.25" />
      <path fill="#F8CD36" d="M25.496 16.528L21.97 25.88l-6.168 2.27" />
      <path fill="#403E3C" d="M21.97 25.88l1.8 1.036-2.928.47" />
      <path fill="#D87E13" d="M21.97 25.88l-6.388 8.525-3.236-.492" />
      <path fill="#199BB5" d="M21.97 25.88L9.63 32.987l.267 2.97" />
      <path fill="#1E5782" d="M21.97 25.88l-11.256 4.232-.156 2.34" />
      <path fill="#199BB5" d="M17.87 7.427l6.27.26-6.806-4.375" />
      <path fill="#2B98D4" d="M17.87 7.427l-.824 8.586-11.5-5.106" />
      <path fill="#1976A6" d="M17.87 7.427l-.536-4.115L7.02 5.225" />
      <path fill="#CD5E8F" d="M17.87 7.427l-.824 8.586 9.046-5.13" />
      <path fill="#3FBB86" d="M17.87 7.427l-12.323 3.48-4.9-4.725" />
      <path fill="#F4781F" d="M7.04 38.49l3.163.852 5.38-4.936" />
      <path fill="#F4781F" d="M7.04 38.49l5.306-4.577 3.236.492" />
      <path fill="#BC67CE" d="M4.476 2.71l2.542 2.515 10.315-1.913" />
      <path fill="#1693C4" d="M4.137.527l.34 2.183 12.857.6" />
      <path fill="#F47621" d="M.647 6.182L17.87 7.427.073 3.887" />
    </svg>
  );
}

export default Logo;
