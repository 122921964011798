import React, { Suspense } from 'react';
import { Route, SentryRoute, Switch, Redirect } from 'libraries/wings-router';
import { SuspenseFallback } from 'libraries/wings-ui/components';
import {
  LoginRedirect,
  LogoutRedirect,
} from '@hummingbirdtechgroup/wings-auth';
import ErrorBoundary from 'libraries/ErrorBoundary';
import { ProtectedRoute } from './appLibraries/routing';

/** =====================
 *  PRIMARY COMPONENTS
 * ====================== */

const AppLayoutLoggedIn = React.lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: 'AppLayoutLoggedIn' */
      './components/AppLayoutLoggedIn'
    ),
);
const FieldsMap = React.lazy(
  () =>
    import(
      /* webpackPreload: true */ /*  webpackChunkName: 'FieldsMap' */ '../modules/FieldsMap'
    ),
);
const Analytics = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: 'Analytics' */ '../modules/Analytics'
    ),
);
const Admin = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: 'Admin' */ '../modules/Admin'
    ),
);

const PrintRouter = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: 'Print' */ '../modules/Print'
    ),
);

/** ======================
 *  SECONDARY COMPONENTS
 * ======================= */

const NotAuthorized = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'NotAuthorized' */
      './components/NotAuthorized'
    ),
);

const FarmAtHandVerification = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'FarmAtHandVerification' */ './components/FarmAtHandVerification'
    ),
);

const NotFound = React.lazy(
  () => import(/* webpackChunkName: 'NotFound' */ './components/NotFound'),
);

function AppRouter(): React.ReactElement {
  return (
    <ErrorBoundary level="AppRouter">
      <Suspense fallback={<SuspenseFallback />}>
        <Switch>
          <Route exact path="/login">
            <LoginRedirect />
          </Route>
          <Route exact path="/logout">
            <LogoutRedirect />
          </Route>

          <ProtectedRoute path="/connect/farm-at-hand">
            <FarmAtHandVerification />
          </ProtectedRoute>

          <SentryRoute path="/not-authorized" component={NotAuthorized} />

          <ProtectedRoute path="/print">
            <PrintRouter />
          </ProtectedRoute>
          {/* PRIMARY APPLICATION MODULES */}
          <ProtectedRoute path="/">
            <AppLayoutLoggedIn>
              <Switch>
                <ProtectedRoute path="/analytics">
                  <Analytics />
                </ProtectedRoute>

                <ProtectedRoute path="/admin">
                  <Admin />
                </ProtectedRoute>

                <ProtectedRoute path="/map/:farmId?">
                  <FieldsMap />
                </ProtectedRoute>
                <Redirect exact from="/:farmId?" to="/map/:farmId?" />
              </Switch>
            </AppLayoutLoggedIn>
          </ProtectedRoute>

          <SentryRoute path="*">
            <NotFound />
          </SentryRoute>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
}

export default AppRouter;
