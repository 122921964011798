import {
  useHistory,
  generatePath,
  useParams,
  useLocation,
} from 'react-router-dom';

type RedirectOptions = {
  /** Replaces rather than pushes a route onto the history stack */
  replace?: boolean;
  /**
   * Updates the path in place. Only provide the parameters required
   * to be updated or an empty object to use the existing params
   * */
  updatePathParams?: Record<string, string>;
  /** Will not persist the current set of queries when true */
  refreshQueryString?: boolean;
  /** Passed on to url state and can be found on location.state */
  state?: Record<string, string>;
  /**
   * Object of queries to use on the next page.
   * These will replace any existing params with the same name
   * Use the `useSearchParams` hooks if you are only modifying the query string.
   * Use with `qsWhitelist` to reset some of the existing params.
   */
  queryString?: Record<string, string | null>;
  /**
   * List of query param names to maintain from the current url
   * Has no effect if `refreshQueryString` is true
   * Any params set with `queryString` will overwrite any maintained params with the same name
   */
  qsWhitelist?: string[];
};

type Redirect = (
  /** A url to navigate to or a path template to be used with updatePathParams  */
  path: string | number,
  option?: RedirectOptions,
) => void;

const createNextPath = (
  template: string,
  nextParams?: Record<string, string>,
  prevParams?: Record<string, string>,
) => {
  const nextPath = nextParams
    ? generatePath(template, { ...prevParams, ...nextParams })
    : template;
  return nextPath;
};

const createNextQueryString = (
  refreshQueries: boolean,
  prevQueries: Record<string, string>,
  nextQueries?: Record<string, string | null>,
  queryWhitelist?: string[],
) => {
  if (refreshQueries) return nextQueries;
  if (!queryWhitelist) return { ...prevQueries, ...nextQueries };

  const maintainedQueries: Record<string, string> = {};
  Object.entries(prevQueries).forEach(([param, value]) => {
    if (queryWhitelist.includes(param)) {
      maintainedQueries[param] = value;
    }
  });

  return { ...maintainedQueries, ...nextQueries };
};

/**
 * A hook to simplify updating the url
 * Handles both path and queryString. If you are only updating the queryString use the `useSearchParams` hook instead
 */
function useRedirect(): Redirect {
  // todo: replace useHistory with useNavigate when upgrading to react-router v6
  const history = useHistory();
  const params = useParams();
  const { query: _query } = useLocation();

  const redirect = (path: string | number, opts?: RedirectOptions): void => {
    if (
      (opts?.queryString || opts?.qsWhitelist) &&
      !opts.refreshQueryString &&
      !opts.updatePathParams
    )
      throw Error(
        'Looks like you are trying to update the query string on its own without refreshing it. Use the useSearchParams hook instead',
      );

    if (typeof path === 'number') return history.go(path);
    if (!opts) return history.push({ pathname: path, query: _query });

    const {
      replace = false,
      updatePathParams,
      refreshQueryString = false,
      state,
    }: RedirectOptions = opts;

    const pathname = createNextPath(path, updatePathParams, params);
    const query = createNextQueryString(
      refreshQueryString,
      _query,
      opts?.queryString,
      opts?.qsWhitelist,
    );

    return history[replace ? 'replace' : 'push']({
      pathname,
      query,
      state,
    });
  };

  return redirect;
}

export default useRedirect;
