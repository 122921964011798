import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function CuPolygonIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M297.7,413.2l-2.1,7.4"
        transform="translate(-285.4 -409.1)"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M297,412l-4.9-.3"
        transform="translate(-285.4 -409.1)"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M290.6,412.6l-2.5,5.9"
        transform="translate(-285.4 -409.1)"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M293.9,412.1l-2.9,8.4"
        transform="translate(-285.4 -409.1)"
        fill="none"
        stroke="currentColor"
        strokeDasharray="2"
      />
      <path
        d="M288.8,420.1l5.4,1.7"
        transform="translate(-285.4 -409.1)"
        fill="none"
        stroke="currentColor"
      />
      <g>
        <path
          d="M297.9,411.1c1.9,0,1.9,2.9,0,3A1.5,1.5,0,1,1,297.9,411.1Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
        />
        <path
          d="M297.9,411.6a1,1,0,0,1,0,2A1,1,0,1,1,297.9,411.6Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <g>
        <path
          d="M290.9,410.1c1.9,0,1.9,2.9,0,3A1.5,1.5,0,1,1,290.9,410.1Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
        />
        <path
          d="M290.9,410.6a1,1,0,0,1,0,2A1,1,0,1,1,290.9,410.6Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <g>
        <path
          d="M287.9,418.1c1.9,0,1.9,2.9,0,3A1.5,1.5,0,1,1,287.9,418.1Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
        />
        <path
          d="M287.9,418.6a1,1,0,0,1,0,2A1,1,0,1,1,287.9,418.6Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <g>
        <path
          d="M294.9,420.1c1.9,0,1.9,2.9,0,3A1.5,1.5,0,1,1,294.9,420.1Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
        />
        <path
          d="M294.9,420.6a1,1,0,0,1,0,2A1,1,0,1,1,294.9,420.6Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <path
        d="M301.2,432.2c-1.5,0-1.7-1.5-1.5-2.7s.9-4,1.3-6.1.9-2.4,1.2-2.8a4.6,4.6,0,0,1,1.8-1c-.4,2-.8,5.6-2.2,7.3s-.6,1.1-.4,1.1,1.3.5,1.5,1.2S302.9,432.3,301.2,432.2Zm.2-3.3c-1.3.1-1.7,2.5-.3,2.7S302.8,429.1,301.4,428.9Z"
        transform="translate(-285.4 -409.1)"
        fill="currentColor"
      />
      <path
        d="M303.5,425.1l4.8-.7a4.1,4.1,0,0,1-2.5,2.5l-3.5.3A7.2,7.2,0,0,0,303.5,425.1Z"
        transform="translate(-285.4 -409.1)"
        fill="currentColor"
      />
      <path
        d="M298,427.7c-.7,0-1.7,0-2-.3a1.6,1.6,0,0,1-.6-1.3c-.1-1.3,1.3-1.8,2.4-1.7s.8.1,1.2.5,1,2.1.5,2.6A2.6,2.6,0,0,1,298,427.7Zm-.6-2.6c-1.7,0-1.9,1.9,0,1.9S299.4,425.2,297.4,425.1Z"
        transform="translate(-285.4 -409.1)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default CuPolygonIcon;
