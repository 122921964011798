import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function PlantingIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M296.8,427.5c-.5-2.9,1.3-5.1,2.5-7.5"
        transform="translate(-285.4 -409.3)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M297.1,424.2a21.6,21.6,0,0,0-.6-4.7c-.5-1.4-1.2-1.6-1.2-1.6"
        transform="translate(-285.4 -409.3)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <g>
        <path
          d="M299.6,419.7c-1.2-.9-.4-2.7.3-4a8.4,8.4,0,0,1,3.6-2.6c.3.9.9,2.7.1,4s-3.1,3.1-4,2.6Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M303.2,413.8c-1.9.6-3.7,2.6-3.7,4.5s.2.9.4,1,2.3-.7,3.3-2.4a4.2,4.2,0,0,0,0-3.1m.5-1.3s1.5,2.9.4,4.9-3.5,3.5-4.7,2.7-1.1-2.7.1-4.6S303.7,412.5,303.7,412.5Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M290.6,411.5a8.4,8.4,0,0,1,3.9,2.7c.7,1.4,1.6,3.3.3,4.2s-3.2-.7-4.3-2.7S290.3,412.3,290.6,411.5Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M290.9,412.1a4.4,4.4,0,0,0,0,3.4c.7,1.1,2.4,2.9,3.6,2.5s.5-.2.4-1.1-2-4.1-4-4.8m-.5-1.3s3.3,1.1,4.5,3.2,1.5,4.1.1,4.9-3.8-.9-5-2.9S290.4,410.8,290.4,410.8Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <path
        d="M302.6,415.5a3.2,3.2,0,0,1-2.1,2.6"
        transform="translate(-285.4 -409.3)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M291.8,414.1c.4,2.1,1.7,1.1,2.4,2.9"
        transform="translate(-285.4 -409.3)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M287.2,427.2h6.9c1.2,4.1,4.4,5.2,5.3,0h8.1"
        transform="translate(-285.4 -409.3)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export default PlantingIcon;
