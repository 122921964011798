import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function FieldZoneIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M304.9,416.6l-3,10.8"
        transform="translate(-285.4 -409.1)"
        stroke="currentColor"
        fill="none"
      />
      <path
        d="M303.9,414.9l-7.1-.5"
        transform="translate(-285.4 -409.1)"
        stroke="currentColor"
        fill="none"
      />
      <path
        d="M294.6,415.8l-3.7,8.6"
        transform="translate(-285.4 -409.1)"
        stroke="currentColor"
        fill="none"
      />
      <path
        d="M299.3,415.1l-4.2,12.2"
        transform="translate(-285.4 -409.1)"
        fill="none"
        stroke="currentColor"
        strokeDasharray="2"
      />
      <path
        d="M291.9,426.7l7.9,2.4"
        transform="translate(-285.4 -409.1)"
        stroke="currentColor"
        fill="none"
      />
      <g>
        <circle cx="20" cy="6" r="2" fill="none" />
        <circle cx="20" cy="6" r="1.5" stroke="currentColor" fill="none" />
      </g>
      <g>
        <circle cx="10" cy="5" r="2" fill="none" />
        <circle cx="10" cy="5" r="1.5" stroke="currentColor" fill="none" />
      </g>
      <g>
        <circle cx="5" cy="17" r="2" fill="none" />
        <circle cx="5" cy="17" r="1.5" stroke="currentColor" fill="none" />
      </g>
      <g>
        <circle cx="16" cy="20" r="2" fill="none" />
        <circle cx="16" cy="20" r="1.5" stroke="currentColor" fill="none" />
      </g>
    </SvgIcon>
  );
}

export default FieldZoneIcon;
