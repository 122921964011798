/* **************************************************************************
 *
 * File contians custom components that override components in react-select
 * Have a look at the documentation for more information on customisation
 * https://react-select.com/components
 *
 * Its not always clear which component is for what, this is a list of those used here
 *
 * Option:          Internal wrapper around the options in the drop down list.
 * ValueContainer:  Internal wrapper around the selected value Select Component
 *
 ************************************************************************** */

import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const containerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
};
const columnOneStyles = { minWidth: '30%', textAlign: 'left' };
const columnTwoStyles = { minWidth: '70%', textAlign: 'left' };

export function TwoColumnOption(props) {
  return (
    <components.Option {...props}>
      <div style={containerStyles}>
        <strong style={columnOneStyles}>{props.label[0]}</strong>
        <span style={columnTwoStyles}>{props.label[1]}</span>
      </div>
    </components.Option>
  );
}

TwoColumnOption.propTypes = {
  label: PropTypes.array.isRequired,
};

export function TwoColumnValueContainer(props) {
  if (!props.selectProps.value) return <components.ValueContainer {...props} />;

  return (
    <components.ValueContainer {...props}>
      <div style={containerStyles}>
        <div style={columnOneStyles}>
          <strong>{props.selectProps.value.label[0]}</strong>
        </div>
        <div style={columnTwoStyles}>{props.selectProps.value.label[1]}</div>
      </div>
    </components.ValueContainer>
  );
}

TwoColumnValueContainer.propTypes = {
  selectProps: PropTypes.object,
};
