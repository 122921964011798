import { groupBy, round } from 'lodash-es';
import { generateColourScaleFromColourList } from 'modules/FieldsMap/utilities/colourScale.utility';

function normalizeEntryRange(value, BAR_STEP) {
  return round(round(value / BAR_STEP) * BAR_STEP, 2);
}

function getNumberOfEntries(range, BAR_STEP) {
  return (
    1 +
    round(
      (normalizeEntryRange(range.upper, BAR_STEP) -
        normalizeEntryRange(range.lower, BAR_STEP)) /
        BAR_STEP,
    )
  );
}

function generateEmptyLookupObjectForRange(range, BAR_STEP) {
  return new Array(getNumberOfEntries(range, BAR_STEP))
    .fill(range.lower)
    .map((e, i) => ({
      x: round(normalizeEntryRange(range.lower, BAR_STEP) + i * BAR_STEP, 2),
      y: 0,
    }));
}

function groupValuesByGivenBarStep(values, BAR_STEP) {
  return groupBy(
    values.map(rawEntry => ({
      ...rawEntry,
      value: normalizeEntryRange(rawEntry.value, BAR_STEP),
    })),
    'value',
  );
}

function fillEmptyLookupObjectWithGroupedValues(emptyEntries, groupedByValue) {
  return emptyEntries.map(({ x }) => ({
    x,
    y: groupedByValue[x]
      ? groupedByValue[x].reduce(
          (totalHectares, boundary) => totalHectares + boundary.hectares,
          0,
        )
      : 0,
  }));
}

function barChartDataByChartEntriesAndColourMap(
  chartEntries,
  colourScale,
  range,
) {
  const colourMap = chartEntries.map(entry =>
    colourScale(entry.x).alpha(0.8).css(),
  );

  return {
    range,
    labels: chartEntries,
    datasets: [
      {
        data: chartEntries,
        backgroundColor: colourMap,
        hoverBackgroundColor: colourMap,
      },
    ],
  };
}

function chartEntriesFromValues(range, values, BAR_STEP) {
  return fillEmptyLookupObjectWithGroupedValues(
    generateEmptyLookupObjectForRange(range, BAR_STEP),
    groupValuesByGivenBarStep(values, BAR_STEP),
  );
}

export function formatValuesToBarChartDataByColourList(
  values,
  range,
  BAR_STEP,
  colourList,
) {
  const chartEntries = chartEntriesFromValues(range, values, BAR_STEP);
  const colourScale = generateColourScaleFromColourList(colourList, range);

  return barChartDataByChartEntriesAndColourMap(
    chartEntries,
    colourScale,
    range,
  );
}

export default formatValuesToBarChartDataByColourList;
