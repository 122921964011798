import {
  getUnitLabelLocalStorage,
  getUnitValueLocalStorage,
} from 'libraries/unitConversion';
import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';

const nitrogenApplicationAnalysis = analysisBuilder()
  .addUnits(
    getUnitLabelLocalStorage({ unitId: 'kilograms-nitrogen-per-hectare' }),
  )
  .addBarStep(1)
  .addDefaultRange({
    lower: 0,
    upper: 120,
  })
  .addColourList(['#F7FBFF', '#A0D5ED', '#00C8EE', '#357FB0', '#23528B'])
  .addUnitConverter(values =>
    values.map(value => ({
      ...value,
      hectares: getUnitValueLocalStorage({
        unitId: 'hectares',
        value: value.hectares,
      }),
      value: getUnitValueLocalStorage({
        unitId: 'kilograms-nitrogen-per-hectare',
        value: value.value,
      }),
    })),
  )
  .addRangeConverter(({ lower, upper }) => ({
    lower: getUnitValueLocalStorage({
      unitId: 'kilograms-nitrogen-per-hectare',
      value: lower,
      precision: 0,
    }),
    upper: getUnitValueLocalStorage({
      unitId: 'kilograms-nitrogen-per-hectare',
      value: upper,
      precision: 0,
    }),
  }))
  .addTooltipConverter(value =>
    getUnitValueLocalStorage({
      unitId: 'kilograms-nitrogen-per-hectare',
      value,
    }),
  )
  .build();

export default nitrogenApplicationAnalysis;
