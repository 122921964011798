import getUnitValue from './get-unit-value';
import conversionTables from '../conversion-tables';
import { UnitId, UnitContext, Language, UnitSystem } from '../unit-constants';

type Options = {
  value: number;
  unitId: UnitId;
  unitSystemFrom?: UnitSystem;
  precision?: number;
  unitContext?: UnitContext;
};
function getUnitValueLocalStorage(opts: Options): number | null {
  const unitSystem: {
    language: Language;
    system: UnitSystem;
    // @ts-ignore
  } = JSON.parse(localStorage.getItem('unitSystem'));

  if (!unitSystem) {
    console.error(
      'There is no language set, please ensure you use this function within a UnitConversionProvider',
    );
    return null;
  }

  return getUnitValue(
    opts.value,
    opts.unitId,
    opts.unitSystemFrom,
    unitSystem.system,
    conversionTables,
    opts.precision,
    opts.unitContext,
  );
}

export default getUnitValueLocalStorage;
