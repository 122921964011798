import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function DownloadIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M297.4,411.5v10.2"
        transform="translate(-285.4 -408.5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="12"
        y1="13.7"
        x2="16"
        y2="9.7"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="12"
        y1="13.7"
        x2="8"
        y2="9.7"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="3.5"
        y1="10.4"
        x2="3.5"
        y2="21"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="20.5"
        y1="10.4"
        x2="20.5"
        y2="21"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="20.5"
        y1="21"
        x2="3.5"
        y2="21"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export default DownloadIcon;
