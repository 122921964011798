import contourConfig from 'constants/contourConfig';
import applicationReducerFactory from 'modules/FieldsMap/partials/Applications/reduxState/applicationStateManagement/applicationReducerFactory';

export function transformer(application) {
  return {
    enabled: true,
    range: {
      lower: 0,
      upper: 1,
    },
    application: application.map(gridSquare => ({
      ...gridSquare,
      value: gridSquare.spray ? 1 : 0,
    })),
  };
}

const sclerotiniaApplication = applicationReducerFactory(
  contourConfig.sclerotiniaApplication.name,
  transformer,
);

export default sclerotiniaApplication;
