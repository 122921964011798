import { getDistributionHistogramDefaultOptions } from './barChartOptionsGenerator';
import { formatValuesToBarChartDataByColourList } from './barChartDataFormatter';

const analysisBuilder = () => {
  const analysis = {
    defaultRange: {
      lower: 0,
      upper: 100,
    },
    barStep: undefined,
    tileFillOpacity: '0.8',
    units: '',
    colourList: [],
    colorBins: [],
    tooltipsEnabled: true,
    tooltipConverter: values => values,
    unitConverter: values => values,
    rangeConverter: values => values,
    convertUnitValues(tooltipValue) {
      return this.unitConverter(tooltipValue);
    },
    convertRangeValues(range) {
      return this.rangeConverter(range);
    },
    convertTooltipValue(value) {
      return this.tooltipConverter(value);
    },
    getUnits() {
      return this.units;
    },
    getDefaultRange() {
      return this.defaultRange;
    },
    getColourList() {
      return this.colourList;
    },
    getColourBins() {
      return this.colourBins;
    },
    getTooltipScale() {
      return this.tooltipScale;
    },
    getTooltipStatus() {
      return this.tooltipsEnabled;
    },
    generateBarChartOptions() {
      return getDistributionHistogramDefaultOptions(
        this.units,
        this.tooltipsFormatter,
      );
    },
    formatValuesToChartData(values = [], range) {
      return formatValuesToBarChartDataByColourList(
        this.convertUnitValues(values),
        this.convertRangeValues(range || this.defaultRange),
        this.barStep,
        this.colourList,
      );
    },
  };

  return {
    addUnits(units) {
      analysis.units = units;

      return this;
    },
    addBarStep(barStep) {
      analysis.barStep = barStep;

      return this;
    },
    addTileFillOpacity(tileFillOpacity) {
      analysis.tileFillOpacity = tileFillOpacity;

      return this;
    },
    addDefaultRange(defaultRange) {
      analysis.defaultRange = defaultRange;

      return this;
    },
    addColourList(colourList) {
      analysis.colourList = colourList;

      return this;
    },
    // for explicit colors on lines
    addColourBinList(colourBins) {
      analysis.colourBins = colourBins;
      return this;
    },
    addTooltipScale(num) {
      analysis.tooltipScale = num;

      return this;
    },
    disableTooltips() {
      analysis.tooltipsEnabled = false;

      return this;
    },
    addTooltipFormatter(formatter) {
      analysis.tooltipsFormatter = formatter;

      return this;
    },
    addTooltipConverter(fn) {
      analysis.tooltipConverter = fn;

      return this;
    },
    addUnitConverter(fn) {
      analysis.unitConverter = fn;

      return this;
    },
    addRangeConverter(fn) {
      analysis.rangeConverter = fn;

      return this;
    },
    build() {
      return analysis;
    },
  };
};

export default analysisBuilder;
