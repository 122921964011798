const tableResetStyles = {
  table: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    margin: '0',
    padding: '0',
    border: 'none',
    borderCollapse: 'inherit',
    borderSpacing: '0',
    borderColor: 'inherit',
    verticalAlign: 'inherit',
    textAlign: 'left',
    fontWeight: 'inherit',
    WebkitBorderHorizontalSpacing: '0',
    WebkitBorderVerticalSpacing: '0',
  },
  thead: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    margin: '0',
    padding: '0',
    border: 'none',
    borderCollapse: 'inherit',
    borderSpacing: '0',
    borderColor: 'inherit',
    verticalAlign: 'inherit',
    textAlign: 'left',
    fontWeight: 'inherit',
    WebkitBorderHorizontalSpacing: '0',
    WebkitBorderVerticalSpacing: '0',
  },
  tbody: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    margin: '0',
    padding: '0',
    border: 'none',
    borderCollapse: 'inherit',
    borderSpacing: '0',
    borderColor: 'inherit',
    verticalAlign: 'inherit',
    textAlign: 'left',
    fontWeight: 'inherit',
    WebkitBorderHorizontalSpacing: '0',
    WebkitBorderVerticalSpacing: '0',
  },
  tfoot: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    margin: '0',
    padding: '0',
    border: 'none',
    borderCollapse: 'inherit',
    borderSpacing: '0',
    borderColor: 'inherit',
    verticalAlign: 'inherit',
    textAlign: 'left',
    fontWeight: 'inherit',
    WebkitBorderHorizontalSpacing: '0',
    WebkitBorderVerticalSpacing: '0',
  },
  th: { display: 'inline' },
  td: { display: 'inline' },
};

export default tableResetStyles;
