const variablesStyles = {
  ':root': {
    '--small-font-size': '12px',
    '--base-font-size': '14px',
    '--large-font-size': '16px',
    '--mega-font-size': '24px',
    '--spacing-root': '10px',
    '--spacing-half': 'calc(var(--spacing-root) / 2)',
    '--spacing-double': 'calc(var(--spacing-root) * 2)',
    '--sidebar-width': '375px',
  },
};

export default variablesStyles;
