import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { AnalysisConfig } from '../types/Analyses';

const canopyCoverage: AnalysisConfig = {
  id: 7,
  name: 'canopyCoverage',
  displayName: 'Canopy Coverage',
  inAPI: 'Canopy Coverage',
  type: 'analysis',
  url: 'analysis-canopy_coverage',
  clouds: true,
  config: analysisBuilder()
    .addUnits('unit.percentage')
    .addBarStep(5)
    .addDefaultRange({
      lower: 0,
      upper: 100,
    })
    .addColourList(['#633016', '#4a640f', '#329818', '#1ecc23', '#28fd2f'])
    .build(),
};

export default canopyCoverage;
