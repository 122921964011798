import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function SettingsIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <polygon
          points="0 0 24 0 24 0 24 24 24 24 0 24 0 24 0 0 0 0"
          fill="none"
        />
        <polygon
          points="0.5 0.5 23.5 0.5 23.5 0.5 23.5 23.5 23.5 23.5 0.5 23.5 0.5 23.5 0.5 0.5 0.5 0.5"
          fill="none"
        />
      </g>
      <path
        d="M299.1,432.7c-1.3.1-1.6-1.5-2.4-2s-1,.8-1.2,1a1.9,1.9,0,0,1-3.2-1c0-2.1-.2-2.1-2.1-1.4s-1.9-2.5-1.2-4.3-1.2-.9-1.6-1.1c-2-1.1-.7-3.4,1-3.9s-.3-1.5-.6-1.9a2,2,0,0,1,1.8-3c.4,0,1.2.4,1.5.1s-.6-2.8.9-3.4,2.2.7,3.3.9.6-.9.7-1.2a2,2,0,0,1,3.6,0c.4,1.7.8,2,2.3.9s2.9.6,2.6,2-.4,2.2,1.4,2.1a1.8,1.8,0,0,1,1.2,3.1c-1.2,1.1-1.5,1.6.1,2.4a2,2,0,0,1-.7,3.5c-.3.1-1.3.2-1.5.5h0c-.2.3.4,1,.5,1.2s-.4,2.9-1.7,2.9C298.9,428.2,303.1,432.3,299.1,432.7Zm-2.6-3.3c1.1-.2,1.7,1,2.2,1.8,2.3,1.2-.3-4.9,5.1-2.4,1.9-.7-2.1-3.2,1.3-4.5.3-.1,1.5-.1,1.6-.6s-.7-1-1.1-1.3a2,2,0,0,1-.3-2.9c.1-.3,1.4-1.1,1-1.5s-1.3-.3-1.6-.4a2,2,0,0,1-1.8-2.2c.1-.4.6-1.6,0-1.9s-1.2.5-1.5.6a1.8,1.8,0,0,1-2.6-.9c-.2-.5-.4-1.8-1.2-1.6s-.6,1-.8,1.3a1.9,1.9,0,0,1-2.7.8c-.4-.3-1.3-1.4-1.7-.7s.6,2.4-.5,3.1-1.8.4-2.6.2,3.6,3.3-1.5,5.6c-.7,1.2,2.3,1,2.4,2.3s-1.4,2.6-.4,3.7,1.1-.3,1.5-.3a1.8,1.8,0,0,1,2.2,1.6c.1.5-.1,1.8.6,1.8S295.4,429.2,296.5,429.4Zm.8-3.7c-5.2,0-5.2-8.2,0-8.2s5.1,8.2,0,8.2Zm0-6.9c-3.3-.2-4,4.9-.7,5.5s4.6-5.4.7-5.5Z"
        transform="translate(-285.4 -409.3)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default SettingsIcon;
