import { useCallback } from 'react';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';
import { fireGAEvent } from './fireGAEvent';

/**
 * Fires a google anlytics
 */
function useGoogleAnalyticsEvent(): (
  category: EventCategory,
  action: EventAction,
  label?: string,
  value?: number,
) => void {
  const fireEvent = useCallback(
    (
      category: EventCategory,
      action: EventAction,
      label?: string,
      value?: number,
    ) => {
      fireGAEvent(category, action, label, value);
    },
    [],
  );

  return fireEvent;
}

export default useGoogleAnalyticsEvent;
