/* eslint-disable camelcase */
import React from 'react';
import { useUnitConversion } from '../unit-conversion-context';

import { UnitId, UnitContext } from '../unit-constants';

type Props = {
  unitId: UnitId;
  unitContext?: UnitContext;
};

function UnitLabeler({
  unitId,
  unitContext = 'general',
}: Props): React.ReactElement {
  const { getUnitLabel } = useUnitConversion();

  return (
    <span data-testid="unit-label">
      {getUnitLabel({ unitId, unitContext })}
    </span>
  );
}

export default UnitLabeler;
