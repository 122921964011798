import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function EquipmentListBigIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 120 120">
      <path
        stroke="currentColor"
        d="M72.7 56.8a9 9 0 113.5-.7 8.9 8.9 0 01-3.5.7zm0-15a6 6 0 104.3 1.8 6 6 0 00-4.3-1.8zm14.4 5.7h-4.3a10.6 10.6 0 00-.8-3.7 10.4 10.4 0 00-1.9-3.1 10.8 10.8 0 00-3.7-2.8 9.1 9.1 0 00-3.9-.8 10.1 10.1 0 00-3.2.5 13 13 0 00-2.6 1.3 13.3 13.3 0 00-2.3 1.8l-.8-.3v-1.7l3.2-2 .5-10.7h13.8l3.5 10.2 13.8 1.4v5.1l1.6 2.2V47l-1-1.3a6.3 6.3 0 00-1.4-1.4 6.4 6.4 0 00-4-1.3 7 7 0 00-1 0 6.5 6.5 0 00-2.5 1 7 7 0 00-1.7 1.5 7.7 7.7 0 00-1.3 2zM83 39v1.5H97v-1.5zm-6.3-11.5v8.3h6l-3-8.3zm-7.2 0v7.2h5.9v-7.2z"
      />
      <path
        stroke="currentColor"
        d="M92 56a8.6 8.6 0 01-1.7-.1 8 8 0 01-5.6-4.7 8.2 8.2 0 01-.5-1.4 8 8 0 010-3.2 7.8 7.8 0 011.2-2.8 7.7 7.7 0 015-3.3 7.7 7.7 0 013.1 0 7.6 7.6 0 012.8 1.1 7.8 7.8 0 012.9 3.5 6.9 6.9 0 01.4 1.5 7.7 7.7 0 010 3.2 7.8 7.8 0 01-1.1 2.8 8 8 0 01-3.5 2.8 7.8 7.8 0 01-3 .7zm0-12a4.2 4.2 0 101.6.4A4 4 0 0092 44zM72.7 93.7a9 9 0 113.5-.7 8.9 8.9 0 01-3.5.7zm0-15a6 6 0 104.3 1.7 6 6 0 00-4.3-1.7zm14.4 5.7h-4.3a10.6 10.6 0 00-.8-3.7 10.4 10.4 0 00-1.9-3.1 11 11 0 00-3.7-2.8 9.3 9.3 0 00-3.9-.9 10.1 10.1 0 00-3.2.6 13 13 0 00-2.6 1.2 13.3 13.3 0 00-2.3 1.8l-.8-.2v-1.8l3.2-2 .5-10.6H81L84.6 73l13.8 1.4v5.1l1.6 2.2V84l-1-1.4a6.3 6.3 0 00-1.4-1.3 7 7 0 00-1.7-1 6.3 6.3 0 00-2.3-.3 7 7 0 00-.9 0 6.4 6.4 0 00-2.6 1 7 7 0 00-1.7 1.4 7.7 7.7 0 00-1.3 2zM83 75.8v1.5H97v-1.5zm-6.3-11.6v8.4h6l-3-8.4zm-7.2 0v7.2h5.9v-7.2z"
      />
      <path
        stroke="currentColor"
        d="M92 93a8.6 8.6 0 01-1.7-.3 7.8 7.8 0 01-2.8-1.1 8 8 0 01-2.8-3.5 8.5 8.5 0 01-.5-1.5 8 8 0 010-3.2 7.8 7.8 0 011.2-2.8 8 8 0 013.5-2.8 8.7 8.7 0 011.4-.5 8.1 8.1 0 013.2 0 7.9 7.9 0 015.7 4.7 6.9 6.9 0 01.4 1.5 7.7 7.7 0 010 3.2 7.8 7.8 0 01-1.1 2.8 8 8 0 01-3.5 2.8 7.8 7.8 0 01-3 .6zm0-12a4.2 4.2 0 101.6.2A4.3 4.3 0 0092 81zM59.1 42l.8-.7h-2.7l.8.6h-2.3l.8-.6h-2.8l.8.6h-2.3l.8-.6h-2.7l.8.6h-2.3l.8-.6h-2.8l.8.6h-2.3l.8-.6h-2.7l.8.6h-2.3l.8-.6h-2.8l.8.6h-2.3l.8-.6h-2.8l.9.6H35l.8-.6H33l.8.6h-2.3l.8-.6h-2.7l.7.6h-2.2l.8-.6H26l.8.6h-2.3l.8-.6h-2.8l.8.6h-2.2l.7-.6H20V39l5-1h37l-.4 2.4-.7.9h-.3l.8.6zm-5.4-1.4h4.8L56.1 39zm-7.5 0H51L48.6 39zm-7.5 0h4.9L41 39zm-7.5 0h5L33.6 39zm-7.5 0h5L26.1 39zm28.7-.5l2.4-1.6H50zm-7.5 0l2.4-1.6h-4.9zm-7.5 0l2.4-1.6H35zm-7.5 0l2.5-1.6h-5zM22.1 47a7.9 7.9 0 011.4 2.5 1.4 1.4 0 01-2.7 0A7.8 7.8 0 0122 47zM43 46.2a8 8 0 011.4 2.6 1.4 1.4 0 01-2.7 0 8 8 0 011.3-2.6zM25.1 44.5a8 8 0 011.4 2.5 1.4 1.4 0 11-2.7 0 8 8 0 011.3-2.5zM47.4 44.5a8.1 8.1 0 011.3 2.5 1.4 1.4 0 11-2.7 0 8 8 0 011.4-2.5zM32.2 47a7.8 7.8 0 011.3 2.5 1.4 1.4 0 01-2.7 0 7.9 7.9 0 011.4-2.6zM52.8 47a7.8 7.8 0 011.3 2.5 1.4 1.4 0 01-2.7 0 7.9 7.9 0 011.4-2.6zM37.6 44.5A8.2 8.2 0 0139 47a1.4 1.4 0 01-2.7 0 8 8 0 011.3-2.5zM58.7 44.5A8.2 8.2 0 0160 47a1.4 1.4 0 01-2.7 0 8.2 8.2 0 011.4-2.5z"
      />
      <path
        stroke="currentColor"
        d="M27.5 68.6h21a6.5 6.5 0 010 13h-21a6.5 6.5 0 010-13z"
      />
      <path
        stroke="currentColor"
        d="M35 94a7.6 7.6 0 01-1.5-.1 7.5 7.5 0 01-2.6-1.2 7.4 7.4 0 01-2.7-3.2 7.5 7.5 0 01-.5-1.4 7.6 7.6 0 010-3 7.5 7.5 0 014.4-5.4 7.7 7.7 0 011.4-.4 7.4 7.4 0 015.7 1.1 7.5 7.5 0 012.6 3.3 7.7 7.7 0 01.5 1.4 7.6 7.6 0 010 3 7.3 7.3 0 01-1.1 2.6 7.4 7.4 0 01-3.3 2.7 7.8 7.8 0 01-1.4.4 7.5 7.5 0 01-1.5.2zm0-11.3a4 4 0 101.6.3 3.9 3.9 0 00-1.5-.3z"
      />
      <path
        stroke="currentColor"
        d="M38 83.6v-2.2h17.8c0-1 2-1.8 3.1-1.8a2 2 0 012.1 1.8c0 1-.8-1-2-1a1.2 1.2 0 00-1 .4V83.6z"
      />
    </SvgIcon>
  );
}

export default EquipmentListBigIcon;
