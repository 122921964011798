const scrollbarStyles = {
  '::-webkit-scrollbar,\n::-moz-scrollbar': { width: '5px', height: '5px' },
  '::-webkit-scrollbar-thumb,\n::-moz-scrollbar-thumb': {
    background: 'var(--c-tint)',
  },
  '::-webkit-scrollbar-track,\n::-moz-scrollbar-track': {
    background: 'var(--c-tint-lighter)',
  },
  body: {
    scrollbarFaceColor: 'var(--c-tint)',
    scrollbarTrackColor: 'var(--c-tint-lighter)',
  },
};

export default scrollbarStyles;
