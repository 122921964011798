import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '../../styles';

const useStyles = makeStyles(({ spacing }: any) => ({
  box: {
    margin: spacing(0, 0.5),
  },
}));

type ContainerProps = {
  children: React.ReactNode;
  type?: 'box';
};

function Container({
  children,
  type = 'box',
}: ContainerProps): React.ReactElement {
  const classes = useStyles();

  return <div className={classNames(classes[type])}>{children}</div>;
}

export default Container;
