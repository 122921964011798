import { merge } from 'lodash-es';
import { makeStyles } from 'libraries/wings-ui/styles';
import hummingbirdLeafletStyles from './hummingbirdLeaflet.styles';
import leafletStyles from './leaflet.styles';

const useLeafletStyles = makeStyles({
  '@global': merge(leafletStyles, hummingbirdLeafletStyles),
});

export default useLeafletStyles;
