type SlackField = { type: string; text: string };
type SlackSection = { type: string; fields: SlackField[] };

function field(key: string, value: string | number): SlackField {
  return {
    type: 'mrkdwn',
    text: `*${key}:*\n${value}`,
  };
}

function section(fields: SlackField[]): SlackSection {
  return {
    type: 'section',
    fields,
  };
}

function generateSlackTable(
  data: Record<string, string | number> = {},
): SlackSection[] {
  const entries = Object.entries(data);
  const sections = [];

  for (let i = 0; i < entries.length - 1; i += 2) {
    sections.push(
      section([
        field(entries[i][0], entries[i][1]),
        field(entries[i + 1][0], entries[i + 1][1]),
      ]),
    );
  }

  if (entries.length % 2 === 1) {
    const lastOddElement = entries.length - 1;
    sections.push(
      section([field(entries[lastOddElement][0], entries[lastOddElement][1])]),
    );
  }

  return sections;
}

export default generateSlackTable;
