import React from 'react';
import { SvgIcon } from '../../components';

function CalendarIcon(props) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path stroke="currentColor" strokeLinecap="round" d="M6 4v5" />
        <path stroke="currentColor" strokeLinecap="round" d="M18 4v5" />
        <path stroke="currentColor" d="M6.5 12h1" />
        <path stroke="currentColor" d="M9.5 12h1" />
        <path stroke="currentColor" d="M12.5 12h1" />
        <path stroke="currentColor" d="M15.5 12h1" />
        <path stroke="currentColor" d="M18.5 12h1" />
        <path stroke="currentColor" d="M6.5 14h1" />
        <path stroke="currentColor" d="M9.5 14h1" />
        <path stroke="currentColor" d="M12.5 14h1" />
        <path stroke="currentColor" d="M15.5 14h1" />
        <path stroke="currentColor" d="M18.5 14h1" />
        <path stroke="currentColor" d="M6.5 16h1" />
        <path stroke="currentColor" d="M9.5 16h1" />
        <path stroke="currentColor" d="M12.5 16h1" />
        <path stroke="currentColor" d="M15.5 16h1" />
        <path stroke="currentColor" d="M18.5 16h1" />
        <path stroke="currentColor" d="M6.5 18h1" />
        <path stroke="currentColor" d="M9.5 18h1" />
        <path stroke="currentColor" d="M12.5 18h1" />
        <path stroke="currentColor" d="M3.5 14h1" />
        <path stroke="currentColor" d="M3.5 16h1" />
        <path stroke="currentColor" d="M3.5 18h1" />
        <path
          d="M4.5 7s-2 0-2 1.5V21h19V8.5c0-1.5-2-1.5-2-1.5"
          stroke="currentColor"
        />
        <path stroke="currentColor" d="M7.5 7h9" />
      </g>
    </SvgIcon>
  );
}

export default CalendarIcon;
