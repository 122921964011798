import React from 'react';
import propTypes from 'prop-types';
import { Slider, Handles } from 'react-compound-slider';
import { makeStyles } from '../../styles';
import SliderHandle from './SliderHandle';

const useStyles = makeStyles(({ palette }) => ({
  sliderRoot: {
    position: 'relative',
    minHeight: 75,
    width: 'auto',
    margin: '10px 15px',
  },

  rail: {
    position: 'absolute',
    width: '100%',
    height: 6,
    top: 25,
    borderRadius: 3,
    background: palette.grey.tint,
  },

  limits: {
    position: 'relative',
    minWidth: '100%',
    margin: 0,
    fontSize: 12,
    color: palette.grey.tint,
  },

  lowerLimit: {
    position: 'absolute',
    left: '-8px',
    top: 0,
    width: 'auto',
  },

  upperLimit: {
    position: 'absolute',
    right: '-8px',
    top: 0,
    width: 'auto',
  },

  legened: {
    bottom: 0,
    position: 'absolute',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },

  legendItem: {
    display: 'flex',
    fontSize: 12,
  },

  legendIndicator: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    marginRight: 5,
  },
}));

function SliderInterface({
  disabled,
  domain,
  values,
  step,
  mode,
  onChange,
  onUpdate,
  colourList,
  formatNumber,
  hasLimits,
  hasLegend,
  legendValues,
}) {
  const classes = useStyles();
  if (disabled) {
    return (
      <div className={classes.sliderRoot}>
        <div className={classes.rail} />
      </div>
    );
  }

  return (
    <Slider
      className={classes.sliderRoot}
      domain={domain}
      values={values}
      step={step}
      mode={mode}
      onChange={onChange}
      onUpdate={onUpdate}
    >
      {hasLimits ? (
        <div className={classes.limits}>
          <span className={classes.lowerLimit}>{formatNumber(domain[0])}</span>
          <span className={classes.upperLimit}>
            {formatNumber(domain[domain.length - 1])}
          </span>
        </div>
      ) : null}
      <div className={classes.rail} />
      <Handles>
        {({ handles, getHandleProps }) => (
          <div>
            {handles.map((handle, index) => (
              <SliderHandle
                colour={colourList[index]}
                key={handle.id}
                handle={handle}
                domain={domain}
                getHandleProps={getHandleProps}
                formatNumber={formatNumber}
              />
            ))}
          </div>
        )}
      </Handles>
      {hasLegend ? (
        <div className={classes.legened}>
          {legendValues.map((legendValue, i) => (
            <div className={classes.legendItem}>
              <div
                className={classes.legendIndicator}
                style={{
                  background: colourList[i],
                }}
              />
              <div>{legendValue}</div>
            </div>
          ))}
        </div>
      ) : null}
    </Slider>
  );
}

SliderInterface.defaultProps = {
  step: 0.1,
  mode: 2,
  disabled: false,
  colourList: [],
  legendValues: [],
  hasLimits: false,
  hasLegend: false,
  formatNumber: n => n,
  onUpdate: () => {},
  onChange: () => {},
};

SliderInterface.propTypes = {
  domain: propTypes.array.isRequired,
  values: propTypes.array.isRequired,
  legendValues: propTypes.array,
  onChange: propTypes.func,
  onUpdate: propTypes.func,
  step: propTypes.number,
  mode: propTypes.number,
  disabled: propTypes.bool,
  colourList: propTypes.array,
  hasLimits: propTypes.bool,
  hasLegend: propTypes.bool,
  formatNumber: propTypes.func,
};

export default SliderInterface;
