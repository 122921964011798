import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function AddCropIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path
          d="M297.2,415.3c-1.5-.4,0-3.5.8-4s1,4.1-.8,4Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M297.8,412.2c-.5.4-.9,2.3-.6,2.5s.9-1.8.6-2.5m.3-1.6c.8.6,1.9,5.5-.9,5.1S297.2,410.8,298.1,410.6Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <line
        x1="3.3"
        y1="15.2"
        x2="0.5"
        y2="17.9"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <g>
        <path
          d="M294.5,417.9c-1.6-.4-.1-3.4.7-3.9s1.1,4.1-.7,3.9Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M295.1,414.9a3.2,3.2,0,0,0-.7,2.5c1,.4,1-1.9.7-2.5m.2-1.7c.8.6,1.9,5.5-.9,5.2S294.4,413.5,295.3,413.2Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M291.6,420.6c-1.5-.3,0-3.4.8-3.9s1.1,4.1-.8,3.9Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M292.2,417.6c-.5.4-.9,2.3-.6,2.5s1-1.9.6-2.5m.3-1.6c.8.5,1.9,5.4-.9,5.1S291.6,416.2,292.5,416Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M288.8,423.4c-1.5-.4,0-3.5.8-4s1,4.1-.8,4Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M289.4,420.3c-.5.4-.9,2.3-.6,2.5s.9-1.8.6-2.5m.3-1.6c.8.6,1.9,5.5-1,5.2S288.8,418.9,289.7,418.7Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M302.2,415.8c-.5.8-3.9,2-3.9.5S301.5,415.1,302.2,415.8Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M301.3,415.9c-.6-.4-2.8-.5-2.5.5s2.2,0,2.5-.5m1.7-.2c-.2.8-4.7,3.3-5.2.7S302.5,414.8,303,415.7Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M299.9,413.6c-1-1.2,2-2.8,2.9-2.7S301.2,414.8,299.9,413.6Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M302.2,411.5c-.7,0-2.1,1.3-2,1.6s1.8-.9,2-1.6m1.2-1.1c.3.9-1.8,5.5-3.9,3.5S302.5,410,303.4,410.4Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M299.5,418.4c-.5.9-4,2-4,.6S298.7,417.8,299.5,418.4Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M298.6,418.5c-.6-.3-2.9-.4-2.5.6s2.1-.1,2.5-.6m1.6-.2c-.2.9-4.7,3.3-5.2.8S299.7,417.5,300.2,418.3Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M296.7,421.2c-.5.8-4,1.9-4,.5S295.9,420.5,296.7,421.2Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M295.8,421.3c-.7-.4-2.9-.5-2.6.5s2.2,0,2.6-.5m1.6-.2c-.2.8-4.7,3.3-5.2.7S296.9,420.2,297.4,421.1Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="M293.8,423.9c-.5.9-3.9,2-3.9.6S293.1,423.2,293.8,423.9Z"
          transform="translate(-285.4 -409.3)"
          fill="none"
        />
        <path
          d="M292.9,424c-.6-.3-2.8-.5-2.5.5s2.2,0,2.5-.5m1.7-.2c-.3.8-4.7,3.3-5.2.7S294.1,423,294.6,423.8Z"
          transform="translate(-285.4 -409.3)"
          fill="currentColor"
        />
      </g>
      <line
        x1="17.1"
        y1="21.9"
        x2="17.1"
        y2="11.6"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <line
        x1="22.2"
        y1="16.7"
        x2="12"
        y2="16.7"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
    </SvgIcon>
  );
}

export default AddCropIcon;
