import { scale } from 'chroma-js';
import { sortBy, findIndex } from 'lodash-es';
import { isNull, isUndefined, isNumber } from 'lodash-es';

const LIGHT_BLUE = '#2093ce';

const colours = {
  default: ['#B31C08', '#E6E60B', '#1FB308'],
};

function getColoursForType(type) {
  return colours[type] || colours.default;
}

export function generateColourScaleFromColourList(
  colourList,
  range = { upper: 1, lower: 0 },
  scaleLength = 1,
) {
  return scale(colourList)
    .domain([range.lower, range.upper])
    .classes(scaleLength);
}

function generateColourScale(
  type,
  range = { upper: 1, lower: 0 },
  scaleLength = 1,
) {
  return scale(getColoursForType(type))
    .domain([range.lower, range.upper])
    .classes(scaleLength);
}

export function getColourForValue(value, type, range, scaleLength) {
  if (isNull(value) || isUndefined(value)) {
    return LIGHT_BLUE;
  }
  return generateColourScale(type, range, scaleLength)(value).hex();
}

export function getPercentileRange(data) {
  const sorted = sortBy(data);

  const quantile = (arr, q) => {
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    return sorted[base + 1] !== undefined
      ? sorted[base] + rest * (sorted[base + 1] - sorted[base])
      : sorted[base];
  };

  return [
    quantile(sorted, 0),
    quantile(sorted, 0.25),
    quantile(sorted, 0.5),
    quantile(sorted, 0.75),
    quantile(sorted, 1),
  ];
}

export function getColourForValueFromColourList(
  value,
  colourList,
  range,
  percentileRange,
) {
  const getPercentileIndex = () =>
    findIndex(percentileRange, percentileValue => value < percentileValue) - 1;

  const getColour = () =>
    percentileRange
      ? colourList[getPercentileIndex()]
      : generateColourScaleFromColourList(colourList, range)(value).hex();

  return isNull(value) || isUndefined(value) || !isNumber(value)
    ? LIGHT_BLUE
    : getColour();
}

export function calculateValueForStop(
  scaleStop,
  range = { upper: 1, lower: 0 },
) {
  if (!scaleStop.min && scaleStop.max) {
    return range.lower;
  }
  if (!scaleStop.max && scaleStop.min) {
    return range.upper;
  }
  const minMinusMax = (scaleStop.max - scaleStop.min) / 2;

  return minMinusMax + scaleStop.min;
}
