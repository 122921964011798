import React from 'react';
import { SvgIcon } from '../../components';

function DroneIcon(props) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0z" />
        <path d="M3 4.8h2L8 3h8l3 1.8h2V9H3z" />
        <path
          d="M8.3 4L5.5 5.7l-.2.1H4V8h16V5.8h-1.3l-.2-.1L15.7 4H8.3M8 3h8l3 1.8h2V9H3V4.8h2L8 3z"
          fill="currentColor"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          d="M6 7l1-1m1 1l1-1m9 1l-1-1m-1 1l-1-1"
        />
        <g transform="translate(0 2)" stroke="currentColor">
          <rect width="6" height="2" rx="1" stroke="none" />
          <rect x=".5" y=".5" width="5" height="1" rx=".5" />
        </g>
        <g stroke="currentColor">
          <path d="M8 9.5h8a2 2 0 01-2 2h-4a2 2 0 01-2-2z" stroke="none" />
          <path d="M8.6 10h6.8a.1.1 0 01.1.1h0a.9.9 0 01-.9.9H9.4a.9.9 0 01-.9-.9h0a.1.1 0 01.1-.1z" />
        </g>
        <g transform="translate(18 2)" stroke="currentColor">
          <rect width="6" height="2" rx="1" stroke="none" />
          <rect x=".5" y=".5" width="5" height="1" rx=".5" />
        </g>
        <g transform="translate(9.5 12)" stroke="currentColor">
          <circle cx="2.5" cy="2.5" r="2.5" stroke="none" />
          <circle cx="2.5" cy="2.5" r="2" />
        </g>
        <path
          d="M9 17.5a6.3 6.3 0 003 1 6.3 6.3 0 003-1M8 19a8 8 0 004 1.5 7.8 7.8 0 004-1.5m-9 1.5a9.5 9.5 0 005 2 9.5 9.5 0 005-2m2.5-10v3m-15-3v3"
          stroke="currentColor"
          strokeLinecap="round"
        />
      </g>
    </SvgIcon>
  );
}

export default DroneIcon;
