import Progress from 'react-progress-2';

function apiMiddleware() {
  return next => action => {
    if (action.type === 'API_REQUEST') {
      Progress.show();
    }
    if (
      action.type === 'API_REQUEST_SUCCESS' ||
      action.type === 'API_REQUEST_FAILURE'
    ) {
      Progress.hide();
    }
    return next(action);
  };
}

export default apiMiddleware;
