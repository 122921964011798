import { call, put, takeLatest, takeEvery, delay } from 'redux-saga/effects';
import format from 'date-fns/format';
import { YYYYMMDDHH_SS } from 'constants/dateTime';
import contourConfig from 'constants/contourConfig';
import fileDownloader from 'utilities/fileDownloader';
import {
  reportDownloadApplicationFile,
  slackDownloadApplicationFile,
} from 'services/reports';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';
import { fireGAEvent } from 'libraries/googleAnalytics/fireGAEvent';
import * as actionTypes from './actionTypes';
import {
  onsiteJohnDeereReceiveURL,
  onsiteJohnDeereReceiveNodes,
  onsiteFinishProcess,
  onsiteConversionError,
  onsiteJohnDeereTransferSuccess,
  onsiteEquipmentList,
} from './actions';
import {
  authenticate,
  pollNodes,
  selectNode,
  requestConversion,
  downloadFileURL,
  getOnsiteEquipmentList,
} from './onsiteClient';

function reportDownloadFileAction(reporter) {
  let actionType = 'DOWNLOADED_EQUIPMENT';
  if (reporter.file === 'shapefile') actionType = 'DOWNLOADED_SHAPEFILE';
  if (reporter.file === 'JohnDeere') actionType = 'UPLOADED_SHAPEFILE';

  const title = `Downloading ${reporter.file} for ${reporter.application}`;
  fireGAEvent(EventCategory.APPLICATIONS, EventAction[actionType], title);

  reportDownloadApplicationFile(reporter);
  slackDownloadApplicationFile(title, reporter);
}

function getURLForApplication(selectedApplication) {
  const application = contourConfig[selectedApplication];
  if (application) {
    return application.url;
  }
  return false;
}

export function* onsiteJohnDeereAuthenticate() {
  try {
    const response = yield call(authenticate);
    const authUrl = response && response.data && response.data.auth_url;

    if (authUrl) {
      yield put(onsiteJohnDeereReceiveURL(authUrl));
      window.open(authUrl, '_blank');
      let isAuthenticationCompleted = false;
      while (!isAuthenticationCompleted) {
        yield delay(7000);
        const res = yield call(pollNodes);
        const nodes = res.data;
        if (nodes && nodes.length) {
          yield put(onsiteJohnDeereReceiveNodes(nodes));
          isAuthenticationCompleted = true;
        }
      }
    }
  } catch (e) {
    /* do nothing */
  }
}

export function* onsiteJohnDeereAuthSaga() {
  yield takeLatest(actionTypes.ONSITE_JD_AUTH, onsiteJohnDeereAuthenticate);
}

export function* onsiteJohnDeerePollNodes() {
  const res = yield call(pollNodes);
  const nodes = res.data;
  if (nodes && nodes.length) {
    yield put(onsiteJohnDeereReceiveNodes(nodes));
  }
}

export function* onsiteJohnDeerePollNodesSaga() {
  try {
    yield takeLatest(
      actionTypes.ONSITE_JD_REQUEST_NODES,
      onsiteJohnDeerePollNodes,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('No longer authenticated: Trying again', error);
  } finally {
    yield put({ type: 'ONSITE_JD_AUTH' });
  }
}

export function* onsiteSelectNode(action) {
  try {
    yield call(
      selectNode,
      action.fieldSurveyId,
      getURLForApplication(action.applicationType),
      { node_id: action.nodeId },
      action.order,
    );
    yield put(onsiteJohnDeereTransferSuccess());
    yield delay(5000);

    reportDownloadFileAction(action.reporter);

    yield put(onsiteFinishProcess());
  } catch (err) {
    /* Do nothing */
  }
}

export function* onsiteSelectNodeSaga() {
  yield takeLatest(actionTypes.ONSITE_JD_SELECT_NODE, onsiteSelectNode);
}

const getFileName = () => {
  const timestamp = format(new Date(), YYYYMMDDHH_SS);

  return `${timestamp}.zip`;
};

export function* onsiteRequestFileConvesion(action) {
  const res = yield call(() =>
    requestConversion(
      action.fieldSurveyId,
      getURLForApplication(action.applicationId),
      action.format,
      action.order,
    ),
  );
  const trackingCode = res.data && res.data.tracking_code;

  let isFileProcessingCompleted = false;

  while (!isFileProcessingCompleted) {
    yield delay(5000);
    const response = yield call(() => downloadFileURL(trackingCode));

    if (response.data.status === 'Error') {
      isFileProcessingCompleted = true;
      yield put(() => onsiteConversionError(response.data.message));
    }

    if (response.data.url) {
      isFileProcessingCompleted = true;
      fileDownloader(response.data.url, getFileName());

      reportDownloadFileAction(action.reporter);

      yield put(onsiteFinishProcess());
    }
  }
}

export function* onsiteRequestConversionSaga() {
  yield takeEvery(
    actionTypes.ONSITE_REQUEST_CONVERSION,
    onsiteRequestFileConvesion,
  );
}

function* onsiteRequestEquipmentList() {
  try {
    const { data } = yield call(getOnsiteEquipmentList);

    const formattedList = data.map(equipmentItem => ({
      value: equipmentItem.id,
      label: [equipmentItem.brand, equipmentItem.format_name],
    }));

    yield put(onsiteEquipmentList(formattedList));
  } catch (error) {
    // eslint-disable-next-line
    console.error(
      'REQUEST ERROR: onsite equipment list failed to fetch',
      error,
    );
  }
}

export function* onsiteRequestEquipmentListSaga() {
  yield takeLatest(
    actionTypes.ONSITE_EQUIPMENT_LIST_REQUEST,
    onsiteRequestEquipmentList,
  );
}
