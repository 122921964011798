import config from 'config';
import { random } from 'chroma-js';
import generateSlackTable from 'utilities/generateSlackTable';
import slackNotify from '../slack/slackNotify';

export default function slackDownloadApplicationFile(
  title: string,
  data: Record<string, string | number>,
): Promise<any> {
  const message = {
    attachments: [
      {
        color: random().hex(),
        blocks: [
          {
            type: 'header',
            text: {
              type: 'plain_text',
              text: `:bell: ${title}`,
            },
          },
          ...generateSlackTable(data),
        ],
      },
    ],
  };

  return slackNotify(config.slackChannelApplicationFileDownload, message);
}
