import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

export default function EditPolygonIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path
          d="M299.3,413.2l-2.3,8.5"
          transform="translate(-285.4 -408.5)"
          fill="none"
          stroke="currentColor"
        />
        <path
          d="M298.6,411.8l-5.6-.3"
          transform="translate(-285.4 -408.5)"
          fill="none"
          stroke="currentColor"
        />
        <path
          d="M291.2,412.5l-2.9,7.2"
          transform="translate(-285.4 -408.5)"
          fill="none"
          stroke="currentColor"
          strokeDasharray="2"
        />
        <path
          d="M289.2,421.1l6.4,1.7"
          transform="translate(-285.4 -408.5)"
          fill="none"
          stroke="currentColor"
        />
        <g>
          <path
            d="M299.9,410.5a1.5,1.5,0,0,1,0,3A1.5,1.5,0,0,1,299.9,410.5Z"
            transform="translate(-285.4 -408.5)"
            fill="none"
          />
          <path
            d="M299.9,411a1,1,0,0,1,0,2A1,1,0,0,1,299.9,411Z"
            transform="translate(-285.4 -408.5)"
            fill="none"
            stroke="currentColor"
          />
        </g>
        <g>
          <path
            d="M291.9,409.5a1.5,1.5,0,0,1,0,3A1.5,1.5,0,0,1,291.9,409.5Z"
            transform="translate(-285.4 -408.5)"
            fill="none"
          />
          <path
            d="M291.9,410a1,1,0,0,1,0,2A1,1,0,0,1,291.9,410Z"
            transform="translate(-285.4 -408.5)"
            fill="none"
            stroke="currentColor"
          />
        </g>
        <g>
          <path
            d="M287.9,419.5a1.5,1.5,0,0,1,0,3A1.5,1.5,0,0,1,287.9,419.5Z"
            transform="translate(-285.4 -408.5)"
            fill="none"
          />
          <path
            d="M287.9,420a1,1,0,0,1,0,2A1,1,0,0,1,287.9,420Z"
            transform="translate(-285.4 -408.5)"
            fill="none"
            stroke="currentColor"
          />
        </g>
        <g>
          <path
            d="M296.9,421.5a1.5,1.5,0,0,1,0,3A1.5,1.5,0,0,1,296.9,421.5Z"
            transform="translate(-285.4 -408.5)"
            fill="none"
          />
          <path
            d="M296.9,422a1,1,0,0,1,0,2A1,1,0,0,1,296.9,422Z"
            transform="translate(-285.4 -408.5)"
            fill="none"
            stroke="currentColor"
          />
        </g>
      </g>
      <g>
        <path
          d="M306.9,419.1c.6.4,1.5,1.3.8,2.1l-7.5,8h0l-2.2-2.1h0l7.5-8A1,1,0,0,1,306.9,419.1Z"
          transform="translate(-285.4 -408.5)"
          fill="none"
        />
        <path
          d="M306.6,419.4c.2.4,1.2.9.7,1.4l-6.8,7.3a.6.6,0,0,1-.7.1c-.3-.4-1.3-.9-.8-1.4l6.8-7.4A.6.6,0,0,1,306.6,419.4Z"
          transform="translate(-285.4 -408.5)"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <g>
        <path
          d="M298.1,428l1.2,1.1-2.2,1.2Z"
          transform="translate(-285.4 -408.5)"
          fill="none"
        />
        <path
          d="M296,431.5l2-4.3,2.2,2Z"
          transform="translate(-285.4 -408.5)"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
