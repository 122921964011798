import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { AnalysisConfig } from '../types/Analyses';

const cropVariability: AnalysisConfig = {
  id: 4,
  name: 'cropVariability',
  displayName: 'Crop Variability',
  inAPI: 'Crop Variability',
  type: 'analysis',
  url: 'analysis-vari',
  clouds: true,
  config: analysisBuilder()
    .addUnits('unit.none')
    .addBarStep(1)
    .addDefaultRange({
      lower: 0,
      upper: 8,
    })
    .addTileFillOpacity(1)
    .addColourList([
      '#A70022',
      '#E03E25',
      '#FB8D4A',
      '#FFD47A',
      '#FFFFBC',
      '#CBEC7D',
      '#84CD61',
      '#24A252',
      '#006935',
    ])
    .build(),
  status: 'deprecated',
};

export default cropVariability;
