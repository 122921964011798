import React, { createContext, useState, useContext, useEffect } from 'react';

import { getSplitPath, useLocation, useHistory } from 'libraries/wings-router';

type Domain = 'map' | 'analytics' | 'admin';
type RouteMemoryContext = {
  currentDomain: Domain;
  domainLevelNavigate: (domain: Domain) => void;
};

const ModuleRouteMemoryContext = createContext<RouteMemoryContext>({
  currentDomain: 'map',
  domainLevelNavigate: () => null,
});
const RedirectRouteContext = createContext<any | undefined>(undefined);

const BLACK_LISTED_ROUTE_PATTERNS = ['not-authorised*', 'login', 'logout'];

type DomainDetails = {
  farmId?: string | null;
  search?: string | null;
  pathname?: string | null;
};

const defaultDomainDetails: DomainDetails = {
  farmId: null,
  search: null,
  pathname: null,
};

const defaultRouteMemory = {
  map: { ...defaultDomainDetails },
  analytics: { ...defaultDomainDetails },
  admin: { ...defaultDomainDetails },
};

type ModuleRouteMemoryProviderProps = {
  children: React.ReactChild;
};
/**
 * Stores currently selected domain url details
 * Keeps track of and remembers domain level path changes.
 * Maintains a record of pathname and search string for each domain
 * Provides a useful url to redirect back to when logging in
 * todo: decouple the redirect provider and the router memory.
 * todo: Create a single exportable "Provider" for the router (include React router provider)
 *
 * NOTE: router is single source of truth:
 *       memory should reflect the url. i.e changes here should not trigger url changes
 */
export function ModuleRouteMemoryProvider({
  children,
}: ModuleRouteMemoryProviderProps): React.ReactElement {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const [currentDomain, setCurrentModule] = useState<Domain>('map');
  const [routeMemory, setRouteMemory] = useState(defaultRouteMemory);
  const [redirectRoutes, setRedirectRoutes] = useState<(string | null)[]>([
    null,
    null,
    null,
  ]);

  useEffect(() => {
    // save the modules pathname and search string
    const splitPath = getSplitPath(pathname);
    const urlModule = splitPath[0] as Domain;
    const farmId = splitPath[1];

    if (urlModule) {
      setCurrentModule(urlModule);

      setRouteMemory(prevMemory => {
        const updatedModule = { farmId, search, pathname };
        return { ...prevMemory, [urlModule]: updatedModule };
      });
    }

    // keep a list of urls not to redirect too
    const skipRoute = BLACK_LISTED_ROUTE_PATTERNS.some(blackRoute => {
      const regex = RegExp(blackRoute);
      return regex.test(pathname);
    });

    if (!skipRoute) {
      setRedirectRoutes(prevRoutes => {
        let updatedRoutes = prevRoutes;
        updatedRoutes.unshift(`${pathname}${search}`);
        updatedRoutes = updatedRoutes.filter((_, index) => index < 3);

        return updatedRoutes;
      });
    }
  }, [pathname, search, currentDomain]);

  const domainLevelNavigate = (domain: Domain) => {
    const farmId = getSplitPath(pathname)[1];

    // `admin` is a special case that should always map to the selected farm if there is one
    if (domain === 'admin') {
      return history.push({
        pathname: Number(farmId) ? `/${domain}/farms/${farmId}` : '/admin',
        state: { farmId },
      });
    }

    // if a path exists for the domain, use it from the memory
    const nextPathname = routeMemory[domain]?.pathname as string;
    if (nextPathname) {
      const nextSearch = routeMemory[domain].search as string;
      return history.push({
        pathname: nextPathname,
        search: nextSearch,
        state: { farmId },
      });
    }

    // allow root domain components to set up default url
    return history.push({ pathname: `/${domain}`, state: { farmId } });
  };

  return (
    <ModuleRouteMemoryContext.Provider
      value={{ currentDomain, domainLevelNavigate }}
    >
      <RedirectRouteContext.Provider value={redirectRoutes}>
        {children}
      </RedirectRouteContext.Provider>
    </ModuleRouteMemoryContext.Provider>
  );
}

/**
 * returns the current module name and function to redirect to new module
 */
export const useTopLevelNavigate = (): RouteMemoryContext =>
  useContext(ModuleRouteMemoryContext);

/**
 * Returns the most relevant referred route.
 * By most relevant we mean it only stores routes from within the app after login.
 * Use it to navigate a user back to where they came from post login
 */
export function useRedirectRoute(): string | null {
  const redirectRoute = useContext(RedirectRouteContext);
  const getRelevantRoute = () => {
    if (redirectRoute[0] && !redirectRoute[1]) return redirectRoute[0];
    if (redirectRoute[1]) return redirectRoute[1];
    return null;
  };

  return getRelevantRoute();
}
