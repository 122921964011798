import React from 'react';
import classNames from 'classnames';
import { makeStyles } from 'libraries/wings-ui/styles';
import ToolTip from '../ToolTip';

const useStyles = makeStyles(({ palette, typography }: any) => ({
  bold: {
    fontWeight: typography.fontWeightMedium,
  },
  root: {
    fontFamily: typography.fontFamily,
    color: ({ color }: any) => color || 'inherit',
  },
  body1: {
    fontSize: 16,
  },
  body2: {
    fontSize: 14,
  },
  form: {
    lineHeight: '1.2',
    padding: '8px',
  },
  title1: {
    fontSize: 26,
  },
  title2: {
    fontSize: 22,
    fontWeight: typography.fontWeightBold,
  },
  title3: {
    fontSize: 20,
  },
  title4: {
    fontSize: 18,
    fontWeight: typography.fontWeightMedium,
  },
  caption: {
    fontSize: 12,
    color: `${palette.text.minor}!important`,
  },
  text: {},
  info: {
    color: `${palette.primary.main}!important`,
  },
  success: {
    color: `${palette.success.main}!important`,
  },
  error: {
    color: `${palette.error.main}!important`,
  },
}));

function truncateString(str: string, num: number) {
  if (!str) return str;

  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num - 3)}...`;
}

type TypoVariant =
  | 'body1'
  | 'body2'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'title4'
  | 'caption'
  | 'form';

type ThemeVariant = 'info' | 'success' | 'error' | 'text';

type Props = {
  className?: string;
  children?: React.ReactNode;
  variant?: TypoVariant;
  theme?: ThemeVariant;
  bold?: boolean;
  truncate?: number;
  color?: string;
  onClick?: () => void;
};

function Typography({
  className,
  children,
  variant = 'body1',
  theme = 'text',
  bold,
  truncate,
  color,
  onClick,
}: Props): React.ReactElement {
  const classes = useStyles({ color });

  // only truncate if necessary
  if (typeof children === 'string') {
    let message = children || '';
    if (truncate && message.length > truncate) {
      const origMessage = message;
      message = truncateString(message, truncate);

      return (
        <ToolTip content={origMessage}>
          <div
            onClick={onClick}
            className={classNames(className, classes.root, {
              [classes[variant]]: variant,
              [classes[theme]]: theme,
              [classes.bold]: bold,
            })}
          >
            {message}
          </div>
        </ToolTip>
      );
    }
  }

  return (
    <div
      onClick={onClick}
      className={classNames(className, classes.root, {
        [classes[variant]]: variant,
        [classes[theme]]: theme,
        [classes.bold]: bold,
      })}
    >
      {children}
    </div>
  );
}

Typography.displayName = 'Typography';

export default Typography;
