import React from 'react';
import { SvgIcon } from '../../components';

function TickIcon(props) {
  return (
    <SvgIcon {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="3"
        fill="none"
      >
        <path d="M3 12.58l5.31 5.3M8.3 17.88L20.7 5.5" />
      </g>
    </SvgIcon>
  );
}

export default TickIcon;
