import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function GrowingSeasonsIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <g>
          <path
            d="M297.1,418.6c4,.3,4,5.2,0,5.5Z"
            transform="translate(-285.4 -409.3)"
            fill="none"
          />
          <path
            d="M297.4,418.9v4.9c3.2-.5,3.2-4.5,0-4.9m-.5-.6c4.6.2,4.6,5.9,0,6Z"
            transform="translate(-285.4 -409.3)"
            fill="currentColor"
          />
        </g>
        <line
          x1="5"
          y1="12"
          x2="1"
          y2="12"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <line
          x1="7"
          y1="18"
          x2="5"
          y2="20.5"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <line
          x1="4"
          y1="3.5"
          x2="7"
          y2="6"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <line
          x1="12"
          y1="0.5"
          x2="12"
          y2="3.5"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <line
          x1="12"
          y1="23.5"
          x2="12"
          y2="19.5"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          d="M297.7,421.3h8.7"
          transform="translate(-285.4 -409.3)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <path
          d="M297.6,420.7l5.5-5.4"
          transform="translate(-285.4 -409.3)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="16.5"
          y1="12"
          x2="18.5"
          y2="10"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="15.2"
          y1="8.4"
          x2="15.2"
          y2="6.2"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="19.5"
          y1="12"
          x2="21.5"
          y2="10"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="16.9"
          y1="6.8"
          x2="16.9"
          y2="4.6"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="16.5"
          y1="12"
          x2="18.5"
          y2="14"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="15.2"
          y1="8.4"
          x2="17.4"
          y2="8.5"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="19.5"
          y1="12"
          x2="21.5"
          y2="14"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="16.9"
          y1="6.8"
          x2="19.1"
          y2="6.8"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <path
          d="M297.6,421.8l5.8,5.6"
          transform="translate(-285.4 -409.3)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="15.3"
          y1="15.5"
          x2="15.4"
          y2="17.8"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="17.1"
          y1="17.2"
          x2="17.2"
          y2="19.6"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="15.3"
          y1="15.5"
          x2="17.7"
          y2="15.4"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <line
          x1="17.1"
          y1="17.2"
          x2="19.5"
          y2="17.2"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="0.5px"
        />
        <g>
          <path
            d="M297.4,415.9v10.9A5.5,5.5,0,0,1,297.4,415.9Z"
            transform="translate(-285.4 -409.3)"
            fill="none"
          />
          <path
            d="M296.9,426.2v-9.8a5,5,0,0,0,0,9.8m1,1.1c-8-.2-8-11.7,0-12Z"
            transform="translate(-285.4 -409.3)"
            fill="currentColor"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default GrowingSeasonsIcon;
