import { InMemoryCache } from '@apollo/client';
/**
 * This cache is use the typePolicies to perform a cache redirect on some fields.
 * Read more about this behaviour here: https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
 */
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        /**
         * Custom field redirect to fetch farm growing seasons from the already fetched farm
         */
        //   growingSeasons(_, { args, toReference, readField }) {
        //     if (!args?.farmId) return null;
        //     const ref = toReference({
        //       __typename: 'Farm',
        //       id: args.farmId,
        //     });
        //     const cachedGrowingSeasons = readField('growingSeasons', ref);
        //     console.log('%c fetch policy', 'color: red', cachedGrowingSeasons);
        //     if (cachedGrowingSeasons) return cachedGrowingSeasons;
        //     return { farm: '' };
        //   },
      },
    },
  },
});

export default cache;
