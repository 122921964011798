import { findIndex, findLastIndex } from 'lodash-es';

function histogramReducer(state = [], action = {}) {
  if (action.type === 'UPDATE_HISTOGRAM') {
    const histogramValues = [...action.histogramValues];
    const minValue = Math.max(...histogramValues) * 0.02;
    const min = findIndex(histogramValues, value => value > minValue);
    const max = findLastIndex(histogramValues, value => value > minValue);
    return {
      ...state,
      [action.fieldSurveyId]: {
        min: min >= 0 ? min : 0,
        max: max > 0 ? max : histogramValues.length,
        values: action.histogramValues,
        colourList: action?.colourList ?? ['#B31C08', '#E6E60B', '#1FB308'],
      },
    };
  }

  if (action.type === 'UPDATE_HISTOGRAM_MIN_MAX') {
    return {
      ...state,
      [action.fieldSurveyId]: {
        ...state[action.fieldSurveyId],
        min: action.min,
        max: action.max,
      },
    };
  }

  return state;
}

export default histogramReducer;
