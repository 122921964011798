import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function AddFieldIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path
          d="M301.2,424H286.8v-5.3c2.2-1.9,12.5-2,14.4-1.5Z"
          transform="translate(-286 -409)"
          fill="none"
        />
        <path
          d="M295,417.4a18,18,0,0,0-7.7,1.6v4.5h13.4v-5.8a43.4,43.4,0,0,0-5.7-.3m0-1c5.5,0,6.9.4,6.7.4v7.7H286.3v-6S289.4,416.4,295,416.4Z"
          transform="translate(-286 -409)"
          fill="currentColor"
        />
      </g>
      <path
        d="M301,417.1c-2.5,1.4-3.3,4.4-3.8,7.1"
        transform="translate(-286 -409)"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M291.3,417.2a12.1,12.1,0,0,0-4.6,4.9"
        transform="translate(-286 -409)"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M296.1,416.8a11.2,11.2,0,0,0-4.2,7.4"
        transform="translate(-286 -409)"
        fill="none"
        stroke="currentColor"
      />
      <g>
        <path
          d="M291.1,412c2.8,0,2.8,4.3,0,4.3S288.4,412,291.1,412Z"
          transform="translate(-286 -409)"
          fill="none"
        />
        <path
          d="M291.1,412.5a1.6,1.6,0,0,1,0,3.3A1.6,1.6,0,0,1,291.1,412.5Z"
          transform="translate(-286 -409)"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <line
        x1="8"
        y1="3"
        x2="9"
        y2="2"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="5.3"
        y1="2"
        x2="5.3"
        y2="1"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="3"
        y1="3"
        x2="2"
        y2="2"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="8.6"
        y1="5.3"
        x2="9.6"
        y2="5.3"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="1"
        y1="5.3"
        x2="2"
        y2="5.3"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="17.9"
        y1="22.9"
        x2="17.9"
        y2="14.2"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <line
        x1="22.2"
        y1="18.5"
        x2="13.5"
        y2="18.5"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
    </SvgIcon>
  );
}

export default AddFieldIcon;
