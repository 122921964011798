import { useState, useEffect } from 'react';
import { useUser } from '@hummingbirdtechgroup/wings-auth';

type UseAuthorisationOptions = {
  check?: 'permissions' | 'role';
};

const defaultOptions: UseAuthorisationOptions = {
  check: 'permissions',
};

/**
 * Checks if a user has the correct authorisation
 * Takes options to check something else other than the users permissions array
 * Returns a boolean if permitted and an error message.
 */
function useAuthorisation(
  authorise: string | string[],
  options: UseAuthorisationOptions = defaultOptions,
): readonly [boolean, string | undefined] {
  const [user] = useUser();
  const [permitted, setPermitted] = useState(true);
  const [permissionFailedMessage, setPermissionFailedMessage] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (!user) return;
    const { permissions, role } = user;

    if (options.check === 'permissions') {
      const isPermitted = permissions.includes(authorise as string);

      setPermitted(isPermitted);
      if (!isPermitted)
        setPermissionFailedMessage(
          'You are not permitted to access this feature',
        );
    }

    if (options.check === 'role') {
      const hasRole = authorise.includes(role);
      setPermitted(hasRole);
      if (!hasRole)
        setPermissionFailedMessage(
          'You do not have the correct role to access this feature',
        );
    }
  }, [options, authorise, user]);

  return [permitted, permissionFailedMessage] as const;
}

export default useAuthorisation;
