import React from 'react';
import PropTypes from 'prop-types';
import { radioStyles } from '.';
import { makeStyles } from '../../styles';

const useStyles = makeStyles(() => ({
  '@global': radioStyles,
}));

function Radio({
  id,
  className,
  name,
  checked,
  value,
  label,
  onChange,
  disabled,
}) {
  useStyles();
  const handleChange = () => {
    if (disabled) return;
    onChange({
      target: {
        id,
        value,
        name,
      },
    });
  };

  return (
    <div className={`Radio ${className}`} onClick={handleChange}>
      <input
        type="radio"
        id={id}
        key={`input-${name}`}
        name={name}
        checked={checked}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      {/* Our Radio styling gets applied to this span */}
      <span />

      {label && <label htmlFor={id}>{label}</label>}
    </div>
  );
}

Radio.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  id: '',
  className: '',
  name: '',
  checked: false,
  value: '',
  onChange: () => {},
  disabled: false,
};

export default Radio;
