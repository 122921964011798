import { networkStatusAction } from 'services/network/networkActionCreators';

function dispatchNetworkAction(dispatch, onLine) {
  dispatch(networkStatusAction(onLine));
}

function onNetworkMessage(dispatch, event) {
  const { eventType, onLine } = event.data;

  if (eventType === 'NETWORK_STATUS') {
    dispatchNetworkAction(dispatch, onLine);
  }
}

function serviceWorkerEventMiddleware(store) {
  const { dispatch } = store;

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', event =>
      onNetworkMessage(dispatch, event),
    );
  }

  window.addEventListener('online', () =>
    dispatchNetworkAction(dispatch, true),
  );
  window.addEventListener('offline', () =>
    dispatchNetworkAction(dispatch, false),
  );

  return next => action => next(action);
}

export default serviceWorkerEventMiddleware;
