const cruddybrowserStyles = {
  '.browsehappy': {
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: 999,
    backgroundColor: 'var(--c-body-bg)',
    margin: '0',
    textAlign: 'center',
    padding: '3em',
  },
  '.browsehappy p': { maxWidth: '400px' },
  '.browsehappy ~ *': { display: 'none' },
  '.logo-fallback': {
    background: "url('../images/logo-circle.png') no-repeat",
    height: '156px',
    width: '156px',
    margin: 'auto',
  },
};

export default cruddybrowserStyles;
