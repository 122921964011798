import React from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function GreenHumingbirdLogoIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 133 101.97">
      <path d="M10.92 0L46 7.33 11.65 5.8z" fill="#a8cf3f" />
      <path d="M11.52 5.63L46 7.31l-27.6 5.18z" fill="#18a94a" />
      <path d="M0 8.77l47.9 9.68-46.61-3.22z" fill="#a8cf3f" />
      <path d="M46 7.28l1.72 11.23-30-6.09z" fill="#1d5780" />
      <path d="M46 7.31L64.63 19.2l-16.88-.7z" fill="#78c053" />
      <path d="M1.28 15.23l46.85 3.21L14.78 28z" fill="#18a94a" />
      <path d="M47.67 18.44l-2.15 23.19L14.79 28z" fill="#78c053" />
      <path d="M47.53 18.34l22.22 9.49-24.23 13.75z" fill="#a8cf3f" />
      <path d="M64.63 19.2l5.1 8.63-22-9.37z" fill="#1d5780" />
      <path d="M64.63 19.19l10.42 3.27-5.35 5.41z" fill="#78c053" />
      <path d="M45.49 41.6l24.26-13.8L51 49.42z" fill="#1d5780" />
      <path d="M84.58 12.21l3.24 15.18-18.24.47z" fill="#18a94a" />
      <path d="M69.65 27.86l18.18-.47-13 13.2z" fill="#1d5780" />
      <path d="M69.66 27.83l5.16 12.76L51 49.41z" fill="#78c053" />
      <path d="M87.82 27.39l3.46 7-16.47 6.19z" fill="#78c053" />
      <path d="M51 49.41l17-6.31-25.9 31.16z" fill="#a8cf3f" />
      <path d="M68 43.11l-9.13 25-16.77 6.14z" fill="#18a94a" />
      <path d="M27.85 79.49l31-11.41L27.92 86z" fill="#a8cf3f" />
      <path d="M58.89 68.06L26.06 95.68l-.57-8.33z" fill="#18a94a" />
      <path d="M33.15 89.68l8.77 1.18L18.58 102z" fill="#78c053" />
      <path d="M58.5 68.31L41.91 90.86l-8.73-1.18z" fill="#1d5780" />
      <path d="M58.71 67.91l5 3.1-8.06 1.11z" fill="#78c053" />
      <path d="M68 43.1l10.4 11.95-19.52 13z" fill="#78c053" />
      <path d="M78.41 55l-8.78 10.55-10.76 2.5z" fill="#1d5780" />
      <path d="M91.27 34.44L78.41 55.05 68 43.11z" fill="#a8cf3f" />
      <path d="M87.82 27.4l17.58.36-14.13 6.7z" fill="#a8cf3f" />
      <path d="M84.58 12.22L101 20l-13.18 7.42z" fill="#a8cf3f" />
      <path d="M105.29 17.6l.1 10.17-17.55-.36z" fill="#78c053" />
      <path d="M99.62 8.53L101 20.11l-16.45-7.9z" fill="#78c053" />
      <path d="M99.62 8.53L112.4 21l-12-5.69z" fill="#a8cf3f" />
      <path d="M105.29 17.6l7.09 3.4-7 6.79z" fill="#18a94a" />
      <path d="M108.79 17.46l18 3.72-14.41-.18z" fill="#1d5780" />
      <path d="M108.78 17.47l19.76 1.84-1.8 1.87z" fill="#78c053" />
      <path d="M128.53 19.31l5 3-6.78-1.14z" fill="#a8cf3f" />
      <path d="M100.44 15.3l4.87 2.3-4.31 2.45z" fill="#1d5780" />
    </SvgIcon>
  );
}

export default GreenHumingbirdLogoIcon;
