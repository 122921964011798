import React from 'react';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { Box, Button } from '@hummingbirdtechgroup/wings-ui';

import { makeStyles, Theme } from '../../styles';
import { UploadFileIcon } from '../../icons';
import { P, LI } from '..';

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: spacing(4),
    borderWidth: 3,
    borderRadius: spacing(),
    borderColor: palette.text.hint,
    borderStyle: 'dashed',
    color: palette.text.hint,
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  blockSpacing: {
    margin: spacing(1, 0),
    width: '100%',
  },
  file: {
    margin: spacing(),
  },
}));
type Props = {
  onDrop: (acceptedFiles: any) => void;
  /** Array of accepted file extensions in the format `.ext` */
  supportedFileTypes: string[];
  disableAcceptedFiles?: boolean;
  disableRejectedFiles?: boolean;
};

function DropZone({
  onDrop,
  supportedFileTypes,
  disableAcceptedFiles,
  disableRejectedFiles,
}: Props): React.ReactElement {
  const classes = useStyles();

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: supportedFileTypes.join(','),
  });
  const acceptedFileItems = acceptedFiles.map(file => (
    <P key={file.name} variant="caption">
      {file.name} - {file.size} bytes
    </P>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <>
      <P key={file.name} variant="caption" theme="error">
        {file.name} - {file.size} bytes
      </P>
      <ul>
        {errors.map(e => (
          <LI variant="caption" theme="error" key={e.code}>
            {e.message}
          </LI>
        ))}
      </ul>
    </>
  ));

  return (
    <>
      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        <UploadFileIcon size="xl" />
        <p>
          <FormattedMessage
            id="fileUploader.dragNdropFiles"
            defaultMessage="Drag 'n' drop some files here"
          />
        </p>
      </div>
      <Box className={classes.blockSpacing} justifyContent="center">
        <Button theme="primary" onClick={open}>
          <FormattedMessage id="fileUploader.selectFile" />
        </Button>
      </Box>
      <Box className={classes.blockSpacing} flexDirection="column">
        {!disableAcceptedFiles && acceptedFileItems.length > 0 && (
          <>
            <P variant="body2">
              <FormattedMessage
                id="fileUploader.filesSelected"
                defaultMessage="Selected Files:"
              />
            </P>
            {acceptedFileItems}
          </>
        )}
        {!disableRejectedFiles && fileRejectionItems.length > 0 && (
          <>
            <P variant="body2">
              <FormattedMessage
                id="fileUploader.filesSelected"
                defaultMessage="Rejected Files. Please check what files are accepted"
              />
            </P>
            {fileRejectionItems}
          </>
        )}
      </Box>
    </>
  );
}

export default DropZone;
