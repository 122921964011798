import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { SurveySourceType } from 'types/surveySourceType';
import { ApplicationConfig } from './ApplicationConfig';

const nitrogenMeanSizeApplication: ApplicationConfig = {
  id: 36,
  name: 'nitrogenMeanSizeApplication',
  displayName: 'Nitrogen (Mean size) (Contoured)',
  inAPI: 'Nitrogen Mean Size Applications',
  type: 'application',
  apiEndPoint: (fieldSurveyId, farmId) =>
    `/api/v1/products/?farm=${farmId}&survey=${fieldSurveyId}&names=application-nitrogen/analysis-mean_size`,
  url: 'application-nitrogen/analysis-mean_size',
  base: 'plantMeanSize',
  surveySources: {
    [SurveySourceType.UAV]: true,
    [SurveySourceType.SATELLITE]: true,
  },
  config: analysisBuilder()
    .addUnits('unit.kilogramsPerHectare')
    .addBarStep(1)
    .addDefaultRange({
      lower: 0,
      upper: 120,
    })
    .addColourList(['#FFF987', '#FBAD5E', '#DD635A', '#A12660', '#4A0B5E'])
    .build(),
};

export default nitrogenMeanSizeApplication;
