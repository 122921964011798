function selectedApplicationReducer(state = null, action = {}) {
  switch (action.type) {
    case 'SELECT_APPLICATION':
      return action.application || state;
    default:
      return state;
  }
}

export default selectedApplicationReducer;
