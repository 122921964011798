import range from 'modules/FieldsMap/partials/Applications/utils/range.utility';

export default function transformResponseToState(nitrogenApplication) {
  return {
    ...nitrogenApplication,
    concentration: nitrogenApplication.concentration,
    variation: nitrogenApplication.range,
    range: range(nitrogenApplication.application),
  };
}
