import React from 'react';
import {
  Slider,
  Rail,
  Tracks,
  Handles,
  HandlesObject,
  CustomMode,
} from 'react-compound-slider';
import { makeStyles } from 'libraries/wings-ui/styles';
import { Handle } from './Handle';
import { Track } from './Track';

const useStyles = makeStyles(({ palette, spacing }: any) => ({
  rail: {
    position: 'absolute',
    width: '100%',
    height: 5,
    borderRadius: spacing(0.25),
    cursor: 'pointer',
    backgroundColor: palette.grey.tint,
  },
}));

const defaultSliderStyle = {
  position: 'relative',
  width: '100%',
  height: 16,
  paddingTop: 6,
};

type Props = {
  /** Customise the handles used by RangeSlider */
  children?: (handles: HandlesObject) => React.ReactNode;
  domain: [number, number];
  mode?: 2 | 1 | 3 | CustomMode;
  onChange: (values: readonly number[]) => void;
  rangeValues: number[];
  rootStyle?: any;
  step?: number;
};

function HistogramRangeSlider({
  children,
  domain = [0, 1],
  onChange,
  mode = 1,
  rangeValues,
  rootStyle = defaultSliderStyle,
  step = 0.001,
}: Props): React.ReactElement {
  const classes = useStyles();

  return (
    <Slider
      mode={mode}
      step={step}
      domain={domain}
      rootStyle={rootStyle}
      onChange={onChange}
      values={rangeValues}
    >
      <Rail>
        {({ getRailProps }) => (
          <div className={classes.rail} {...getRailProps()} />
        )}
      </Rail>

      <Handles>
        {({ handles, getHandleProps, ...rest }) =>
          children ? (
            children({ handles, getHandleProps, ...rest })
          ) : (
            <>
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </>
          )
        }
      </Handles>
      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) => (
          // Do we need this div?
          <>
            {tracks.map(({ id, source, target }) => (
              <Track
                key={id}
                source={source}
                target={target}
                getTrackProps={getTrackProps}
              />
            ))}
          </>
        )}
      </Tracks>
    </Slider>
  );
}

export default HistogramRangeSlider;
