import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function AddZoneIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <line
        x1="17.1"
        y1="21.9"
        x2="17.1"
        y2="11.6"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <line
        x1="22.2"
        y1="16.7"
        x2="12"
        y2="16.7"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <path
        d="M297.7,413.2l-2.1,7.4"
        transform="translate(-285.4 -409.1)"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M297,412l-4.9-.3"
        transform="translate(-285.4 -409.1)"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M290.6,412.6l-2.5,5.9"
        transform="translate(-285.4 -409.1)"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M293.9,412.1l-2.9,8.4"
        transform="translate(-285.4 -409.1)"
        fill="none"
        stroke="currentColor"
        strokeDasharray="2"
      />
      <path
        d="M288.8,420.1l5.4,1.7"
        transform="translate(-285.4 -409.1)"
        fill="none"
        stroke="currentColor"
      />
      <g>
        <path
          d="M297.9,411.1c1.9-.1,1.9,3,0,3S295.9,411,297.9,411.1Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
        />
        <path
          d="M297.9,411.6c1.3-.1,1.3,2,0,2S296.6,411.5,297.9,411.6Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <g>
        <path
          d="M290.9,410.1c1.9-.1,1.9,3,0,3S288.9,410,290.9,410.1Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
        />
        <path
          d="M290.9,410.6c1.3-.1,1.3,2,0,2S289.6,410.5,290.9,410.6Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <g>
        <path
          d="M287.9,418.1c1.9-.1,1.9,3,0,3S285.9,418,287.9,418.1Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
        />
        <path
          d="M287.9,418.6c1.3-.1,1.3,2,0,2S286.6,418.5,287.9,418.6Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
          stroke="currentColor"
        />
      </g>
      <g>
        <path
          d="M294.9,420.1c1.9-.1,1.9,3,0,3S292.9,420,294.9,420.1Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
        />
        <path
          d="M294.9,420.6c1.3-.1,1.3,2,0,2S293.6,420.5,294.9,420.6Z"
          transform="translate(-285.4 -409.1)"
          fill="none"
          stroke="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export default AddZoneIcon;
