import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { get, isArray } from 'lodash-es';

import {
  requestApplicationErrorAction,
  receiveApplicationAction,
} from './applicationActionCreators';
import {
  getApplicationClient,
  saveApplicationClient,
  updateApplicationClient,
} from './applicationClient';

export function* requestApplication(action) {
  try {
    const response = yield call(
      getApplicationClient,
      action.applicationKey,
      action.fieldSurveyId,
      action.unitSystem,
      action.farmId,
    );
    yield put(
      receiveApplicationAction(
        action.applicationKey,
        action.fieldSurveyId,
        isArray(response.data) ? get(response, 'data[0]', {}) : response.data,
      ),
    );
  } catch (e) {
    yield put(
      requestApplicationErrorAction(
        action.applicationKey,
        action.fieldSurveyId,
        e.response,
      ),
    );
  }
}

export function* requestApplicationSaga() {
  yield takeEvery('REQUEST_APPLICATION', requestApplication);
}

export function* saveApplication(action) {
  try {
    const response = yield call(
      saveApplicationClient,
      action.applicationKey,
      action.fieldSurveyId,
      action.application,
    );

    yield put(
      receiveApplicationAction(
        action.applicationKey,
        action.fieldSurveyId,
        response.data,
      ),
    );
  } catch (e) {
    /* do nothing */
  }
}

export function* saveApplicationSaga() {
  yield takeLatest('SAVE_APPLICATION', saveApplication);
}

export function* updateApplication(action) {
  try {
    const response = yield call(
      updateApplicationClient,
      action.applicationKey,
      action.fieldSurveyId,
      action.application,
    );

    yield put(
      receiveApplicationAction(
        action.applicationKey,
        action.fieldSurveyId,
        response.data,
      ),
    );
  } catch (e) {
    /* do nothing */
  }
}

export function* updateApplicationSaga() {
  yield takeLatest('UPDATE_APPLICATION', updateApplication);
}
