const hummingBirdLeafletStyles = {
  '.leaflet-bar': { boxShadow: 'none' },
  '.leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-in, .leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-out': {
    display: 'block',
    borderRadius: '100%',
    border: '1px solid var(--hb-light-grey)',
    color: 'var(--hb-blue)',
    backgroundColor: 'white',
    appearance: 'initial',
    fontSize: '25px',
    width: '30px',
    height: '30px',
    lineHeight: '27px',
    marginBottom: '3px',
    padding: '0',
    textAlign: 'center',
    textDecoration: 'none',
  },
  '.leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-in:hover, .leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-out:hover': {
    textDecoration: 'none',
  },
  '.leaflet-control-container .leaflet-control-zoom .leaflet-disabled': {
    color: 'var(--c-tint-light)',
  },
  '.map--static .leaflet-bottom, .map--static .leaflet-top': {
    position: 'absolute',
  },
  '.map-icon-label': {
    transform: 'translateX(-50%)',
    padding: '3px 5px',
    background: 'color(var(--c-info) alpha(45%))',
    borderRadius: '2px',
    color: 'var(--c-text-light)',
    fontSize: '14px',
    border: '1px solid var(--c-tint)',
    lineHeight: '14px',
  },
  '.map-icon-label:hover': { textDecoration: 'underline' },
  '.map-icon-desiccation-label': {
    transform: 'translateX(-50%)',
    zIndex: 99999,
    fontWeight: 'bold',
    color: 'var(--c-text-main)',
    padding: '3px 7px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    display: 'inline-block',
    borderRadius: '2px',
  },
  '.leaflet-control-scale-line': {
    border: '2px solid #00a5cf',
    borderWidth: '0 2px',
    backgroundColor: 'color(var(--c-text-main) alpha(80%))',
    color: 'var(--c-text-light)',
  },
  '.leaflet-control-scale-line:not(:first-child)': {
    marginTop: '1px',
    borderTop: 'none',
  },
  '.leaflet-right .leaflet-control-scale-line': {
    cssFloat: 'right',
    clear: 'right',
  },
  '.leaflet-marker-icon:hover': { zIndex: 9999 },
  '.leaflet-div-icon': {
    border: 'none',
    background: 'transparent',
    width: 'auto !important',
    whiteSpace: 'nowrap',
    height: 'auto !important',
    pointerEvents: 'none',
  },
  '.leaflet-popup-pane': {
    transform: 'translate3d(0, 0, 1px)',
    zIndex: '999999 !important',
  },
  '.leaflet-popup-content-wrapper': { borderRadius: '5px' },
  '.leaflet-popup-content': { margin: '5px', minWidth: '267px' },
  '.leaflet-popup-tip': { height: '18px', width: '18px' },
  '.leaflet-container a.leaflet-popup-close-button': {
    color: 'var(--c-negative)',
    font: 'inherit',
    fontWeight: 'normal',
    fontSize: '24px',
    padding: '0',
    lineHeight: '25px',
    top: '3px',
    right: '3px',
    background: 'var(--c-island-bg)',
    borderRadius: '50px',
    height: '25px',
    width: '25px',
    border: '1px solid currentColor',
  },
  '.leaflet-container a.leaflet-popup-close-button:hover': {
    textDecoration: 'none',
    color: 'color(var(--c-negative) alpha(80%))',
  },
  '.leaflet-marker-icon': { zIndex: '200 !important' },
};

export default hummingBirdLeafletStyles;
