import { Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';

/**
 * A Route component to provide paramaterised transactions to Sentry Profiler
 * See docs here: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
const SentryRoute = Sentry.withSentryRouting(Route);

export default SentryRoute;
