const mainStyles = {
  '*,\n*:before,\n*:after': { boxSizing: 'border-box' },
  '*': { fontFamily: 'inherit', lineHeight: 'inherit', color: 'inherit' },
  'html,\nbody,\nmain,\nmain > div': { height: '100%' },
  body: { background: 'white', overflow: 'hidden' },
  hr: {
    borderTop: '0',
    borderBottom: '1px solid var(--c-info)',
  },
  '.contain': { position: 'relative', display: 'block' },
};

export default mainStyles;
