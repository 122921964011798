import React, { ReactElement } from 'react';
import { SvgIcon } from '../../components';
import type { SvgIconProps } from '../../components/SvgIcon';

function AddFarmIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <line
        x1="17.9"
        y1="22.9"
        x2="17.9"
        y2="14.2"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <line
        x1="22.2"
        y1="18.5"
        x2="13.5"
        y2="18.5"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <path
        d="M294.9,419.5a2.2,2.2,0,0,0-2.2,2.2h0v3.6l.3.2,2,2.2h-.6v5.2h2.8V421.7a2.2,2.2,0,0,0-2.3-2.2Zm-5.5,4.5-3.6,1.6-2,2.1h.5v5.2h3.4v-2.5h3.4v2.5h3.3v-5.2h.6l-2-2.2Zm-.9,1.8h1.8V427h-1.8Zm-.6,4.8v2.1h1.4v-2.1Zm1.6,0v2.1h1.4v-2.1Z"
        transform="translate(-282.3 -418.5)"
        fill="none"
        stroke="currentColor"
        opacity="0.9900000095367432"
      />
    </SvgIcon>
  );
}

export default AddFarmIcon;
