import axios from 'axios';
import store from 'App/reduxState/store';
import {
  makeRequest,
  requestSuccess,
  requestFailure,
} from 'services/api/apiActionCreators';
import errorCodes from 'constants/errorCodes';
import authInterceptorFactory from '../authInterceptorFactory';

async function request(requestData, token, onProgress) {
  const { requestName } = requestData;
  const apiClient = axios.create({
    onUploadProgress: onProgress,
  });

  if (!store.getState().networkOnline) {
    store.dispatch(
      requestFailure(requestName, errorCodes.NO_NETWORK_CONNECTION_ERR),
    );
    throw new Error(errorCodes.NO_NETWORK_CONNECTION_ERR);
  }

  apiClient.interceptors.request.use(authInterceptorFactory(token));

  store.dispatch(makeRequest(requestName));

  try {
    const response = await apiClient.request(requestData);
    store.dispatch(requestSuccess(requestName));
    return response;
  } catch (err) {
    const errorMessage = err.response ? err.response.status : err.message;
    if (errorMessage === 401 && window.location.pathname !== '/login') {
      // todo: redirect
      // history('/not-authorised');
    }
    store.dispatch(requestFailure(requestName, errorMessage));
    throw err;
  }
}

export default request;
