/* eslint-disable new-cap */
export function showIntercomMessenger() {
  if (window.Intercom) {
    window.Intercom('show');
  }
}

export function hideIntercomMessenger() {
  if (window.Intercom) {
    window.Intercom('hide');
  }
}

export function updateIntercomMessenger(options) {
  if (window.Intercom) {
    window.Intercom('update', options);
  }
}

export function shutdownIntercomMessenger() {
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
}
