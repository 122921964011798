const iconsStyles = {
  '.icon::before': {
    content: "''",
    display: 'inline-block',
    marginRight: '5px',
    backgroundRepeat: 'no-repeat',
    verticalAlign: 'middle',
    backgroundSize: 'cover',
    height: '25px',
    width: '25px',
  },
  '.icon > *': { display: 'inline-block', marginTop: '0', marginBottom: '0' },
  '.icon--wide::before, .icon--wide::after': { height: '16px', width: '25px' },
  '.icon--tiny::before, .icon--tiny::after': {
    marginRight: '3px',
    height: '7px',
    width: '7px',
  },
  '.icon--small::before, .icon--small::after': {
    height: '20px',
    width: '20px',
  },
  '.icon--back::before': {
    backgroundImage: "url('../images/icons/icon-arrow-left.svg')",
  },
  '.icon--tick::before': {
    backgroundImage: "url('../images/icons/icon-tick.svg')",
  },
  '.active .icon--plus-mini::before': { transform: 'rotate(45deg)' },
  '.icon--close::after, .icon--close::before': {
    backgroundImage: "url('../images/icons/icon-close.svg')",
  },
  '.icon--close:hover::after, .icon--close:hover::before': {
    backgroundImage: "url('../images/icons/icon-close-hover.svg')",
  },
  '.active .icon--compare::before, .stacking-block:hover .icon--compare::before': {
    backgroundImage: "url('../images/icons/icon-compare-active.svg')",
  },
  '.icon--cross::before': {
    height: '10px',
    width: '10px',
    marginTop: '-3px',
    marginRight: '5px',
    backgroundImage: "url('../images/icons/icon-cross.svg')",
  },
  '.icon--add::before': {
    backgroundImage: "url('../images/icons/icon-add.svg')",
  },
  '.icon--approve::before': {
    backgroundImage: "url('../images/icons/icon-approve.svg')",
    height: '42px',
    width: '42px',
  },
};

export default iconsStyles;
