import { geoJSON, LatLngBounds } from 'leaflet';
import { GeometryCollection } from '../types';

export function getBounds(
  boundary: GeometryCollection,
): LatLngBounds | undefined {
  try {
    return geoJSON(boundary).getBounds();
  } catch (e) {
    return undefined;
  }
}
