import analysisBuilder from 'modules/FieldsMap/utilities/analysisBuilder';
import { AnalysisConfig } from '../types/Analyses';

const weedDetection: AnalysisConfig = {
  id: 17,
  name: 'weedDetection',
  displayName: 'Wide Row Weed Distribution',
  inAPI: 'Wide Row Weed Distribution',
  type: 'analysis',
  url: 'analysis-weed_detection',
  clouds: true,
  config: analysisBuilder()
    .addUnits('unit.percentageWeedPressure')
    .addBarStep(5)
    .addDefaultRange({
      lower: 0,
      upper: 100,
    })
    .addColourList([
      '#FFFFFF',
      '#01C6D1',
      '#009098',
      '#008411',
      '#00B414',
      '#00FF21',
    ])
    .build(),
  status: 'deprecated',
};

export default weedDetection;
